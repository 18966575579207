import caleidoBaseApi from 'services/apiClient';
import tokenUtils from 'modules/auth/utils/tokenUtils';
import {
  AccessAndRefreshTokens,
  IUserResponse,
  IUserWithTokens,
} from 'modules/auth/types/types';

const AuthService = {
  register: (data: { email: string; password: string }) => {
    return caleidoBaseApi.post<IUserWithTokens>('/auth/register', data);
  },

  login: (data: { email: string; password: string }) => {
    return caleidoBaseApi.post<IUserWithTokens>('/auth/login', data);
  },

  refreshTokens: async (refreshToken: string) => {
    const response = await caleidoBaseApi.post<AccessAndRefreshTokens>('/auth/refresh-tokens', {
      refreshToken,
    });
    
    // Save the new tokens after successful refresh
    tokenUtils.setToken(response.data.access.token);
    tokenUtils.setRefreshToken(response.data.refresh.token);
    
    return response;
  },

  whoAmI: () => {
    return caleidoBaseApi.get<IUserResponse>(`/auth/whoami`, {
      headers: {
        'Cache-Control': 'no-cache',
      },
    });
  },

  logout: async () => {
    const refreshToken = tokenUtils.getRefreshToken();
    await caleidoBaseApi.post('/auth/logout', { refreshToken });
    tokenUtils.clearTokens();
  },
};

export { AuthService };