// webPushNotifications.ts
import axios from 'axios';
import tokenUtils from 'modules/auth/utils/tokenUtils';

interface PushInit {
  registration: ServiceWorkerRegistration;
  subscription: PushSubscription;
}

interface DeviceInfo {
  userAgent: string;
  deviceType: 'mobile' | 'desktop';
  lastUsed: string;
}

/**
 * WebPushNotifications - Manages browser push notification functionality
 * Handles service worker registration, push subscriptions, and backend communication
 */
class WebPushNotifications {
  private initialized = false;
  private registration: ServiceWorkerRegistration | null = null;
  private currentSubscription: PushSubscription | null = null;

  /**
   * Logs push-related events with consistent formatting
   */
  private log(action: string, data?: any): void {
    // console.log(`📱 Push [${action}]`, data || '');
  }

  /**
   * Converts a base64 string to Uint8Array for application server key
   */
  private urlBase64ToUint8Array(base64String: string): Uint8Array {
    const padding = '='.repeat((4 - (base64String.length % 4)) % 4);
    const base64 = (base64String + padding)
      .replace(/-/g, '+')
      .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
      outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

  /**
   * Gets device information for subscription tracking
   */
  private getDeviceInfo(): DeviceInfo {
    return {
      userAgent: window.navigator.userAgent,
      deviceType: /mobile|android|iphone|ipad/i.test(window.navigator.userAgent)
        ? 'mobile'
        : 'desktop',
      lastUsed: new Date().toISOString(),
    };
  }

  /**
   * Gets or creates a service worker registration
   */
  private async getServiceWorkerRegistration(): Promise<ServiceWorkerRegistration> {
    if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
      throw new Error('Push notifications are not supported');
    }

    if (this.registration) {
      return this.registration;
    }

    try {
      this.registration = await navigator.serviceWorker.register('/push-sw.js');
      await navigator.serviceWorker.ready;
      return this.registration;
    } catch (error) {
      this.log('Service Worker Registration Failed', error);
      throw error;
    }
  }

  /**
   * Creates a new push subscription
   */
  private async createSubscription(
    registration: ServiceWorkerRegistration,
  ): Promise<PushSubscription | null> {
    const publicVapidKey = process.env.REACT_APP_VAPID_PUBLIC_KEY;
    if (!publicVapidKey) {
      this.log('Missing VAPID Key');
      return null;
    }

    try {
      return await registration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: this.urlBase64ToUint8Array(publicVapidKey),
      });
    } catch (error) {
      this.log('Subscription Creation Failed', error);
      return null;
    }
  }

  /**
   * Validates a subscription with the backend
   */
  public async validateSubscription(
    subscription: PushSubscription,
  ): Promise<boolean> {
    try {
      const accessToken = tokenUtils.getToken();
      if (!accessToken) return false;

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/notify/check-subscription`,
        { subscription: subscription.toJSON() },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data?.isValid === true;
    } catch (error) {
      this.log('Validation Failed', error);
      return false;
    }
  }

  /**
   * Registers a subscription with the backend
   */
  private async registerWithBackend(
    subscription: PushSubscription,
  ): Promise<boolean> {
    try {
      const accessToken = tokenUtils.getToken();
      if (!accessToken) {
        this.log('No Access Token Found');
        return false;
      }

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/notify/register`,
        {
          subscription: subscription.toJSON(),
          deviceInfo: this.getDeviceInfo(),
        },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );

      return true;
    } catch (error) {
      this.log('Backend Registration Failed', error);
      return false;
    }
  }

  /**
   * Initializes the push notification system
   * Does NOT request permission or create subscription
   */
  async init(): Promise<PushInit | null> {
    if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
      this.log('Push Notifications Not Supported');
      return null;
    }

    try {
      const registration = await this.getServiceWorkerRegistration();
      let subscription = await registration.pushManager.getSubscription();

      this.initialized = true;

      if (subscription) {
        this.currentSubscription = subscription;
        return { registration, subscription };
      }

      // No subscription but system is ready
      return {
        registration,
        subscription: null as unknown as PushSubscription,
      };
    } catch (error) {
      this.log('Push Initialization Failed', error);
      return null;
    }
  }

  /**
   * Requests notification permission and creates subscription
   * Should only be called after user interaction
   */
  async requestPermission(userId: string): Promise<PushSubscription | null> {
    if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
      this.log('Push Notifications Not Supported');
      return null;
    }

    try {
      this.log('Starting Permission Request Process');

      // Check notification permission
      if (Notification.permission === 'denied') {
        this.log('Notifications Permission Previously Denied');
        return null;
      }

      // Explicitly request permission if not already granted
      if (Notification.permission !== 'granted') {
        this.log('Requesting Notification Permission');
        const permission = await Notification.requestPermission();
        if (permission !== 'granted') {
          this.log('Permission Not Granted');
          return null;
        }
      }
      this.log('Permission Granted');

      // Initialize push if not already initialized
      if (!this.initialized) {
        await this.init();
      }

      // Get existing or create new subscription
      const registration = await this.getServiceWorkerRegistration();
      let subscription = await registration.pushManager.getSubscription();

      if (subscription) {
        this.log('Found Existing Subscription, Validating');
        const isValid = await this.validateSubscription(subscription);
        if (isValid) {
          this.log('Existing Subscription Is Valid');
          this.currentSubscription = subscription;
          return subscription;
        }
        this.log('Existing Subscription Invalid, Creating New');
        await subscription.unsubscribe();
      }

      // Create new subscription
      this.log('Creating New Subscription');
      subscription = await this.createSubscription(registration);

      if (!subscription) {
        this.log('Failed To Create Subscription');
        return null;
      }

      this.currentSubscription = subscription;

      // Register with backend
      this.log('Registering With Backend');
      const registered = await this.registerWithBackend(subscription);

      if (!registered) {
        this.log('Backend Registration Failed');
        return null;
      }

      this.log('Registration Successful');
      return subscription;
    } catch (error) {
      this.log('Permission Request Failed', error);
      return null;
    }
  }

  /**
   * Checks if a valid subscription exists
   */
  async checkSubscription(): Promise<boolean> {
    if (!('serviceWorker' in navigator) || !('PushManager' in window)) {
      return false;
    }

    try {
      this.log('Starting Subscription Check');

      const registration = await this.getServiceWorkerRegistration();
      const subscription = await registration.pushManager.getSubscription();

      this.log('Current Subscription Status', {
        exists: !!subscription,
        endpoint: subscription?.endpoint.slice(-10) || 'none',
      });

      if (!subscription) {
        this.log('No Subscription Found');
        return false;
      }

      const accessToken = tokenUtils.getToken();
      if (!accessToken) {
        this.log('No Access Token Found');
        return false;
      }

      const isValid = await this.validateSubscription(subscription);
      this.log('Backend Validation Result', { isValid });

      if (!isValid) {
        this.log('Subscription Invalid, Cleaning Up');
        await subscription.unsubscribe();
        return false;
      }

      this.currentSubscription = subscription;
      return true;
    } catch (error) {
      this.log('Subscription Check Failed', error);
      return false;
    }
  }

  /**
   * Unsubscribes from push notifications
   */
  async unsubscribe(): Promise<boolean> {
    try {
      this.log('Unsubscribing');
      const registration = await this.getServiceWorkerRegistration();
      const subscription = await registration.pushManager.getSubscription();

      if (subscription) {
        const endpoint = subscription.endpoint;
        await subscription.unsubscribe();

        const accessToken = tokenUtils.getToken();
        if (accessToken) {
          await axios.post(
            `${process.env.REACT_APP_BASE_URL}/notify/unregister`,
            { endpoint },
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
              },
            },
          );
        }

        this.log('Unsubscribed Successfully');
      }

      this.initialized = false;
      this.currentSubscription = null;
      return true;
    } catch (error) {
      this.log('Error Unsubscribing', error);
      return false;
    }
  }

  /**
   * Sends a test notification
   */
  async sendTestNotification(userId: string): Promise<boolean> {
    try {
      const accessToken = tokenUtils.getToken();
      if (!accessToken) {
        this.log('No Access Token Found');
        return false;
      }

      await axios.post(
        `${process.env.REACT_APP_BASE_URL}/notify/test`,
        { userId },
        {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json',
          },
        },
      );
      this.log('Test Notification Sent');
      return true;
    } catch (error) {
      this.log('Error Sending Test Notification', error);
      return false;
    }
  }
}

// Export a singleton instance
export const webPushNotifications = new WebPushNotifications();
