import { useState, useEffect } from 'react';
import { AxiosRequestConfig } from 'axios';
import apiClient from 'services/apiClient';

export const useApiCall = () => {
  const [data, setData] = useState<any[] | any | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [error, setError] = useState<any | null>(null);

  const fetchData = async (path: string) => {
    try {
      setIsFetching(true);
      setIsLoading(true);
      const response = await apiClient.get(path);
      setData(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error);
      setData(null);
    } finally {
      setIsLoading(false);
      setIsFetching(false);
    }
  };

  return {
    data,
    refetch: fetchData,
    isLoading,
    isFetching,
    error,
  };
};

export const useApiQuery = <T,>(path: string, params?: AxiosRequestConfig) => {
  const [data, setData] = useState<T | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any | null>(null);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const response = await apiClient.get(path, { params });
      setData(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching data:', error);
      setError(error);
      setData(null);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [path]);

  return { data, isLoading, error, refetch: fetchData };
};
