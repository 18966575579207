import { cn } from 'utils/helpers';

type Props = {
  eventOptions: {
    label: string;
    value: string;
  }[];
  eventType: string;
  setEventType: (eventType: string) => void;
};

const EventsMenu = ({ eventOptions, eventType, setEventType }: Props) => {
  return (
    <div className="relative w-full flex gap-2 overflow-x-scroll">
      <div className="flex items-center">
        {eventOptions.map((option) => (
          <div
            key={option.value}
            className={cn(
              'bg-transparent px-5 py-5 border-b border-[rgb(255,255,255,0.2)] transition-border duration-300',
              eventType === option.value && 'border-white',
            )}
            onClick={() => setEventType(option.value)}
          >
            <p
              className={cn(
                'whitespace-nowrap text-[rgb(255,255,255,0.6)] transition-colors duration-300',
                eventType === option.value && 'text-white',
              )}
            >
              {option.label}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default EventsMenu;
