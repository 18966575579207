'use client';

import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect, useMemo } from 'react';
import { useGetPendingEventsQuery } from 'services/event.service';
import { cn } from 'utils/helpers';
import EventCard from './EventCard';
import InfiniteList from './InfiniteList';
import EmptyEventsList from './EmptyEventsList';
import { Loader2 } from 'lucide-react';
import useUpdates from '../hooks/useUpdates';
import { IContact } from 'types/event';
import { useAuth } from 'modules/auth/context/AuthContextV2';

const DraftsEventsSection = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [events, setEvents] = useState<any[]>([]);
  const { data: draftEvents, isFetching } = useGetPendingEventsQuery({
    page,
    isPast: true,
  });

  const { updates } = useUpdates();

  useEffect(() => {
    if (draftEvents) {
      if (draftEvents.length === 0) {
        setHasMore(false);
      }
    }
  }, [draftEvents]);
  const filteredEvents = useMemo(() => {
    return events.filter((event) => {
      return event.event.userId === user?._id;
    });
  }, [events, user]);

  const sortedEvents = useMemo(() => {
    return [...filteredEvents].sort((a, b) => {
      return dayjs(a.event.start).diff(dayjs(b.event.start));
    });
  }, [filteredEvents]);

  return (
    <div>
      <div className="w-full flex justify-between items-center px-5 pb-3">
        <p className="text-[1.125rem] font-bold">
          {sortedEvents?.length > 0
            ? `${sortedEvents?.length} events`
            : 'Events'}
        </p>
      </div>
      <div
        className={cn(
          'w-full max-h-[42svh] min-h-[21.75rem] flex flex-col gap-3 overflow-y-scroll',
          sortedEvents.length === 0 && 'max-h-[55svh]',
        )}
      >
        <InfiniteList
          onInView={() => {
            if (isFetching || !hasMore) return;

            setPage((prev) => prev + 1);
            setEvents((prev) => [...prev, ...draftEvents]);
          }}
          children={
            <div
              className={cn(
                'w-full flex flex-col justify-center items-center gap-3 overflow-y-scroll px-5 mb-[9rem]',
              )}
            >
              {sortedEvents.length > 0 ? (
                sortedEvents
                  .sort((a: any, b: any) => dayjs(a.start).diff(dayjs(b.start)))
                  .map((event: any, index: number) => (
                    <div
                      className="w-full flex"
                      key={index}
                      onClick={() => {
                        navigate(`/lets-meet/edit/${event.event._id}`);
                      }}
                    >
                      <EventCard
                        title={event.event.activity}
                        description={event.event.details}
                        time={event.event.start}
                        emoji={event.event.emoji || '📅'}
                        organizerId={event.event.userId}
                        status={event.event.status}
                      />
                    </div>
                  ))
              ) : (
                <>
                  {!isFetching && draftEvents.length === 0 && (
                    <EmptyEventsList />
                  )}
                  {isFetching && (
                    <div className="flex justify-center items-center">
                      <Loader2 className="w-4 h-4 animate-spin" />
                    </div>
                  )}
                </>
              )}
              {isFetching && (
                <p className="text-center text-gray-500">Loading...</p>
              )}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default DraftsEventsSection;
