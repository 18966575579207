import React, { Fragment, useMemo } from 'react';
import { createPortal } from 'react-dom';
import dayjs from 'dayjs';
import { X } from 'lucide-react';
import { cn } from 'utils/helpers';
import CalendarPicker from 'components/v2/calendarPicker/CalendarPicker';
import Button from 'components/v2/button/Button';
import { SquareAutocompleteInput } from 'components/v2/squareAutocompleteInput/SquareAutocompleteInput';
import { Text } from 'components/text/primary/Text';
import { useGetAvailabilitiesQuery } from 'services/calendar.service';
import TimePicker from 'components/v2/timePicker/TimePicker';
import LetsMeetLayout from '../../layouts/LetsMeetLayout';

type Props = {
  isOpen: boolean;
  onBack: () => void;
  onSave: () => void;
  selectedDatesWithTimeRange: {
    date: Date;
    start: Date | null;
    end: Date | null;
  }[];
  setSelectedDatesWithTimeRange: React.Dispatch<
    React.SetStateAction<
      {
        date: Date;
        start: Date | null;
        end: Date | null;
      }[]
    >
  >;
};

const CreateEventV2LaterTimePopup = ({
  isOpen,
  onBack,
  onSave,
  selectedDatesWithTimeRange,
  setSelectedDatesWithTimeRange,
}: Props) => {
  const { data: availabilities, isLoading: isLoadingAvailabilities } =
    useGetAvailabilitiesQuery(
      {
        dates: selectedDatesWithTimeRange.map((date) =>
          dayjs(date.date).format('YYYY-MM-DD'),
        ),
        interval: 30,
      },
      {
        skip: !selectedDatesWithTimeRange,
      },
    );

  const selectedDatesAvailability = useMemo(() => {
    return selectedDatesWithTimeRange.map((date) => {
      const availability = availabilities?.find((availability) =>
        dayjs(availability.date).isSame(dayjs(date.date), 'day'),
      );

      if (!availability) {
        return {
          times: [],
          date: date.date.toISOString(),
          timezone: '',
        };
      }

      const currentTimeZoneTimes = availability.times.map((time) => {
        return {
          ...time,
          start: dayjs(time.start)
            .tz(availability.timezone, true)
            .toISOString(),
          end: dayjs(time.end).tz(availability.timezone, true).toISOString(),
        };
      });

      return {
        ...availability,
        times: currentTimeZoneTimes,
      };
    });
  }, [availabilities, selectedDatesWithTimeRange]);

  const handleCalendarDateChange = (dates: Date[]) => {
    if (!dates) {
      return;
    }

    setSelectedDatesWithTimeRange(
      dates.map((date) => {
        const selectedDateWithTimeRange = selectedDatesWithTimeRange.find((d) =>
          dayjs(d.date).isSame(dayjs(date), 'day'),
        );

        return {
          date,
          start: selectedDateWithTimeRange?.start ?? null,
          end: selectedDateWithTimeRange?.end ?? null,
        };
      }),
    );
  };

  const startTimeOptions = useMemo(() => {
    if (!selectedDatesAvailability) {
      return [];
    }

    return selectedDatesAvailability.map((date) => {
      const times = date.times.map((time) => {
        const label = dayjs(time.start).format('hh:mm A');

        return {
          label,
          value: time.start,
        };
      });

      return {
        date: date.date,
        times,
      };
    });
  }, [selectedDatesAvailability]);

  const endTimeOptions = useMemo(() => {
    if (selectedDatesWithTimeRange.length === 0) {
      return [];
    }

    return selectedDatesAvailability.map((date) => {
      const selectedDateWithTimeRange = selectedDatesWithTimeRange.find((d) =>
        dayjs(d.date).isSame(dayjs(date.date), 'day'),
      );

      const times = date.times
        .filter((time) => {
          if (
            dayjs(time.end).isAfter(
              dayjs(selectedDateWithTimeRange?.start),
              'minutes',
            )
          ) {
            return true;
          }

          return false;
        })
        .map((time) => {
          const label = dayjs(time.end).format('hh:mm A');

          return {
            label,
            value: time.end,
          };
        });

      return {
        date: date.date,
        times,
      };
    });
  }, [selectedDatesWithTimeRange, selectedDatesAvailability]);

  return createPortal(
    <div
      className={cn(
        'fixed inset-0 z-50 duration-300 ease-in-out',
        isOpen
          ? 'opacity-100 pointer-events-auto'
          : 'opacity-0 pointer-events-none',
      )}
    >
      <LetsMeetLayout
        title="Choose Dates"
        step="create"
        footerText={''}
        actionOnHeaderRight={''}
        widget="menu"
        handleGoBack={onBack}
        bottomChildren={<Button onClick={onSave}>Save</Button>}
      >
        <div className="mt-4 flex flex-col">
          <div className="flex flex-col gap-4 mt-6">
            <Text size="small" className="text-white">
              Select date
            </Text>

            <CalendarPicker
              type="single"
              selectedDates={selectedDatesWithTimeRange.map((d) => d.date)}
              setSelectedDates={(dates) => handleCalendarDateChange(dates)}
              only="future-dates"
            />
          </div>
          <div className="flex flex-col gap-4 my-4">
            {selectedDatesWithTimeRange.map((date) => {
              const currentStartTimeOptions =
                startTimeOptions.find((d) =>
                  dayjs(d.date).isSame(dayjs(date.date), 'day'),
                )?.times ?? [];

              const currentEndTimeOptions =
                endTimeOptions.find((d) =>
                  dayjs(d.date).isSame(dayjs(date.date), 'day'),
                )?.times ?? [];

              return (
                <Fragment key={date.date.toISOString()}>
                  <Text size="small" className="text-white">
                    {dayjs(date.date).format('D MMM, YYYY')}
                  </Text>
                  <div className="flex items-center gap-4 justify-between">
                    <SquareAutocompleteInput
                      label="Start time"
                      options={currentStartTimeOptions}
                      selectedOption={date.start?.toISOString?.()}
                      setSelectedOption={(option) => {
                        if (option) {
                          setSelectedDatesWithTimeRange(
                            selectedDatesWithTimeRange.map((d) =>
                              dayjs(d.date).isSame(dayjs(date.date), 'day')
                                ? { ...d, start: dayjs(option).toDate() }
                                : d,
                            ),
                          );
                        }
                      }}
                      dropdownLabel="Choose time"
                      dropdownEmptyLabel="No time slots available"
                      size="medium"
                      isDropdownIcon={true}
                      readonly={true}
                      disabled={isLoadingAvailabilities}
                      placeholder="Choose time"
                      customOption={{
                        type: 'time',
                        label: 'Set time',
                        resetOnNullValue: true,
                        renderInputChildren: ({ onClear }) => (
                          <div className="px-4 h-16 flex items-center justify-center">
                            <TimePicker
                              value={
                                date.start
                                  ? {
                                      hours: dayjs(date.start).format('HH'),
                                      minutes: dayjs(date.start).format('mm'),
                                    }
                                  : null
                              }
                              onChange={(value) => {
                                const newDate = dayjs(date.date)
                                  .set('hour', parseInt(value.hours))
                                  .set('minute', parseInt(value.minutes));

                                setSelectedDatesWithTimeRange(
                                  selectedDatesWithTimeRange.map((d) =>
                                    dayjs(d.date).isSame(
                                      dayjs(date.date),
                                      'day',
                                    )
                                      ? {
                                          ...d,
                                          start: newDate.toDate(),
                                          end: null,
                                        }
                                      : d,
                                  ),
                                );
                              }}
                            />
                            <X
                              size={18}
                              className="text-white ml-2"
                              onClick={() => {
                                onClear();

                                setSelectedDatesWithTimeRange(
                                  selectedDatesWithTimeRange.map((d) =>
                                    dayjs(d.date).isSame(
                                      dayjs(date.date),
                                      'day',
                                    )
                                      ? { ...d, start: null, end: null }
                                      : d,
                                  ),
                                );
                              }}
                            />
                          </div>
                        ),
                      }}
                    />
                    <SquareAutocompleteInput
                      label="End time"
                      options={currentEndTimeOptions}
                      selectedOption={date.end?.toISOString?.()}
                      readonly={true}
                      setSelectedOption={(option) => {
                        if (option) {
                          setSelectedDatesWithTimeRange(
                            selectedDatesWithTimeRange.map((d) =>
                              dayjs(d.date).isSame(dayjs(date.date), 'day')
                                ? { ...d, end: dayjs(option).toDate() }
                                : d,
                            ),
                          );
                        }
                      }}
                      dropdownLabel="Choose time"
                      dropdownEmptyLabel={
                        !date.start
                          ? 'Select start time first'
                          : 'No time slots available'
                      }
                      size="medium"
                      placeholder="Optional"
                      isDropdownIcon={true}
                      disabled={!date.start || isLoadingAvailabilities}
                      customOption={{
                        type: 'time',
                        label: 'Set time',
                        resetOnNullValue: true,
                        renderInputChildren: ({ onClear }) => (
                          <div className="px-4 h-16 flex items-center justify-center">
                            <TimePicker
                              value={
                                date.end
                                  ? {
                                      hours: dayjs(date.end).format('HH'),
                                      minutes: dayjs(date.end).format('mm'),
                                    }
                                  : null
                              }
                              onChange={(value) => {
                                const newHour = parseInt(value.hours);
                                const minHour = dayjs(date.start).hour();

                                const hour = Math.max(newHour, minHour);
                                const minute =
                                  newHour === minHour
                                    ? Math.max(
                                        parseInt(value.minutes),
                                        dayjs(date.start)
                                          .add(1, 'minutes')
                                          .minute(),
                                      )
                                    : parseInt(value.minutes);

                                setSelectedDatesWithTimeRange(
                                  selectedDatesWithTimeRange.map((d) =>
                                    dayjs(d.date).isSame(
                                      dayjs(date.date),
                                      'day',
                                    )
                                      ? {
                                          ...d,
                                          end: date.start
                                            ? dayjs(date.start)
                                                .set('hour', hour)
                                                .set('minute', minute)
                                                .toDate()
                                            : null,
                                        }
                                      : d,
                                  ),
                                );
                              }}
                            />
                            <X
                              size={18}
                              className="text-white ml-2"
                              onClick={() => {
                                onClear();

                                setSelectedDatesWithTimeRange(
                                  selectedDatesWithTimeRange.map((d) =>
                                    dayjs(d.date).isSame(
                                      dayjs(date.date),
                                      'day',
                                    )
                                      ? { ...d, end: null }
                                      : d,
                                  ),
                                );
                              }}
                            />
                          </div>
                        ),
                      }}
                    />
                  </div>
                </Fragment>
              );
            })}
          </div>
        </div>
      </LetsMeetLayout>
    </div>,
    document.body,
  );
};

export default CreateEventV2LaterTimePopup;
