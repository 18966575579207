import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import OverlayLoader from 'components/loader/OverlayLoader';
import { useUpdateUserMutation } from 'modules/auth/services/user.mutation.service';
import Contacts from 'modules/settings/components/Contacts';
import { useAuth } from 'modules/auth/context/AuthContextV2';
import { getMyContacts, deleteContactFromUser } from 'utils/helpers';
import { IUser } from 'modules/auth/types/types';
import { Contact } from 'modules/profile/types/types';

// Define a type for partial updates of IUser
type PartialUserUpdate = Partial<IUser>;

const EditContacts = () => {
  const { user, loading } = useAuth();
  const [updateUser, { data: updateData }] = useUpdateUserMutation();
  const [contacts, setContacts] = useState<Contact[]>([]);
  const [isRefetching, setIsRefetching] = useState<boolean>(false);
  const [initialLoadComplete, setInitialLoadComplete] =
    useState<boolean>(false);

  // Function to get cached contacts from localStorage
  const getCachedContacts = (): Contact[] => {
    try {
      const cachedContacts = localStorage.getItem('cached-contacts');
      if (cachedContacts) {
        console.log('Using cached contacts data');
        return JSON.parse(cachedContacts) as Contact[];
      }
      return (user?.contacts || []) as Contact[];
    } catch (error) {
      console.error('Error retrieving cached contacts:', error);
      return (user?.contacts || []) as Contact[];
    }
  };

  // Function to fetch contacts
  const fetchContacts = async (): Promise<void> => {
    if (!initialLoadComplete) {
      setIsRefetching(false);
    } else {
      setIsRefetching(true);
    }

    try {
      const contactsData = await getMyContacts();
      console.log('Fetched fresh contacts data:', contactsData);

      if (contactsData && Array.isArray(contactsData)) {
        setContacts(contactsData as Contact[]);
      }

      try {
        if (contactsData) {
          localStorage.setItem('cached-contacts', JSON.stringify(contactsData));
        }
      } catch (error) {
        console.error('Error caching contacts:', error);
      }

      setInitialLoadComplete(true);
    } catch (error) {
      console.error('Error fetching contacts:', error);
    } finally {
      setIsRefetching(false);
    }
  };

  // Handle contact deletion
  const handleDeleteContact = async (phoneNumber: string): Promise<void> => {
    try {
      // Update UI immediately
      const updatedContacts = contacts.filter(
        (contact) => contact.phoneNumber !== '+' + phoneNumber,
      );
      setContacts(updatedContacts);

      // Update localStorage cache
      try {
        localStorage.setItem(
          'cached-contacts',
          JSON.stringify(updatedContacts),
        );
      } catch (error) {
        console.error('Error updating cached contacts:', error);
      }

      // Call API to update backend
      await deleteContactFromUser('+' + phoneNumber);
    } catch (error) {
      console.error('Error deleting contact:', error);
      // Revert UI changes in case of error
      fetchContacts();
    }
  };

  // Handle contact addition
  const handleAddContacts = async (
    updatedContacts: Contact[],
  ): Promise<IUser | null> => {
    try {
      // Create an update payload with just the contacts field
      const updatePayload = {
        data: {
          contacts: updatedContacts,
        },
      };

      // Update UI immediately
      setContacts(updatedContacts);

      // Update localStorage cache
      localStorage.setItem('cached-contacts', JSON.stringify(updatedContacts));

      // Call API to update backend
      const response = await updateUser(updatePayload as any).unwrap();

      return response;
    } catch (error) {
      console.error('Error adding contacts:', error);
      fetchContacts();
      throw error;
    }
  };
  // Initialize with cached contacts
  useEffect(() => {
    const cachedContacts = getCachedContacts();
    setContacts(cachedContacts);

    // Then fetch fresh data
    fetchContacts();

    // Set up interval for periodic refresh
    const intervalId = setInterval(fetchContacts, 15000); // 15 seconds

    return () => clearInterval(intervalId);
  }, []);

  // Create a merged user object
  const userWithContacts = {
    ...(user || {}),
    contacts: contacts.length > 0 ? contacts : user?.contacts || [],
  } as IUser;

  return (
    <main>
      <div className="w-full">
        {isEmpty(user) ? (
          <OverlayLoader />
        ) : (
          <Contacts
            update={handleAddContacts}
            data={userWithContacts}
            setPrevOrSkip={() => {}}
            loading={loading && !initialLoadComplete}
            deleteContact={handleDeleteContact}
          />
        )}
      </div>
    </main>
  );
};

export default EditContacts;
