export const EVENT_ACTIVITIES = [
  {
    id: 1,
    name: 'Dinner',
    emoji: '🍽️',
  },
  {
    id: 2,
    name: 'Lunch',
    emoji: '🍔',
  },
  {
    id: 3,
    name: 'Breakfast',
    emoji: '🥚',
  },
  {
    id: 4,
    name: 'Drinks',
    emoji: '🍸',
  },
  {
    id: 5,
    name: 'Bar',
    emoji: '🍺',
  },
  {
    id: 6,
    name: 'Coffee and chill',
    emoji: '☕',
  },
  {
    id: 7,
    name: 'Catch up',
    emoji: '🤗',
  },
  {
    id: 8,
    name: 'House hang',
    emoji: '🏠',
  },
  {
    id: 9,
    name: 'Gaming',
    emoji: '🎮',
  },
  {
    id: 10,
    name: 'Dancing',
    emoji: '🪩',
  },
  {
    id: 11,
    name: 'Study',
    emoji: '📚',
  },
  {
    id: 12,
    name: 'Work',
    emoji: '💻',
  },
  {
    id: 13,
    name: 'Workout',
    emoji: '💪',
  },
  {
    id: 14,
    name: 'Walk',
    emoji: '🚶‍♂️',
  },
  {
    id: 15,
    name: 'Date night',
    emoji: '🌹',
  },
  {
    id: 16,
    name: 'Game night',
    emoji: '🎲',
  },
  {
    id: 17,
    name: 'Hang',
    emoji: '🕶️',
  },
  {
    id: 18,
    name: 'Beach day',
    emoji: '🌊',
  },
];

export const DURATION_OPTIONS = [
  {
    label: '15 m',
    value: '15',
  },
  {
    label: '30 m',
    value: '30',
  },
  {
    label: '45 m',
    value: '45',
  },
  {
    label: '1 h',
    value: '60',
  },
  {
    label: '2 h',
    value: '120',
  },
  {
    label: '3 h',
    value: '180',
  },
  {
    label: '4 h',
    value: '240',
  },
  {
    label: '5 h',
    value: '300',
  },
  {
    label: '6 h',
    value: '360',
  },
  {
    label: '7 h',
    value: '420',
  },
  {
    label: '8 h',
    value: '480',
  },
  {
    label: '9 h',
    value: '540',
  },
  {
    label: '10 h',
    value: '600',
  },
  {
    label: '11 h',
    value: '660',
  },
  {
    label: '12 h',
    value: '720',
  },
];

export const CUSTOM_EMOJI_OPTIONS = [
  '🍽️',
  '🍔',
  '🥚',
  '🍸',
  '🍺',
  '☕',
  '🤗',
  '🏠',
  '🎮',
  '🪩',
  '📚',
  '💻',
  '💪',
  '🚶‍♂️',
  '🌹',
  '🎲',
  '🕶️',
  '🌊',
];

export enum EventStatus {
  NotResponded = 0,
  Accepted = 1,
  Declined = 2,
  Maybe = 3,
}

export enum EventContactInvited {
  NotResponded = 0,
  Accepted = 1,
  Declined = 2,
  Maybe = 3,
}
