import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ReactComponent as CaleidoLogo } from 'assets/images/common/logo.svg';
import { ReactComponent as ButterflyIcon } from 'assets/images/common/logo-butterfly.svg';
import whoIsThisForImg from 'assets/images/landing/why-and-who-is-this-for.jpg';
import whatisItImg from 'assets/images/landing/what-is-it.jpg';

import step1Img from 'assets/images/landing/step1.jpg';
import step2Img from 'assets/images/landing/step2.jpg';
import step3Img from 'assets/images/landing/step3.jpg';

import { Button } from './../components/Button';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogDescription,
} from './../components/Dialog';
import './../styles/landing.css';
import QRPopup from './../components/QRPopup';

const stepImages = [step1Img, step2Img, step3Img];
const heroVideo =
  'https://caleido-storage.s3.eu-north-1.amazonaws.com/hero-landing-vid.mp4';

export default function LandingPage() {
  const [showSupport, setShowSupport] = useState(false);
  const [showQRPopup, setShowQRPopup] = useState(false);
  const isMobile = window.innerWidth <= 768;

  const handleButtonClick = (url: string) => {
    if (isMobile) {
      window.location.href = url;
    } else {
      setShowQRPopup(true);
    }
  };

  let chatBubbles = [];
  if (isMobile) {
    chatBubbles = [
      { message: 'Dinner Friday? 👋', position: { left: '5%', top: '15%' } },
      { message: "I'm in! ✌️", position: { right: '10%', top: '25%' } },
    ];
  } else {
    chatBubbles = [
      { message: 'Dinner Friday? 👋', position: { left: '5%', top: '15%' } },
      { message: "I'm in! ✌️", position: { right: '10%', top: '25%' } },
      {
        message: 'Basketball tonight? 🏀',
        position: { left: '15%', top: '70%' },
      },
      {
        message: 'Meet Tuesday at 2pm? ☕️',
        position: { right: '20%', top: '80%' },
      },
      {
        message: 'Movie this weekend? 🎬',
        position: { left: '25%', top: '85%' },
      },
      { message: 'Count me in! 🙌', position: { right: '15%', top: '65%' } },
    ];
  }

  return (
    <div className="landingPageWrapper">
      <div className="min-h-screen bg-dark text-white overflow-hidden">
        {/* Support Dialog */}
        <Dialog open={showSupport} onOpenChange={setShowSupport}>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Contact Support</DialogTitle>
              <DialogDescription>
                You can reach us at{' '}
                <a
                  href="mailto:hi@caleido.social"
                  className="text-primary hover:underline"
                >
                  hi@caleido.social
                </a>
              </DialogDescription>
            </DialogHeader>
            <div className="mt-6 flex justify-end">
              <button
                onClick={() => setShowSupport(false)}
                className="px-4 py-2 rounded-lg bg-primary text-white hover:bg-primary/90 transition-colors"
              >
                Close
              </button>
            </div>
          </DialogContent>
        </Dialog>
        <QRPopup isOpen={showQRPopup} onClose={() => setShowQRPopup(false)} />
        {/* Header */}
        <header className="fixed top-0 w-full z-50 bg-dark-transparent backdrop-blur-sm border-b border-white-10">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-5">
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-2">
                <div className="flex items-center">
                  <CaleidoLogo className="w-23 h-3.5" />
                  <ButterflyIcon className="w-4 h-4 ml-1" />
                </div>
              </div>
              <div className="flex items-center gap-4 sm:gap-6">
                <Button
                  variant="ghost"
                  className="hidden md:inline-flex text-gray-300 hover:text-white"
                  onClick={() =>
                    handleButtonClick(
                      'https://caleido.social/auth/start',
                    )
                  }
                >
                  Log in
                </Button>
                <Button
                  className="bg-primary hover:bg-primary-dark shadow-lg shadow-primary-25"
                  onClick={() =>
                    handleButtonClick(
                      'https://caleido.social/auth/start',
                    )
                  }
                >
                  Get Started
                </Button>
              </div>
            </div>
          </div>
        </header>

        {/* Hero Section */}
        <section className="relative min-h-screen flex items-center pt-16 sm:pt-20">
          {/* Background Video with Overlay */}
          <div className="absolute inset-0 w-full h-full">
            <video
              autoPlay
              muted
              loop
              playsInline
              preload="auto"
              className="absolute inset-0 w-full h-full object-cover opacity-50 z-[3]"
            >
              <source src={heroVideo} type="video/mp4" />
            </video>
            <div className="absolute inset-0 bg-gradient-to-b from-dark via-dark-90 to-dark z-[2]" />
          </div>

          {/* Decorative Elements */}
          <motion.div
            className="absolute right-0 top-1/4 w-72 sm:w-96 h-72 sm:h-96 bg-secondary rounded-full blur-[120px] opacity-20 z-[3]"
            animate={{
              scale: [1, 1.2, 1],
              opacity: [0.2, 0.3, 0.2],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
            }}
          />

          {/* Floating Chat Bubbles */}
          {chatBubbles.map((bubble, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{
                opacity: 1,
                y: [0, -20, 0],
                x: [0, index % 2 === 0 ? -30 : 30, 0],
              }}
              transition={{
                opacity: { delay: index * 0.2, duration: 0.8 },
                y: {
                  delay: index * 0.2,
                  duration: 8,
                  repeat: Infinity,
                  repeatType: 'reverse',
                  ease: 'easeInOut',
                },
                x: {
                  delay: index * 0.2,
                  duration: 12,
                  repeat: Infinity,
                  repeatType: 'reverse',
                  ease: 'easeInOut',
                },
              }}
              className="absolute sm:block bg-secondary rounded-2xl px-4 sm:px-6 py-2 sm:py-3 text-sm font-medium shadow-lg shadow-secondary-20 backdrop-blur-sm z-[4]"
              style={{
                ...bubble.position,
              }}
            >
              {bubble.message}
            </motion.div>
          ))}

          <div className="container mx-auto px-4 sm:px-6 lg:px-8 relative z-[5]">
            <div className="max-w-[85rem] mx-auto text-center">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
                className="space-y-6 sm:space-y-8"
              >
                <h1 className="text-5xl sm:text-5xl lg:text-7xl font-bold tracking-tight px-4">
                  See you{' '}
                  <span className="text-gray-400 opacity-50 line-through">
                    later
                  </span>{' '}
                  there
                </h1>
                <p className="text-gray-100 text-xl sm:text-2xl max-w-3xl mx-auto leading-relaxed px-4">
                  Skip the group chat fatigue with your hangout coordinator.
                  <br /> Less planning. More hanging.
                </p>
                <div className="space-y-8 pt-4">
                  <motion.div
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                  >
                    <Button
                      size="lg"
                      className="bg-primary hover:bg-primary-dark shadow-lg shadow-primary-25 h-12 sm:h-14 px-6 sm:px-8 text-base sm:text-lg"
                      onClick={() =>
                        handleButtonClick(
                          'https://staging-app.caleido.social/auth/start',
                        )
                      }
                    >
                      Start hanging
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="ml-2 h-5 w-5"
                      >
                        <path d="M5 12h14" />
                        <path d="m12 5 7 7-7 7" />
                      </svg>
                    </Button>
                  </motion.div>

                  {/* Scroll Indicator */}
                  <motion.div
                    className="flex flex-col items-center space-y-2"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ delay: 1 }}
                  >
                    <span className="text-sm text-gray-400">
                      Scroll for more!
                    </span>
                    <motion.div
                      animate={{
                        y: [0, 8, 0],
                      }}
                      transition={{
                        duration: 1.5,
                        repeat: Infinity,
                        ease: 'easeInOut',
                      }}
                    >
                      <svg
                        className="w-6 h-6 text-gray-400"
                        fill="none"
                        strokeWidth="2"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path d="M19 14l-7 7m0 0l-7-7m7 7V3"></path>
                      </svg>
                    </motion.div>
                  </motion.div>
                </div>
              </motion.div>
            </div>
          </div>
        </section>

        {/* What is it Section */}
        <section className="py-12 sm:py-16 lg:py-24 relative overflow-hidden">
          <motion.div
            className="absolute left-0 bottom-0 w-72 sm:w-96 h-72 sm:h-96 bg-[#4B3BFF] rounded-full blur-[120px] opacity-10"
            animate={{
              scale: [1, 1.2, 1],
              opacity: [0.1, 0.2, 0.1],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
            }}
          />

          {/* Main content wrapper */}
          <div className="relative max-w-[1400px] mx-auto px-6 md:px-8">
            <div className="relative flex flex-col md:flex-row md:items-center justify-between">
              {' '}
              {/* Changed to items-center */}
              {/* Text Content */}
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: true }}
                className="max-w-[480px] md:py-20" // Added padding to help with vertical alignment
              >
                <div className="space-y-4">
                  <h2 className="text-2xl md:text-4xl font-bold tracking-tight">
                    What is it?
                  </h2>
                  <p className="text-base md:text-lg text-gray-400 leading-relaxed">
                    Caleido helps you coordinate hangouts in as easy as a few
                    quick steps. Send a spontaneous hang invite, plan a future
                    meetup, find the optimal time, send reminders. Invite anyone
                    in-app or via text with a shareable invite link. Caleido
                    helps you plan so you can spend more time together.
                  </p>
                </div>
              </motion.div>
              {/* Image/Placeholder */}
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: true }}
                className="mt-8 md:mt-0 md:ml-20 flex-shrink-0" // Added flex-shrink-0
              >
                <img
                  src={whatisItImg}
                  alt="What is Caleido?"
                  className="w-full max-w-[600px] aspect-[4/3] object-contain rounded-3xl bg-dark-50"
                />
              </motion.div>
            </div>
          </div>
        </section>

        {/* How does it work Section */}
        <section className="py-12 sm:py-16 lg:py-24 relative bg-gradient-to-b from-transparent to-dark-50">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
              className="text-center max-w-3xl mx-auto mb-16 space-y-4"
            >
              <h2 className="text-2xl md:text-4xl font-bold tracking-tight">
                How does it work?
              </h2>
              <p className="text-base md:text-lg text-gray-400 leading-relaxed">
                Pick an activity, choose a location, add some friends, indicate
                if it&apos;s happening now, later, or you need to find time.
                Then send it off yourself or let Caleido send it for you!
              </p>
            </motion.div>

            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 lg:gap-8 max-w-6xl mx-auto">
              {[
                {
                  title: 'Create a Hangout',
                  description: 'Choose your activity and set the details',
                },
                {
                  title: 'Invite Friends',
                  description: 'Add friends via text or sharing a link',
                },
                {
                  title: "Let's Hang!",
                  description: 'Coordinate and enjoy your time together',
                },
              ].map((step, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.8, delay: index * 0.2 }}
                  viewport={{ once: true }}
                  className="group"
                >
                  <div className="relative h-[800px] transition-all duration-300 group-hover:translate-y-[-10px]">
                    <div className="absolute -inset-1 bg-gradient-to-r from-[#4B3BFF] to-[#007AFF] rounded-3xl blur opacity-25 transition-opacity group-hover:opacity-50"></div>
                    <div className="relative h-full rounded-3xl border border-white/10 bg-white p-2">
                      <img
                        src={stepImages[index]}
                        alt={`Step ${index + 1}: ${step.title}`}
                        className="rounded-2xl h-full w-full object-cover"
                      />
                    </div>
                    <div className="absolute bottom-4 left-4 right-4 bg-[#0A0118] backdrop-blur-sm rounded-xl p-4 border border-white/10 h-[88px]">
                      <div className="flex flex-col justify-center h-full">
                        <h3 className="text-base font-semibold text-white">
                          {step.title}
                        </h3>
                        <p className="text-sm text-[#6c7280] line-clamp-1">
                          {step.description}
                        </p>
                      </div>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </div>
        </section>

        {/* Who is it for Section */}
        <section className="py-12 sm:py-16 lg:py-24 relative">
          <motion.div
            className="absolute right-0 bottom-0 w-72 sm:w-96 h-72 sm:h-96 bg-primary rounded-full blur-[120px] opacity-10"
            animate={{
              scale: [1, 1.2, 1],
              opacity: [0.1, 0.2, 0.1],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
            }}
          />

          <div className="relative max-w-[1400px] mx-auto px-6 md:px-8">
            <div className="relative flex flex-col md:flex-row md:items-center justify-between">
              {/* Image */}
              <motion.div
                initial={{ opacity: 0, x: -20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: true }}
                className="flex-shrink-0"
              >
                <img
                  src={whoIsThisForImg}
                  alt="Friends hanging out"
                  className="w-full max-w-[600px] aspect-[4/3] object-contain rounded-3xl bg-dark-50"
                />
              </motion.div>

              {/* Text Content */}
              <motion.div
                initial={{ opacity: 0, x: 20 }}
                whileInView={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.8 }}
                viewport={{ once: true }}
                className="mt-8 md:mt-0 md:ml-20 max-w-[480px] md:py-20"
              >
                <h2 className="text-2xl md:text-4xl font-bold tracking-tight">
                  Why and who is this for?
                </h2>
                <div className="space-y-4">
                  <p className="mt-4 text-base md:text-lg text-gray-400 leading-relaxed whitespace-pre-wrap">
                    Friends move away, jobs get hectic, families grow... but
                    just because we are busier doesn&apos;t mean our friendships
                    should suffer. Life gets in the way - we get it - but
                    Caleido is here to help. We are on a mission to empower
                    people to have more time together IRL, and less time
                    connecting online. We are here to help you foster, grow, and
                    maintain your relationships. Less planning. More hanging.
                  </p>
                </div>
              </motion.div>
            </div>
          </div>
        </section>

        {/* Footer */}
        <footer className="border-t border-white/10 py-8">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8">
            <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
              {/* Left side */}
              <div className="flex flex-wrap gap-4 justify-center sm:justify-start">
                <a
                  href="https://www.instagram.com/caleido.social?igsh=MTJxZmE4a3B2cHVjdA%3D%3D&utm_source=qr"
                  className="text-sm text-gray-400 hover:text-white transition-colors"
                >
                  Instagram
                </a>
                <a
                  href="https://www.tiktok.com/@caleido?_t=ZT-8tFbnUrDDnq&_r=1 "
                  className="text-sm text-gray-400 hover:text-white transition-colors"
                >
                  TikTok
                </a>
                <button
                  onClick={() => setShowSupport(true)}
                  className="text-sm text-gray-400 hover:text-white transition-colors"
                >
                  Support
                </button>
              </div>
              {/* Right side */}
              <div className="flex flex-wrap gap-4 justify-center sm:justify-end mt-4 sm:mt-0">
                <a
                  href="privacy"
                  className="text-sm text-gray-400 hover:text-white transition-colors"
                >
                  Privacy
                </a>
                <a
                  href="terms"
                  className="text-sm text-gray-400 hover:text-white transition-colors"
                >
                  Terms and Conditions
                </a>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </div>
  );
}
