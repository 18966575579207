import React, { useEffect, useMemo, useRef, useState } from 'react';
import { cn } from 'utils/helpers';
import { Text } from 'components/text/primary/Text';

type Option = {
  label: string;
  value: string;
};

type Props = {
  label: string;
  options: Option[];
  selectedOption?: string | null;
  onChange: (option: string | null) => void;
};

const ROTATION_ANGLE = 30;

const WheelSelector = ({ label, options, selectedOption, onChange }: Props) => {
  const scrollWrapperRef = useRef<HTMLDivElement>(null);
  const optionsWrapperRef = useRef<HTMLDivElement>(null);

  const [currentScrollProgress, setCurrentScrollProgress] = useState<number>(0);

  useEffect(() => {
    const scrollWrapper = scrollWrapperRef.current;

    const handleScroll = (e: any) => {
      e.preventDefault();

      const currentScroll =
        100 -
        (e.target.scrollTop / (e.target.scrollHeight - e.target.clientHeight)) *
          100;

      const percentagePerItem = 100 / (options.length - 1);
      const currentItem = Math.round(currentScroll / percentagePerItem);

      const currentItemPercentage = currentItem * percentagePerItem;

      setCurrentScrollProgress(currentItemPercentage);

      onChange(
        options[Math.max(0, Math.min(currentItem, options.length - 1))].value,
      );
    };

    if (scrollWrapper) {
      scrollWrapper.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollWrapper) {
        scrollWrapper.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  useEffect(() => {
    const scrollWrapper = scrollWrapperRef.current;

    if (selectedOption && scrollWrapper) {
      const selectedItemIndex = options.findIndex(
        (option) => option.value === selectedOption,
      );

      if (selectedItemIndex !== -1) {
        const percentagePerItem = 100 / options.length;
        const selectedItemPercentage = selectedItemIndex * percentagePerItem;

        if (selectedItemPercentage !== currentScrollProgress) {
          setCurrentScrollProgress(selectedItemPercentage);
        }
      }
    } else if (!selectedOption && options[0]) {
      onChange(options[0].value);
    }
  }, [selectedOption]);

  useEffect(() => {
    const scrollWrapper = scrollWrapperRef.current;

    if (scrollWrapper) {
      scrollWrapper.scrollTop = scrollWrapper.scrollHeight;
    }
  }, []);

  const getItemStyles = (index: number) => {
    // Calculate total rotation range based on number of options
    const totalRange = (options.length - 1) * ROTATION_ANGLE;
    // Map scroll progress (0-100) to rotation range
    const progressRatio = currentScrollProgress / 100;
    const currentRotation = totalRange * progressRatio;

    // Calculate rotation for each option
    return {
      transform: `rotateX(${ROTATION_ANGLE * index - currentRotation}deg) translateZ(90px)`,
      opacity:
        Math.abs(ROTATION_ANGLE * index - currentRotation) <= 85 ? '1' : '0',
    };
  };

  return (
    <div className="relative select-none">
      <Text size="small" className="font-400 self-start">
        {label}
      </Text>

      <div className="relative h-[268px] w-full">
        <div
          ref={scrollWrapperRef}
          className="absolute inset-0 w-full overflow-y-scroll cursor-grab active:cursor-grabbing"
        >
          <div
            className="w-full"
            style={{
              height: `${options.length * 100}px`,
            }}
          ></div>
        </div>

        <div
          ref={optionsWrapperRef}
          className="absolute inset-0 bottom-6 w-full z-10 pointer-events-none"
        >
          {options.map((option, index) => (
            <div
              key={option.value}
              className="absolute w-full flex items-center justify-center top-1/2 transition-all duration-150"
              style={getItemStyles(index)}
            >
              {option.label}
            </div>
          ))}
        </div>

        {/* Selection indicator */}
        <div className="absolute left-4 right-4 h-[44px] top-1/2 -translate-y-1/2 bg-[#32395E] rounded-[0.6rem] pointer-events-none">
          <div className="h-full border-y border-white/10" />
        </div>
      </div>
    </div>
  );
};

export default WheelSelector;
