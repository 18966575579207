import Button from 'components/v2/button/Button';
import { useNavigate } from 'react-router-dom';

const EmptyEventsList = () => {
  const navigate = useNavigate();

  return (
    <div className="w-full h-full flex flex-col justify-center items-center gap-6 mt-[5svh]">
      <div className="flex flex-col gap-2">
        <p className="text-center text-gray-500">No events to display.</p>
        <p className="text-center text-gray-500">Start a hangout</p>
      </div>
      <div className="w-[50%] flex justify-center items-center">
        <Button
          variant="primary"
          className="w-full py-3"
          onClick={() => {
            navigate('/lets-meet');
          }}
        >
          Create a hangout
        </Button>
      </div>
    </div>
  );
};

export default EmptyEventsList;
