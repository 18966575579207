import dayjs from 'dayjs';
import { MapPin, Send } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import Button from 'components/v2/button/Button';
import {
  useGetAvailableContactsQuery,
  useRespondGuestEventMutation,
} from 'services/event.service';
import { cn } from 'utils/helpers';
import { EventStatus } from 'utils/constants';
import { useAuth } from 'modules/auth/context/AuthContextV2';
import UpdatesCard from './UpdatesCard';
import UpdateButton from './UpdateButton';
import EmojiStack from './EmojiStack';
import 'swiper/css';
import CalendarCard from './Updates/CalendarCard';
import InviteFriendsCard from './Updates/InviteFriendsCard';
import { UpdateCard } from '../hooks/useUpdates';
import FriendsCard from './Updates/FriendsCard';
import InviteEventCard from './Updates/InviteEventCard';
import { IEvent } from 'types/event';

type Props = {
  updates: UpdateCard[];
  handleDismiss: (eventId: string, expiresAt?: Date) => void;
};

const Updates = ({ updates, handleDismiss }: Props) => {
  const { data: availableContacts } = useGetAvailableContactsQuery();
  const { user } = useAuth();

  if (!user) {
    return <div className="flex items-center justify-center"></div>;
  }

  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={50}
      style={{
        width: '100%',
        overflow: 'visible',
      }}
    >
      {updates
        .filter((event) => event.type === 'event')
        .map((event: any, index: number) => (
          <SwiperSlide
            key={index}
            className="relative shadow-[0px_-20px_40px_1px_#401DBD] rounded-[4rem]"
          >
            <InviteEventCard
              event={event.eventData}
              handleDismiss={handleDismiss}
            />
          </SwiperSlide>
        ))}
      {updates.find(
        (event) =>
          event.type === 'friends' &&
          availableContacts &&
          availableContacts?.data?.length > 0,
      ) && (
        <SwiperSlide className="relative shadow-[0px_-20px_40px_1px_#401DBD] rounded-[6rem]">
          <FriendsCard handleDismiss={handleDismiss} />
        </SwiperSlide>
      )}
      {!user.isCalendarSync &&
        updates.find((event) => event.type === 'calendar') && (
          <SwiperSlide className="relative shadow-[0px_-20px_40px_1px_#401DBD] rounded-[6rem]">
            <CalendarCard handleDismiss={handleDismiss} />
          </SwiperSlide>
        )}
      {updates.find((event) => event.type === 'inviteFriends') && (
        <SwiperSlide className="relative shadow-[0px_-20px_40px_1px_#401DBD] rounded-[6rem]">
          <InviteFriendsCard handleDismiss={handleDismiss} />
        </SwiperSlide>
      )}
    </Swiper>
  );
};

export default Updates;
