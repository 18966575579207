import React from 'react';

type ToggleSwitchProps = {
  isChecked: boolean;
  onChange: (checked: boolean) => void;
  className?: string;
  disabled?: boolean;
};

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  isChecked,
  onChange,
  className,
  disabled = false,
}) => {
  return (
    <label className={`inline-flex items-center ${disabled ? 'cursor-not-allowed opacity-60' : 'cursor-pointer'} ${className}`}>
      <input
        type="checkbox"
        className="sr-only peer"
        checked={isChecked}
        onChange={(event) => !disabled && onChange(event.target.checked)}
        disabled={disabled}
      />
      <div className={`relative w-9 h-5 ${isChecked ? 'bg-secondary' : 'bg-gray-400 bg-opacity-40'} peer-focus:outline-none rounded-full peer after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-blue-950 after:rounded-full after:h-4 after:w-4 after:transition-all ${isChecked ? 'after:translate-x-full rtl:after:-translate-x-full' : ''}`}></div>
    </label>
  );
};

export default ToggleSwitch;