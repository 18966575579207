import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  ReactNode,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthService } from 'modules/auth/services/auth.service';
import tokenUtils from 'modules/auth/utils/tokenUtils';
import { isPWA } from 'utils/isPWA';
import apiClient from 'services/apiClient';
import OverlayLoader from 'components/loader/OverlayLoader';
import { IUser } from '../types/types';
import { notificationService } from 'services/notification-v2.service';
import { webPushNotifications } from 'utils/webPushNotifications';

const PWA_MODE_KEY = 'pwa_mode_enabled';
const SESSION_ID_KEY = 'session_id';

interface AuthFlowState {
  phoneNumber: {
    code: string;
    number: string;
    to: string;
  };
  formattedPhoneNumber: string;
  termsAccepted: boolean;
}

interface AuthContextType {
  // Persistent auth state
  user: IUser | null;
  loading: boolean;
  initialized: boolean;
  isPWAMode: boolean;
  isAuthenticated: boolean;

  // Auth flow state
  authFlow: AuthFlowState;

  // Methods
  refreshUserData: () => Promise<boolean>;
  login: (response: {
    user: IUser;
    tokens: { access: { token: string }; refresh: { token: string } };
  }) => void;
  logout: () => void;
  secureLogout: () => void; // New method for complete logout
  updateAuthFlow: (updates: Partial<AuthFlowState>) => void;
  resetAuthFlow: () => void;
}

const DEFAULT_AUTH_FLOW_STATE: AuthFlowState = {
  phoneNumber: { code: '+1', number: '', to: '' },
  formattedPhoneNumber: '',
  termsAccepted: false,
};

const PUBLIC_PATHS = [
  '/auth',
  '/share',
  '/public',
  '/landing',
  '/terms',
  '/privacy',
  '/about',
] as const;

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

const logAuth = (action: string, data?: any) => {
  // console.log(`🔐 Auth [${action}]`, data || '');
};

const isPublicPath = (path: string): boolean => {
  logAuth('Check Public Path', { path });
  return PUBLIC_PATHS.some((p) => path.startsWith(p));
};

// Generate a unique session ID
const generateSessionId = () => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
};

// Get or create a session ID
const getSessionId = () => {
  let sessionId = localStorage.getItem(SESSION_ID_KEY);
  if (!sessionId) {
    sessionId = generateSessionId();
    localStorage.setItem(SESSION_ID_KEY, sessionId);
  }
  return sessionId;
};

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const navigate = useNavigate();
  const sessionId = getSessionId();

  // Add PWA state
  const [isPWAMode, setIsPWAMode] = useState<boolean>(() => {
    const storedValue = localStorage.getItem(PWA_MODE_KEY) === 'true';
    const isPWAEnabled = isPWA();
    logAuth('PWA Mode Init', { storedValue, isPWAEnabled });
    return Boolean(storedValue || isPWAEnabled);
  });

  // Persistent auth state - update to use IUser
  const [user, setUser] = useState<IUser | null>(() => {
    const saved = localStorage.getItem('user');
    logAuth('Init User State', saved ? 'Found saved user' : 'No saved user');
    const token = tokenUtils.getToken();
    logAuth('Token Check', {
      hasToken: !!token,
      hasSavedUser: !!saved,
    });

    // In PWA mode, try to restore tokens from backup if they're missing
    if (isPWA() && (!token || !tokenUtils.getRefreshToken())) {
      logAuth('PWA Mode: Attempting to restore tokens from backup');
      const restored = tokenUtils.restoreTokensFromBackup();
      logAuth('Token restoration result', { restored });
    }

    // If we have saved user but no tokens, clear user data
    if (saved && !tokenUtils.getToken() && !tokenUtils.getRefreshToken()) {
      logAuth('Found saved user but no tokens, clearing user');
      localStorage.removeItem('user');
      return null;
    }

    // If we have a token but no saved user, try to fetch
    if (!saved && tokenUtils.getToken()) {
      logAuth('Token exists but no saved user, will fetch user data');
      apiClient
        .get('/auth/whoami')
        .then((response) => {
          logAuth('Retrieved user data', { userId: response.data._id });
          login({
            user: response.data,
            tokens: {
              access: { token: tokenUtils.getToken() ?? '' },
              refresh: { token: tokenUtils.getRefreshToken() ?? '' },
            },
          });
        })
        .catch((error) => {
          logAuth('Failed to get user data', error);
          // Only clear tokens in non-PWA mode
          if (!isPWA()) {
            tokenUtils.clearTokens();
          }
        });
    }

    return saved ? JSON.parse(saved) : null;
  });

  const [loading, setLoading] = useState(false);
  const [initialized, setInitialized] = useState(false);
  const [isAppReady, setIsAppReady] = useState(false);

  // Auth flow state
  const [authFlow, setAuthFlow] = useState<AuthFlowState>(
    DEFAULT_AUTH_FLOW_STATE,
  );

  // Auth flow methods
  const updateAuthFlow = (updates: Partial<AuthFlowState>) => {
    logAuth('Update Auth Flow', updates);
    setAuthFlow((prev) => ({
      ...prev,
      ...updates,
    }));
  };

  const resetAuthFlow = () => {
    logAuth('Reset Auth Flow');
    setAuthFlow(DEFAULT_AUTH_FLOW_STATE);
  };

  // Update login to use IUser
  const login = (response: {
    user: IUser;
    tokens: { access: { token: string }; refresh: { token: string } };
  }) => {
    logAuth('Login', { userId: response.user._id });

    // Save user
    localStorage.setItem('user', JSON.stringify(response.user));
    setUser(response.user);

    // Save tokens
    tokenUtils.setToken(response.tokens.access.token);
    tokenUtils.setRefreshToken(response.tokens.refresh.token);

    resetAuthFlow();
  };

  // Add PWA check effect
  useEffect(() => {
    const checkPWAMode = () => {
      const isPWAEnabled = isPWA();
      logAuth('PWA Check', { isPWAEnabled });
      if (isPWAEnabled) {
        localStorage.setItem(PWA_MODE_KEY, 'true');
        setIsPWAMode(true);
      }
    };

    checkPWAMode();
  }, []);

  // Add token refresh on PWA startup
  useEffect(() => {
    if (!isPWAMode) return;

    const refreshTokenOnPWAStartup = async () => {
      const refreshToken = tokenUtils.getRefreshToken();

      if (refreshToken) {
        logAuth('PWA Mode: Attempting token refresh on startup');
        try {
          await refreshTokenWithRetry(refreshToken);
          logAuth('PWA Mode: Token refresh successful on startup');
        } catch (error) {
          logAuth('PWA Mode: Token refresh failed on startup', error);
          // Even if refresh fails, we keep the tokens for potential future refresh attempts
        }
      }
    };

    refreshTokenOnPWAStartup();
  }, [isPWAMode]);

  // Standard logout - preserves tokens in PWA mode
  const logout = () => {
    logAuth('Logout', { isPWAMode });
    localStorage.removeItem('user');

    // Only clear tokens if not in PWA mode
    if (!isPWAMode) {
      tokenUtils.clearTokens();
      logAuth('Tokens cleared (non-PWA mode)');
    } else {
      logAuth('Tokens preserved for PWA mode');
    }

    setUser(null);
    resetAuthFlow();
    navigate('/auth/login');
  };

  // Secure logout - always clears tokens, even in PWA mode
  const secureLogout = () => {
    logAuth('Secure Logout (clearing all tokens)');
    localStorage.removeItem('user');
    tokenUtils.clearTokens(true); // Force clear all tokens
    setUser(null);
    resetAuthFlow();
    navigate('/auth/login');
  };

  // Token refresh with retry mechanism
  const refreshTokenWithRetry = async (
    refreshToken: string,
    maxRetries = 3,
  ): Promise<boolean> => {
    let retries = 0;

    const attemptRefresh = async (): Promise<boolean> => {
      try {
        await AuthService.refreshTokens(refreshToken);
        logAuth('Token Refresh Success');
        return true;
      } catch (error: any) {
        const isNetworkError =
          error.message &&
          (error.message.includes('Network Error') ||
            error.message.includes('Failed to fetch'));

        if (isNetworkError && retries < maxRetries) {
          retries++;
          logAuth(
            `Network error during token refresh, retrying (${retries}/${maxRetries})`,
            error,
          );
          // Exponential backoff: 1s, 2s, 4s
          await new Promise((resolve) =>
            setTimeout(resolve, 1000 * Math.pow(2, retries - 1)),
          );
          return attemptRefresh();
        }

        logAuth('Token Refresh Failed', error);
        return false;
      }
    };

    return attemptRefresh();
  };

  const refreshUserData = async (): Promise<boolean> => {
    const accessToken = tokenUtils.getToken();
    const refreshToken = tokenUtils.getRefreshToken();

    if (!accessToken) {
      logAuth('Refresh Failed', 'No access token');
      return false;
    }

    try {
      logAuth('Refreshing User Data');
      setLoading(true);
      const response = await apiClient.get('/auth/whoami');

      // Construct the login payload with existing tokens
      login({
        user: response.data as IUser,
        tokens: {
          access: { token: accessToken },
          refresh: { token: refreshToken || '' },
        },
      });

      logAuth('Refresh Success');
      return true;
    } catch (error) {
      logAuth('Refresh Failed', error);
      return false;
    } finally {
      setLoading(false);
    }
  };

  // Session initialization effect
  useEffect(() => {
    if (initialized) {
      logAuth('Already Initialized');
      setIsAppReady(true);
      return;
    }

    const initSession = async () => {
      logAuth('Initializing Session', { sessionId });
      setLoading(true);

      try {
        let accessToken = tokenUtils.getToken();
        let refreshToken = tokenUtils.getRefreshToken();

        logAuth('Token Check', {
          hasAccessToken: !!accessToken,
          hasRefreshToken: !!refreshToken,
        });

        // Try to restore tokens in PWA mode
        if (isPWAMode && (!accessToken || !refreshToken)) {
          logAuth('PWA Mode: Attempting to restore tokens');
          const restored = tokenUtils.restoreTokensFromBackup();
          if (restored) {
            accessToken = tokenUtils.getToken();
            refreshToken = tokenUtils.getRefreshToken();
            logAuth('PWA Mode: Tokens restored', {
              hasAccessToken: !!accessToken,
              hasRefreshToken: !!refreshToken,
            });
          }
        }

        if (!accessToken && !refreshToken) {
          if (!isPublicPath(window.location.pathname)) {
            logAuth('No Tokens - Redirecting to Login');
            navigate('/auth/login');
          }
          completeInitialization();
          return;
        }

        if (!accessToken && refreshToken) {
          logAuth('Attempting Token Refresh');
          const refreshSuccess = await refreshTokenWithRetry(refreshToken);
          if (refreshSuccess) {
            accessToken = tokenUtils.getToken();
            logAuth('Token Refresh Success');
          } else {
            logAuth('Token Refresh Failed');
            // Only clear tokens if not in PWA mode
            if (!isPWAMode) {
              tokenUtils.clearTokens();
            }
            if (!isPublicPath(window.location.pathname)) {
              navigate('/auth/login');
            }
            completeInitialization();
            return;
          }
        }

        if (accessToken) {
          try {
            logAuth('Fetching User Data');
            const response = await apiClient.get('/auth/whoami');
            login({
              user: response.data,
              tokens: {
                access: { token: accessToken },
                refresh: { token: refreshToken ?? '' },
              },
            });
          } catch (error: any) {
            logAuth('User Data Fetch Failed', error);

            if (error.response?.status === 401 && refreshToken) {
              logAuth('Attempting Token Refresh After 401');
              const refreshSuccess = await refreshTokenWithRetry(refreshToken);
              if (refreshSuccess) {
                try {
                  const retryResponse = await apiClient.get('/auth/whoami');
                  login({
                    user: retryResponse.data,
                    tokens: {
                      access: { token: tokenUtils.getToken() ?? '' },
                      refresh: { token: refreshToken ?? '' },
                    },
                  });
                  logAuth('User Data Fetch Retry Success');
                } catch (retryError) {
                  logAuth('User Data Fetch Retry Failed', retryError);
                  // Only clear tokens if not in PWA mode
                  if (!isPWAMode) {
                    tokenUtils.clearTokens();
                  }
                  if (!isPublicPath(window.location.pathname)) {
                    navigate('/auth/login');
                  }
                }
              } else {
                logAuth('Token Refresh Failed After 401');
                // Only clear tokens if not in PWA mode
                if (!isPWAMode) {
                  tokenUtils.clearTokens();
                }
                if (!isPublicPath(window.location.pathname)) {
                  navigate('/auth/login');
                }
              }
            } else if (!isPublicPath(window.location.pathname)) {
              navigate('/auth/login');
            }
          }
        }

        completeInitialization();
      } catch (error) {
        logAuth('Init Error', error);
        completeInitialization();
      }
    };

    const completeInitialization = () => {
      logAuth('Completing Initialization');
      setLoading(false);
      setInitialized(true);
      setIsAppReady(true);
    };

    initSession();
  }, [navigate, initialized, isPWAMode, sessionId]);

  // Cross-tab communication
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === 'logout') {
        logAuth('Storage Event - Logout');
        setInitialized(false);
        setIsAppReady(false);
        setUser(null);
        resetAuthFlow();

        // Only clear tokens if explicitly requested and not in PWA mode
        if (event.newValue === 'secure' || !isPWAMode) {
          tokenUtils.clearTokens(event.newValue === 'secure');
          logAuth('Tokens cleared via storage event');
        }

        if (!isPublicPath(window.location.pathname)) {
          navigate('/auth/login');
        }
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [navigate, isPWAMode]);

  // Notification setup effect
  useEffect(() => {
    if (!user?._id || loading || !initialized) return;

    const setupNotifications = async () => {
      logAuth('Setting Up Notifications', {
        userId: user._id,
        isPWAMode: isPWAMode,
      });

      try {
        if (!user._id) {
          logAuth('User ID is missing, skipping notification setup');
          return;
        }

        // Initialize notification system WITHOUT requesting permission
        // This separation is key for better UX
        await notificationService.initialize(user._id);

        // Update PWA mode on server if needed
        if (isPWAMode) {
          try {
            await apiClient.patch('/users/settings', {
              usingPWAMode: true,
            });
            logAuth('PWA Mode Updated on Server');
          } catch (error) {
            logAuth('Failed to update PWA mode on server', error);
          }
        }

        logAuth('Notification System Initialized');
      } catch (error) {
        logAuth('Notification Setup Failed', error);
        // Continue app flow even if notifications fail
      }
    };

    setupNotifications();
  }, [user, loading, initialized, isPWAMode]);

  // Token refresh based on expiration time
  useEffect(() => {
    if (!initialized || !user || !tokenUtils.getRefreshToken()) return;

    // Set up periodic token refresh if needed
    const checkTokenExpiry = () => {
      if (tokenUtils.isTokenExpired()) {
        const refreshToken = tokenUtils.getRefreshToken();
        if (refreshToken) {
          logAuth('Token expired, attempting refresh');
          refreshTokenWithRetry(refreshToken)
            .then((success) => {
              logAuth('Auto-refresh result', { success });
            })
            .catch((error) => {
              logAuth('Auto-refresh error', error);
            });
        }
      }
    };

    // Check initially
    checkTokenExpiry();

    // Set up interval to check (every 5 minutes)
    const intervalId = setInterval(checkTokenExpiry, 5 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, [initialized, user]);

  if (!isAppReady) {
    logAuth('App Not Ready');
    return <OverlayLoader />;
  }

  return (
    <AuthContext.Provider
      value={{
        user,
        loading,
        initialized,
        isPWAMode,
        isAuthenticated: !!user,
        authFlow,
        refreshUserData,
        login,
        logout,
        secureLogout,
        updateAuthFlow,
        resetAuthFlow,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
