import EmojiAvatar from 'modules/home/pages/homePage/EmojiAvatar';
import Button from 'components/v2/button/Button';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const ContactItem = ({
  name,
  photoURL,
  isUser,
  onClick,
  phoneNumber,
  isAvailable,
}: {
  name: string;
  photoURL: string;
  isUser: boolean;
  phoneNumber: string;
  onClick: React.MouseEventHandler<HTMLDivElement>;
  isAvailable: boolean;
}) => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate(
      `/lets-meet?selectedContactsIds=${encodeURIComponent(phoneNumber)}`,
    );
  };

  return (
    <div
      onClick={onClick}
      className="flex items-center gap-4 px-5 py-4 bg-[#110E2B] rounded-[1rem] cursor-pointer"
    >
      <div className="relative">
        <EmojiAvatar image={photoURL} fullName={name} isOnline={isAvailable} />
      </div>

      <div className="flex flex-col justify-center items-start">
        <div className="flex gap-1 text-xs font-semibold text-center truncate contact-search-name">
          <div className="text-[0.875rem] font-semibold">
            {name.split(' ')[0]}
          </div>
          {isAvailable && (
            <div className="text-[0.75rem] text-[#ABE49C] font-medium text-center truncate contact-search-name">
              Available
            </div>
          )}
        </div>
        <div className="text-[0.875rem] text-[rgb(255,255,255,0.8)] font-normal text-center">
          {name}
        </div>
      </div>
      {isUser && (
        <div className="ml-auto" onClick={(e) => e.stopPropagation()}>
          <Button
            variant="secondary"
            className="text-[0.813rem] font-medium font-manrope px-4 py-3"
            onClick={handleButtonClick}
          >
            Let's hang
          </Button>
        </div>
      )}
    </div>
  );
};

export default ContactItem;
