import { PlusIcon, X } from 'lucide-react';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Text } from 'components/text/primary/Text';
import { compareHoursAndMinutes } from 'modules/letsMeet/utils/dates';
import { AvailabilitySlot } from 'services/calendar.service';
import TimeSlotPickerPopup from './TimeSlotPickerPopup';

type Props = {
  selectedDate: Date;
  selectedDatesWithTime: Date[];
  setSelectedDatesWithTime: (dates: Date[]) => void;
  availableTimeSlots: AvailabilitySlot[];
};

const TimeSlotPicker = ({
  selectedDate,
  selectedDatesWithTime,
  setSelectedDatesWithTime,
  availableTimeSlots,
}: Props) => {
  const [isTimeSlotChosePopupOpen, setIsTimeSlotChosePopupOpen] =
    useState(false);

  const sortedSelectedDatesWithTime = useMemo(
    () => [...selectedDatesWithTime].sort((a, b) => a.getTime() - b.getTime()),
    [selectedDatesWithTime],
  );

  return (
    <div className="flex flex-col gap-6 pt-4 pb-6">
      <Text size="small" className="font-400">
        {dayjs(selectedDate).format('ddd, MMM D YYYY')}
      </Text>
      <div className="flex flex-wrap gap-2">
        {sortedSelectedDatesWithTime.map((date) => (
          <div
            className="flex items-center gap-1 bg-[#32395E] rounded-full px-3 py-3 border border-transparent"
            key={date.toISOString()}
          >
            <Text size="small" className="whitespace-nowrap font-400">
              {dayjs(date).format('hh:mm A')}
            </Text>
            <X
              onClick={() => {
                setSelectedDatesWithTime(
                  selectedDatesWithTime.filter(
                    (d) => !compareHoursAndMinutes(d, date),
                  ),
                );
              }}
              className="w-4 h-4 text-[#ABE49C] cursor-pointer"
            />
          </div>
        ))}

        <button
          className="flex items-center gap-1 text-[#ABE49C] border border-[#ABE49C] rounded-full pr-3 pl-2 py-3 text-sm font-400 w-max"
          onClick={() => setIsTimeSlotChosePopupOpen(true)}
        >
          <PlusIcon className="w-5 h-5" />
          Add time slot
        </button>
      </div>
      <TimeSlotPickerPopup
        selectedDate={selectedDate}
        isOpen={isTimeSlotChosePopupOpen}
        setIsOpen={setIsTimeSlotChosePopupOpen}
        selectedTimeSlots={selectedDatesWithTime}
        setSelectedTimeSlots={setSelectedDatesWithTime}
        availableTimeSlots={availableTimeSlots}
      />
    </div>
  );
};

export default TimeSlotPicker;
