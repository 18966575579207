import React, { useState } from 'react';
import { ReactComponent as CaleidoLogo } from 'assets/images/common/logo.svg';
import { ReactComponent as ButterflyIcon } from 'assets/images/common/logo-butterfly.svg';
import { OptionIcon } from 'lucide-react';
import { DeleteIcon } from 'lucide-react';
import SideBarButton from 'components/sidebar/sidebarButton';
import { Link } from 'react-router-dom';
type Props = {};

const Header = (props: Props) => {
  const [menuVisible, setMenuVisible] = useState(false);

  return (
    <div className="fixed top-0 w-full flex items-center bg-[#0a0118] justify-between px-5 py-2 z-30">
      <div className="flex items-center">
        <Link to="/" className="flex items-center">
          <CaleidoLogo className="h-[0.875rem]" />
          <ButterflyIcon className="w-4 h-4 ml-1" />
        </Link>
      </div>

      <div className="relative">
        <button
          onClick={() => setMenuVisible(!menuVisible)}
          className="flex items-center"
        >
          <SideBarButton />
        </button>
      </div>
    </div>
  );
};

export default Header;
