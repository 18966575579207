import React from 'react';
import { cn } from 'utils/helpers';

type ButtonProps = {
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  variant?: 'primary' | 'destructive' | 'secondary';
  disabled?: boolean;
  className?: string;
};

const Button = ({
  children,
  onClick,
  variant = 'primary',
  disabled = false,
  className,
}: ButtonProps) => {
  return (
    <button
      className={cn(
        'w-full text-center py-5 rounded-[6.25rem] transition-opacity duration-300',
        {
          'bg-[#6000E9] text-white': variant === 'primary',
          'bg-transparent text-[#FF6A62]': variant === 'destructive',
          'bg-[#32395E] text-white': variant === 'secondary',
          'opacity-50': disabled,
        },
        className,
      )}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </button>
  );
};

export default Button;
