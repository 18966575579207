import dayjs from 'dayjs';
import React from 'react';
import UpdateButton from '../UpdateButton';
import UpdatesCard from '../UpdatesCard';
import { MapPin, Send } from 'lucide-react';
import { cn } from 'utils/helpers';
import { EventStatus } from 'utils/constants';
import { useAuth } from 'modules/auth/context/AuthContextV2';
import EmojiStack from '../EmojiStack';
import Button from 'components/v2/button/Button';
import { IEvent } from 'types/event';
import { useRespondGuestEventMutation } from 'services/event.service';

type Props = {
  event: IEvent;
  handleDismiss: (eventId: string, expiresAt?: Date) => void;
};

const InviteEventCard = ({ event, handleDismiss }: Props) => {
  const { user } = useAuth();
  const [respondGuestEvent] = useRespondGuestEventMutation();
  if (!user) return null;
  return (
    <div>
      <div className="absolute bg-transparent w-full h-full shadow-[0px_50px_110px_-61px_#AAA8FF] rounded-[6rem] z-[-1]"></div>
      <UpdatesCard
        title={`${event?.activity} ${
          dayjs(event?.start).isSame(dayjs(), 'day')
            ? 'Today'
            : dayjs(event?.start).format('MMM D')
        } at ${
          dayjs(event?.start).minute() === 0
            ? dayjs(event?.start).format('hA')
            : dayjs(event?.start).format('h:mm A')
        }`}
        description="Copy your customer profile link below and share your profile with others to join you on Caleido for faster meetup coordination"
        emoji="👬"
        eventId={event?._id}
        infoChildren={
          <div className="flex flex-col gap-2">
            {event?.location && (
              <div className="flex items-center gap-1">
                <MapPin className="text-[#D7C0FB] w-4 h-4" />

                <div className="text-[0.875rem]">{event?.location}</div>
              </div>
            )}
            <div className="flex items-center gap-1">
              <Send className="text-[#D7C0FB] w-4 h-4" />
              <div className="text-[0.875rem]">
                {event?.organizer} invited you
              </div>
            </div>
            <div
              className={cn(
                'flex items-center',
                event?.contacts?.length === 1 && 'gap-1',
              )}
            >
              <EmojiStack
                images={
                  event?.contacts
                    ? event?.contacts?.map((contact: any) => ({
                        image: contact.image,
                        name: contact.name,
                        username: contact.username,
                      }))
                    : []
                }
                size="small"
              />
              <div className="flex justify-between">
                {event?.contacts && event?.contacts?.length > 0 && (
                  <div>
                    {event?.contacts
                      .slice(0, 2)
                      .map((contact: any, index: number) => (
                        <span key={index}>
                          {contact.name.split(' ')[0]}
                          {index < (event?.contacts?.length ?? 0) - 1 && ', '}
                        </span>
                      ))}
                    {event?.contacts?.length > 2 && (
                      <span>
                        {` and +${event?.contacts?.length - 2} going`}
                      </span>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        }
        actionsChildren={
          <>
            {event?.contacts &&
            event?.contacts?.some?.(
              (contact: any) =>
                contact.phoneNumber === user?.phoneNumber &&
                contact.status === EventStatus.NotResponded,
            ) ? (
              <Button
                variant="secondary"
                className="py-3"
                onClick={() => {
                  respondGuestEvent({
                    eventId: event?._id,
                    name: user?.name,
                    response: EventStatus.Accepted,
                  });
                }}
              >
                Tap to join
              </Button>
            ) : (
              <UpdateButton
                options={[
                  { label: 'Accept', value: 'Accepted' },
                  { label: 'Decline', value: 'Declined' },
                  { label: 'Maybe', value: 'Maybe' },
                ]}
                selectedValue={{ label: 'Accept', value: 'Accepted' }}
                setSelectedValue={(value) => {
                  respondGuestEvent({
                    eventId: event?._id,
                    name: user?.name,
                    response:
                      EventStatus[value.value as keyof typeof EventStatus],
                  });
                }}
                eventId={event?._id}
              >
                Tap to join
              </UpdateButton>
            )}
            <Button
              variant="secondary"
              className="py-3"
              onClick={() => {
                const expiresAt = event?.end
                  ? dayjs(event?.end).toDate()
                  : event?.start
                    ? dayjs(event?.start).toDate()
                    : undefined;
                handleDismiss(event?._id, expiresAt);
              }}
            >
              Dismiss
            </Button>
          </>
        }
      />
    </div>
  );
};

export default InviteEventCard;
