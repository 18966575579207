import { Text } from 'components/text/primary/Text';
import { cn } from 'utils/helpers';

type EmojiProps = {
  emoji: string;
  size?: 'small' | 'medium' | 'large';
};
export const Emoji = (props: EmojiProps) => {
  const size = props.size || 'large';

  const container = {
    small: 'h-6 w-6',
    medium: 'h-10 w-10',
    large: 'h-12 w-12',
  }[size];

  return (
    <div
      className={cn(
        'rounded-full bg-transparent flex justify-center items-center flex-shrink-0',
        container,
      )}
    >
      <Text size={size} className="font-semibold text-center leading-none">
        {props.emoji}
      </Text>
    </div>
  );
};
