import { cn } from 'utils/helpers';

type ActivityLayoutProps = {
  children: React.ReactNode;
};

const ActivityLayout: React.FC<ActivityLayoutProps> = ({ children }) => {
  return (
    <div className="flex items-center justify-center text-white bg-main">
      <div
        className={cn(
          'flex flex-col min-h-screen w-full h-full max-w-md *:mx-5 py-8 relative',
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default ActivityLayout;
