import { ChevronDown } from 'lucide-react';
import { useState } from 'react';
import { Check } from 'lucide-react';
import { cn } from 'utils/helpers';

type DropdownFilterProps = {
  options: { label: string; value: string }[];
  selectedValue: { label: string; value: string };
  setSelectedValue: (value: { label: string; value: string }) => void;
};

const DropdownFilter = ({
  options,
  selectedValue,
  setSelectedValue,
}: DropdownFilterProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (option: { label: string; value: string }) => {
    setSelectedValue(option);
    setIsOpen(false);
  };

  return (
    <div className="relative">
      <button
        onClick={toggleDropdown}
        className="flex justify-between items-center text-white text-xs font-semibold bg-[#32395E] border-none active:border-none rounded-[1rem] px-3 py-2 whitespace-nowrap"
      >
        {selectedValue.label} <ChevronDown className="ml-2 h-4 w-4 shrink-0" />
      </button>
      {isOpen && (
        <div className="absolute right-0 flex flex-col bg-main whitespace-nowrap shadow-[1px_2px_10px_-5px_#ffffff71,-1px_2px_10px_0px_#ffffff71,0px_2px_10px_-6px_#ffffff71] text-white text-left font-normal leading-normal text-xs border-none mt-2 rounded-[0.75rem] z-[20]">
          <p className="uppercase font-semibold text-[rgb(255,255,255,0.6)] py-2 px-4">
            Sort by time
          </p>
          {options.map((option, index) => (
            <div
              key={option.value}
              className={cn(
                'py-3 px-4 flex items-center justify-between min-w-[13rem]',
                selectedValue.value === option.value && 'bg-[#32395E]',
                index === options.length - 1 && 'rounded-b-[0.75rem]',
              )}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
              {selectedValue.value === option.value && (
                <Check className="h-4 w-4 shrink-0" />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownFilter;
