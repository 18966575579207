import dayjs from 'dayjs';
import React, { useMemo, useState } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Separator } from 'components/ui/separator';
import { cn } from 'utils/helpers';

const WEEK_DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

type Props = {
  type: 'single' | 'multiple';
  selectedDates: Date[];
  setSelectedDates: (dates: Date[]) => void;
  only?: 'future-dates' | 'past-dates';
};

const CalendarPicker = ({
  type,
  selectedDates,
  setSelectedDates,
  only,
}: Props) => {
  const [currentMonth, setCurrentMonth] = useState(dayjs().month());
  const [currentYear, setCurrentYear] = useState(dayjs().year());

  const handlePrevMonth = () => {
    if (currentMonth === 0) {
      setCurrentMonth(11);
      setCurrentYear(currentYear - 1);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  const handleNextMonth = () => {
    if (currentMonth === 11) {
      setCurrentMonth(0);
      setCurrentYear(currentYear + 1);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const headerTitle = useMemo(() => {
    return `${dayjs().month(currentMonth).format('MMM')} ${currentYear}`;
  }, [currentMonth, currentYear]);

  const monthDaysArray = useMemo(() => {
    const firstDayOfMonth = dayjs()
      .year(currentYear)
      .month(currentMonth)
      .startOf('month');
    const startingDayOfWeek = firstDayOfMonth.day();
    const daysInMonth = firstDayOfMonth.daysInMonth();
    const daysFromPrevMonth = startingDayOfWeek;
    const totalDays = daysFromPrevMonth + daysInMonth;

    return Array.from({ length: totalDays }, (_, index) => {
      if (index < daysFromPrevMonth) {
        // Days from previous month
        const prevMonthDate = firstDayOfMonth.subtract(
          daysFromPrevMonth - index,
          'day',
        );

        return {
          isFiller: true,
          label: prevMonthDate.format('D'),
          value: prevMonthDate.toDate(),
        };
      }
      // Days from current month
      const currentMonthDate = firstDayOfMonth.add(
        index - daysFromPrevMonth,
        'day',
      );

      let isDisabled = false;

      switch (only) {
        case 'future-dates':
          if (
            currentMonthDate.isBefore(dayjs()) &&
            !currentMonthDate.isSame(dayjs(), 'day')
          ) {
            isDisabled = true;
          }
          break;
        case 'past-dates':
          if (
            currentMonthDate.isAfter(dayjs()) &&
            !currentMonthDate.isSame(dayjs(), 'day')
          ) {
            isDisabled = true;
          }
          break;
      }

      return {
        isFiller: false,
        label: currentMonthDate.format('D'),
        value: currentMonthDate.toDate(),
        isCurrentDay: currentMonthDate.isSame(dayjs(), 'day'),
        isSelected: selectedDates.some((date) =>
          dayjs(date).isSame(currentMonthDate, 'day'),
        ),
        isDisabled,
      };
    });
  }, [currentMonth, currentYear, selectedDates]);

  return (
    <div className="flex flex-col border border-[#D7C0FB] rounded-[0.5rem] px-3 py-5">
      <div className="flex items-center justify-between px-6 py-1">
        <button onClick={handlePrevMonth}>
          <ChevronLeft className="w-[1.125rem] h-[1.125rem]" />
        </button>
        <div className="text-[#ABE49C] text-[1.25rem] font-600">
          {headerTitle}
        </div>
        <button onClick={handleNextMonth}>
          <ChevronRight className="w-[1.125rem] h-[1.125rem]" />
        </button>
      </div>
      <Separator className="mb-1 mt-1 bg-[rgb(189,189,189,0.1)]" />
      <div className="grid grid-cols-7 px-6 gap-x-2 gap-y-1 text-center">
        {WEEK_DAYS.map((day) => (
          <div className="text-[0.585rem] font-normal font-manrope" key={day}>
            {day}
          </div>
        ))}
        {monthDaysArray.map((day) => (
          <div
            key={day.value.toISOString()}
            className={cn(
              'relative rounded-full w-8 h-8 flex items-center justify-center transition-colors text-[0.875rem] duration-300',
              {
                'opacity-0 pointer-events-none': day.isFiller,
                'bg-[#ABE49C] text-[#0A081A]': day.isSelected,
                'opacity-50 pointer-events-none': day.isDisabled,
              },
            )}
            onClick={() => {
              if (day.isDisabled) {
                return;
              }

              if (type === 'single') {
                if (day.isSelected) {
                  setSelectedDates([]);
                } else {
                  setSelectedDates([day.value]);
                }
              } else {
                if (day.isSelected) {
                  setSelectedDates(
                    selectedDates.filter(
                      (date) => !dayjs(date).isSame(day.value, 'day'),
                    ),
                  );
                } else {
                  setSelectedDates([...selectedDates, day.value]);
                }
              }
            }}
          >
            {day.label}
            {day.isCurrentDay && (
              <div
                className={cn(
                  'absolute bottom-0.5 w-1 h-1 bg-secondaryLight rounded-full transition-colors duration-300',
                  {
                    'bg-main': day.isSelected,
                  },
                )}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default CalendarPicker;
