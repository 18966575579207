import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

dayjs.extend(utc);
dayjs.extend(timezone);

export function parseRangeDateString(dateString: string) {
  const match = dateString.match(/(\d{4}-\d{2}-\d{2})-(.*)Z-(.*)Z/);

  if (!match) {
    return { startDate: null, endDate: null };
  }

  const [_, date, tz1, tz2] = match;

  const startDate = dayjs(`${date}T${tz1}:00`).utc().local();
  const endDate = dayjs(`${date}T${tz2}:00`).utc().local();

  return { startDate, endDate };
}
