import { Check, X } from 'lucide-react';
import React, { ReactNode, useMemo, useState } from 'react';
import { Text } from 'components/text/primary/Text';
import { useClickOutside } from 'hooks/useClickOutside';
import { cn } from 'utils/helpers';

interface Option {
  emoji: ReactNode;
  value: string;
  label: string;
  getEmoji?: (isSelected: boolean) => ReactNode;
}

interface SquareMultiSelectInputProps {
  setSelectedOptions: (options: string[]) => void;
  selectedOptions: string[];
  label: string;
  dropdownLabel: string;
  dropdownEmptyLabel: string;
  defaultEmoji: ReactNode;
  options: Option[];
  placeholder?: string;
  disabled?: boolean;
  error?: string | null;
}

export const SquareMultiSelectInput: React.FC<SquareMultiSelectInputProps> = ({
  label,
  dropdownLabel,
  dropdownEmptyLabel,
  defaultEmoji,
  options,
  setSelectedOptions,
  selectedOptions,
  placeholder = 'Enter text...',
  disabled,
  error,
}) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');

  const wrapperRef = useClickOutside<HTMLDivElement>(() => {
    setIsFocused(false);
    setInputValue('');
  });

  const filteredOptions = useMemo(
    () =>
      options.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase()),
      ),
    [options, inputValue],
  );

  const selectedOptionsInfo = useMemo(() => {
    return options.filter((option) => selectedOptions.includes(option.value));
  }, [options, selectedOptions]);

  const handleOptionClick = (option: Option): void => {
    if (selectedOptions.includes(option.value)) {
      setSelectedOptions(
        selectedOptions.filter((value) => value !== option.value),
      );
    } else {
      setSelectedOptions([...selectedOptions, option.value]);
    }

    setInputValue('');
  };

  const getEmojiToDisplay = (
    option: Option,
    isSelected: boolean,
  ): ReactNode => {
    if (option.getEmoji) {
      return option.getEmoji(isSelected);
    }
    return option.emoji;
  };

  return (
    <div>
      <div className="relative flex flex-col" ref={wrapperRef}>
        <div
          className={cn(
            'w-full min-h-[80px] border-solid border-2 border-[#D7C0FB] rounded focus-within:border-[#ABE49C] relative duration-300 ease-in-out',
            isFocused && 'rounded-b-none border-[#ABE49C]',
          )}
        >
          <div className="absolute top-[-0.750rem] left-5 flex items-center bg-main z-10 justify-start space-x-2 px-1">
            <Text
              size="small"
              className={cn(
                'text-base font-sofia-normal transition-colors duration-300 ease-in-out',
                isFocused ? 'text-[#ABE49C]' : 'text-[#D7C0FB]',
              )}
            >
              {label}
            </Text>
          </div>

          <div className="absolute top-[50%] -translate-y-1/2 left-6 text-2xl pointer-events-none">
            {defaultEmoji}
          </div>

          <div
            className={cn(
              'flex flex-wrap items-center gap-2 pl-16 pr-2',
              selectedOptionsInfo.length !== 0 && 'py-6',
            )}
          >
            {selectedOptionsInfo.map((option) => (
              <div
                key={option.value}
                className="flex items-center gap-2 bg-[#33335a] px-3 py-1 h-[36px] rounded-full"
              >
                {getEmojiToDisplay(option, true)}
                <span className="text-gray-300">{option.label}</span>
                <X
                  size={16}
                  className="cursor-pointer text-[#ABE49C]"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectedOptions(
                      selectedOptions.filter((value) => value !== option.value),
                    );
                  }}
                />
              </div>
            ))}

            <input
              disabled={disabled}
              className={cn(
                'flex-1 bg-transparent border-none outline-none text-lg font-sans text-gray-300 min-w-[120px] transition-opacity duration-300 ease-in-out',
                'placeholder:text-[#FFFFFF] placeholder:opacity-[0.6]',
                selectedOptionsInfo.length === 0 ? 'py-[26px]' : '',
                disabled && 'opacity-30',
              )}
              placeholder={placeholder}
              onFocus={() => setIsFocused(true)}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            />
          </div>
        </div>
        {isFocused && (
          <div
            className={cn(
              'absolute z-20 top-[calc(100%-2px)] bg-main rounded-b-xl left-0 right-0 border-2 border-solid h-auto py-2 duration-300 ease-in-out',
              isFocused ? 'border-[#ABE49C]' : 'border-[#D7C0FB]',
            )}
          >
            <p className="text-sm text-gray-500 px-4 mt-2 mb-4">
              {dropdownLabel}
            </p>

            {filteredOptions.length === 0 && (
              <div className="text-sm px-4 py-4">
                <div className="font-medium">{dropdownEmptyLabel}</div>
              </div>
            )}

            {filteredOptions.length > 0 && (
              <div className="flex flex-col gap-1 max-h-[250px] overflow-scroll">
                {filteredOptions.map((option) => (
                  <div
                    className={cn(
                      'p-4 cursor-pointer text-md font-sans flex items-center gap-2 hover:bg-[#33335a] duration-300 ease-in-out',
                      selectedOptions.includes(option.value) && 'bg-[#33335a]',
                    )}
                    key={option.value}
                    onClick={() => handleOptionClick(option)}
                  >
                    <div className="flex items-center gap-2">
                      {getEmojiToDisplay(option, false)} {option.label}
                    </div>

                    {selectedOptions.includes(option.value) && (
                      <Check
                        className="text-[#ABE49C] text-xl ml-auto"
                        size={16}
                      />
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
      {error && (
        <div className="text-red-500 text-sm px-2 mt-1 mb-1">{error}</div>
      )}
    </div>
  );
};
