import Button from 'components/v2/button/Button';
import { useState } from 'react';
import { cn } from 'utils/helpers';

const InviteContacts = () => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyLink = async () => {
    const linkToCopy = 'https://caleido.social/';

    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(linkToCopy);
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = linkToCopy;
        textArea.style.position = 'absolute';
        textArea.style.left = '-9999px';
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
      }

      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };

  return (
    <div className="w-full flex items-center justify-between bg-[#110E2B] rounded-[1rem] py-5 px-4">
      <div className="w-full h-full flex justify-between items-center gap-2">
        <div className="flex items-center gap-2">
          <div className="text-[1.5rem]">🔗</div>

          <div className="flex flex-col gap-2">
            <p className="text-[0.813rem] font-medium font-manrope">
              Invite friends to Caleido
            </p>
            <p className="text-[0.813rem] font-medium font-manrope">
              Share your link to your friends
            </p>
          </div>
        </div>
        <div>
          <Button
            className={cn(
              'relative flex items-center justify-center text-[0.813rem] font-medium font-manrope px-4 py-3 transition-all duration-500',
              isCopied && 'bg-green-600 pointer-events-none',
            )}
            onClick={handleCopyLink}
          >
            <span
              className={cn(
                'absolute transition-all duration-500',
                !isCopied ? 'opacity-0' : 'opacity-100',
              )}
            >
              Copied!
            </span>

            <span
              className={cn(
                'transition-all duration-500',
                isCopied ? 'opacity-0' : 'opacity-100',
              )}
            >
              Copy link
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default InviteContacts;
