import { useEffect, useState, useRef, useMemo, useLayoutEffect } from 'react';
import { Drawer } from 'vaul';
import { CheckIcon, ChevronDown, ChevronLeft } from 'lucide-react';
import { ErrorIcon } from 'react-hot-toast';
import OneSignal from 'react-onesignal';
import { useNavigate } from 'react-router-dom';
import { DraggableContainer } from 'components/draggableContainer';
import useSessionStorage from 'hooks/useSessionStorage';
import useLocalStorage from 'hooks/useLocalStorage';
import RSVP from 'modules/letsMeet/components/receive/RSVP';
import OTPInput from 'components/otpInput/OTPInput';
import { toast } from 'components/ui/toast/use-toast';
import { mutualAddContacts, cn, deleteContactFromEvent } from 'utils/helpers';
import './../../../../App.css';
import {
  ViewState,
  ReceiveMeetResponseState,
  ReceiveMeetResponseType,
} from 'modules/letsMeet/types/types';
import {
  useSendOtpMutation,
  useVerifyOtpMutation,
} from 'modules/auth/services/verification.mutation.service';
import ResendTimer from 'modules/auth/components/ResendTimer';
import { Text } from 'components/text/primary/Text';
import { formatPhoneNumber } from 'modules/auth/utils/stringUtils';
import tokenService from 'modules/auth/utils/tokenUtils';
import caleidoBaseApi from 'services/apiClient';
import { useGuestSubscribeNotificationMutation } from 'services/slices/eventsApiSlice';
import { TokenResponse } from 'modules/auth/types/types';
interface StoredResponse {
  [eventId: string]: {
    response: ReceiveMeetResponseType;
    state: ReceiveMeetResponseState;
  };
}

interface PublicDrawerProps {
  state: ReceiveMeetResponseState;
  setState: (state: ReceiveMeetResponseState) => void;
  response?: ReceiveMeetResponseType;
  setResponse: (response: ReceiveMeetResponseType) => void;
  eventId: string;
  isPoll?: boolean;
  submitPollVote?: () => Promise<void>;
  selectedTimes?: string[];
  hasVoted?: boolean;
  hostUserId?: string;
  onSubmitResponse: (data: {
    name: string;
    phoneNumber: string;
    countryCode: string;
    response: ReceiveMeetResponseType;
  }) => Promise<boolean>;
}

const PublicDrawer: React.FC<PublicDrawerProps> = ({
  state,
  setState,
  response,
  setResponse,
  eventId,
  isPoll,
  submitPollVote,
  selectedTimes,
  hasVoted,
  hostUserId,
  onSubmitResponse,
}) => {
  const [isDrawerVisible, setIsDrawerVisible] = useState(true);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [name, setName] = useSessionStorage('cal-user-name', '');
  const [phoneNumber, setPhoneNumber] = useSessionStorage(
    'cal-user-phone-number',
    '',
  );
  const [countryCode, setCountryCode] = useSessionStorage(
    'cal-user-country-code',
    '+1',
  );
  const [validatedNumbers] = useLocalStorage(
    'cal-user-otp-validated-number',
    [],
  );
  const [storedResponses, setStoredResponses] = useLocalStorage(
    'user-responses',
    {},
  );

  const [otp, setOtp] = useState('');
  const [errors, setErrors] = useState({ otp: '' });
  const otpInputRef = useRef(null);
  const [sendOtp, { isLoading: isSendingOtp }] = useSendOtpMutation();
  const [verifyOtp, { isLoading: isVerifyingOtp }] = useVerifyOtpMutation();
  const [userAccountAvailable, setUserAccountAvailable] = useLocalStorage(
    'cal-user-account-available',
    false,
  );
  const [isHostResponding] = useSessionStorage('cal-host-responding', false);
  const [completedEventResponses, setCompletedEventResponses] = useLocalStorage(
    'cal-user-completed-event-responses',
    {},
  );
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [subscribeToEvent] = useGuestSubscribeNotificationMutation();
  const [eventContactIds, setEventContactIds] = useLocalStorage(
    'cal-user-event-contact-ids',
    {},
  );
  const navigate = useNavigate();
  const [onboardingUsername, setOnboardingUsername] = useLocalStorage(
    'cal-user-onboarding-username',
    '',
  );

  const [showSubmitButton, setShowSubmitButton] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const getInitialViewState = () => {
    if (isPoll) {
      if (!name) {
        return 'name-input';
      } else if (name && !phoneNumber) {
        return 'phone-input-poll';
      } else if (name && phoneNumber) {
        return 'verify-otp';
      }
      return 'poll-dismissed';
    }

    if (!name && !storedResponses[eventId]) {
      return 'unlock';
    } else if (!name && storedResponses[eventId]) {
      return 'name-input';
    } else if (name && !phoneNumber) {
      return 'phone-and-rsvp';
    } else if (name && phoneNumber) {
      return 'rsvp-selected';
    }
  };

  useLayoutEffect(() => {
    // Add the event listeners
    document.addEventListener('focusin', (e) => e.stopImmediatePropagation());
    document.addEventListener('focusout', (e) => e.stopImmediatePropagation());

    // Clean up function to remove listeners when component unmounts
    return () => {
      document.removeEventListener('focusin', (e) =>
        e.stopImmediatePropagation(),
      );
      document.removeEventListener('focusout', (e) =>
        e.stopImmediatePropagation(),
      );
    };
  }, []);

  const [currentView, setCurrentView] = useState<ViewState>(
    getInitialViewState() ?? 'unlock',
  );
  useEffect(() => {
    if (response && eventId) {
      setStoredResponses((prev: StoredResponse) => ({
        ...prev,
        [eventId]: {
          response,
          state,
        },
      }));
    }
  }, [response, state]);

  // Check for stored response on mount
  useEffect(() => {
    const storedResponse = storedResponses[eventId];
    if (storedResponse) {
      setResponse(storedResponse.response);
      setState(storedResponse.state);
    }
  }, []);

  useEffect(() => {
    if (
      currentView === 'unlock' ||
      currentView === 'name-input' ||
      currentView === 'verify-otp'
    ) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100vh';
      document.body.style.touchAction = 'none';
      document.body.style.position = 'fixed';
      document.body.style.width = '100%';
    } else {
      document.body.style.overflow = '';
      document.body.style.height = '';
      document.body.style.touchAction = '';
      document.body.style.position = '';
      document.body.style.width = '';
    }

    return () => {
      document.body.style.overflow = '';
      document.body.style.height = '';
      document.body.style.touchAction = '';
      document.body.style.position = '';
      document.body.style.width = '';
    };
  }, [currentView]);

  const handlePhoneNumberValidation = (success: boolean) => {
    if (success && phoneNumber.trim()) {
      const formattedNumber = `${countryCode}${phoneNumber}`;
      // If phone number format is valid, always go to verify-otp
      setCurrentView('verify-otp');
    } else {
      // No phone number, skip verification
      setCurrentView('rsvp-selected');
    }
  };

  useEffect(() => {
    if (currentView !== 'phone-input-poll') return;

    const handleScroll = () => {
      // Get poll selector element (you'll need to add an id to it)
      const pollSelector = document.getElementById('poll-selector');
      if (!pollSelector) return;

      // Get poll selector bottom position relative to viewport
      const pollSelectorRect = pollSelector.getBoundingClientRect();
      const pollSelectorBottom = pollSelectorRect.bottom;

      // Show button when poll selector is scrolled above viewport
      setShowSubmitButton(pollSelectorBottom < 0);
    };

    window.addEventListener('scroll', handleScroll);
    // Initial check
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [currentView]);

  useEffect(() => {
    const checkUser = async () => {
      if (currentView === 'verify-otp' && phoneNumber) {
        const formattedNumber = `${countryCode}${phoneNumber}`;

        // Check if user exists
        const isUserExists = await checkContactExists(formattedNumber);
        setUserAccountAvailable(isUserExists);

        // if (isUserExists && eventOrganizer) {
        //     const eventOrganizerContact = eventOrganizer?.phoneNumber ?? '';
        //     setIsHostResponding(formattedNumber === eventOrganizerContact);
        // }
      }
    };

    checkUser();
  }, [currentView, phoneNumber, countryCode]);

  const handleResponseSelect = async (
    selectedResponse: ReceiveMeetResponseType,
  ) => {
    setResponse(selectedResponse);
    if (currentView === 'rsvp-view') {
      setIsSubmitting(true);
      await onSubmitResponse({
        name,
        phoneNumber,
        countryCode,
        response: selectedResponse,
      });

      if (phoneNumber.trim()) {
        // if verify otp hasn't been done before
        setCurrentView('verify-otp');
        // setCurrentView('rsvp-selected');
      } else {
        // If no phone number, show phone input screen
        setCurrentView('phone-and-rsvp');
      }
      setIsSubmitting(false);
      return;
    }

    // Normal flow for initial unlock screen
    setState(selectedResponse as unknown as ReceiveMeetResponseState);
    setCurrentView('name-input');
  };

  const handleChevronClick = () => {
    setCurrentView('rsvp-view');
    setState(ReceiveMeetResponseState.NOT_RESPONDED);
    if (response) {
      setResponse(response);
    }
  };

  const drawerHeight = useMemo(() => {
    if (currentView === 'unlock') {
      return 400;
    } else if (currentView === 'name-input') {
      return 445;
    } else if (currentView === 'phone-and-rsvp') {
      return 250;
    } else if (currentView === 'verify-otp') {
      return 300;
    } else if (currentView === 'response-complete') {
      return 220;
    } else if (currentView === 'rsvp-view') {
      return 240;
    } else if (currentView === 'rsvp-selected') {
      return 180;
    } else if (currentView === 'phone-input-poll') {
      return 250;
    } else if (currentView === 'poll-dismissed') {
      return 100;
    } else {
      return 400;
    }
  }, [currentView]);

  const rsvpHeight = `${drawerHeight + 20}px`;

  const handleDetailsSubmit = async () => {
    // Name input screen handling
    if (currentView === 'name-input') {
      if (!name.trim()) {
        toast({
          title: 'Please enter your name',
        });
        return;
      }
      if (isPoll && (!phoneNumber || !phoneNumber.trim())) {
        setCurrentView('phone-input-poll');
        return;
      } else if (isPoll && name && phoneNumber) {
        // update the contact to have phone number in db
        setCurrentView('verify-otp');
        return;
      }
      setIsSubmitting(true);
      try {
        // Submit response to API when we have name
        const success = await onSubmitResponse({
          name,
          phoneNumber,
          countryCode,
          response: response!,
        });

        if (success) {
          // Update parent state after successful submission
          setState(response as unknown as ReceiveMeetResponseState);

          // If phone number is provided, check validation
          if (phoneNumber.trim()) {
            const formattedNumber = `${countryCode}${phoneNumber}`;
            const isNumberValidated = validatedNumbers.some(
              (n: any) => n.phoneNumber === formattedNumber,
            );

            setCurrentView('verify-otp');
          } else {
            // If no phone number, go to phone input screen
            if (isPoll) {
              setCurrentView('phone-input-poll');
            } else {
              setCurrentView('phone-and-rsvp');
            }
          }
        }
      } catch (error) {
        toast({
          title: 'Failed to submit response',
          variant: 'destructive',
        });
      } finally {
        setIsSubmitting(false);
      }
      return;
    }

    if (currentView === 'phone-input-poll') {
      setIsSubmitting(true);
      try {
        if (phoneNumber.trim()) {
          setCurrentView('verify-otp');
        } else {
          // No phone number provided, go to dismissed state
          setCurrentView('poll-dismissed');
          setIsDrawerVisible(false);
        }
      } catch (error) {
        toast({
          title: 'Failed to submit',
          variant: 'destructive',
        });
      } finally {
        setIsSubmitting(false);
      }
      return;
    }

    // Phone and RSVP screen handling
    if (currentView === 'phone-and-rsvp') {
      setIsSubmitting(true);
      try {
        // Submit updated response with phone number
        const success = await onSubmitResponse({
          name,
          phoneNumber,
          countryCode,
          response: response!,
        });
        if (success) {
          // Only proceed with OTP if phone number provided
          if (phoneNumber.trim()) {
            const formattedNumber = `${countryCode}${phoneNumber}`;
            const isNumberValidated = validatedNumbers.some(
              (n: any) => n.phoneNumber === formattedNumber,
            );

            setCurrentView('verify-otp');
          } else {
            setCurrentView('rsvp-selected');
          }
        } else {
          setCurrentView('phone-and-rsvp');
        }
      } catch (error) {
        toast({
          title: 'Failed to submit response',
          variant: 'destructive',
        });

        setCurrentView('phone-and-rsvp');
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleResendOtp = async () => {
    const formattedNumber = `${countryCode}${phoneNumber}`;
    try {
      await sendOtp({ phoneNumber: formattedNumber }).unwrap();
      toast({
        title: 'OTP sent successfully!',
        backgroundColor: '#E0F4DB',
        textColor: '#060606',
        icon: <CheckIcon />,
      });
    } catch (error) {
      toast({
        title: 'Failed to send OTP!',
        backgroundColor: '#FDE2E2',
        textColor: '#060606',
        icon: <ErrorIcon />,
      });
    }
  };

  const checkContactExists = async (number: string) => {
    try {
      return await caleidoBaseApi
        .get(`/users/registered-number/${number}`)
        .then((response) => {
          return response?.data?.data?.isUser ? true : false;
        });
    } catch (e: any) {
      return false;
    }
  };

  const handleVerifyOtp = async () => {
    if (!otp || otp.length < 6) {
      setErrors({ otp: 'Please enter a valid OTP' });
      return;
    }

    const formattedNumber = `${countryCode}${phoneNumber}`;
    try {
      const response = await verifyOtp({
        to: formattedNumber,
        otp,
        name,
      }).unwrap();

      if (response) {
        // Save validated number
        const prevValidatedOtps = validatedNumbers;
        prevValidatedOtps.push({
          phoneNumber: formattedNumber,
          time: new Date(),
        });
        localStorage.setItem(
          'cal-user-otp-validated-number',
          JSON.stringify(prevValidatedOtps),
        );

        // Get user data from OTP response
        const user = response.user;

        if (response.tokens) {
          const idWithoutPhone = localStorage.getItem('cal-id-without-phone');
          // Check if user account is available
          if (userAccountAvailable && isHostResponding) {
            // Event host is responding - log them in and redirect to host view
            if (idWithoutPhone) {
              await deleteContactFromEvent(eventId, idWithoutPhone);
              localStorage.removeItem('cal-id-without-phone');
            }

            tokenService.setTokens(response.tokens as any);

            window.location.href = `/lets-meet/view/${eventId}`;
          } else if (userAccountAvailable) {
            // Existing user - log them in and redirect to normal view
            // need to delete duplicated contact from cookie
            if (idWithoutPhone) {
              await deleteContactFromEvent(eventId, idWithoutPhone);
              localStorage.removeItem('cal-id-without-phone');
            }

            tokenService.setTokens(response.tokens as any);
            await mutualAddContacts(hostUserId ?? '', user.id ?? '');
            window.location.href = `/lets-meet/receive/${eventId}`;
            return;
          } else {
            // New user - log them in and redirect to pageleaving cases separated for now
            // TODO: figure out what other things we need to do here...
            tokenService.setTokens(response.tokens as any);
            await mutualAddContacts(hostUserId ?? '', user.id ?? '');
            window.location.href = `/lets-meet/receive/${eventId}`;
            return;
          }
        }

        // Subscribe to event if user exists
        if (userAccountAvailable) {
          const prevEventContactId = eventContactIds[eventId] ?? undefined;
          try {
            await subscribeToEvent({
              eventId,
              contactId: prevEventContactId,
              phoneNumber: formattedNumber,
            }).unwrap();
            setIsSubscribed(true);
          } catch (error) {
            console.error('Failed to subscribe to event:', error);
          }
        }

        // Set up OneSignal notifications if user exists
        if (user && user.id) {
          try {
            await OneSignal.logout();
            await OneSignal.Notifications.requestPermission();
            OneSignal.login(user.id);
            OneSignal.User.addSms(user.phoneNumber);
          } catch (err) {
            console.error('OneSignal setup failed:', err);
          }
        }

        // Track completion status
        setCompletedEventResponses((prev: any) => ({
          ...prev,
          [eventId]: true,
        }));

        toast({
          title: 'Phone number verified successfully!',
          backgroundColor: '#E0F4DB',
          textColor: '#060606',
          icon: <CheckIcon />,
        });
      }
    } catch (error) {
      console.log(error);
      toast({
        title: 'Invalid OTP. Please try again.',
        backgroundColor: '#FDE2E2',
        textColor: '#060606',
        icon: <ErrorIcon />,
      });
    }
  };

  useEffect(() => {
    const sendInitialOtp = async () => {
      if (currentView === 'verify-otp' && phoneNumber) {
        const formattedNumber = `${countryCode}${phoneNumber}`;
        try {
          await sendOtp({ phoneNumber: formattedNumber }).unwrap();
          toast({
            title: 'OTP sent successfully!',
            backgroundColor: '#E0F4DB',
            textColor: '#060606',
            icon: <CheckIcon />,
          });
        } catch (error) {
          toast({
            title: 'Failed to send OTP!',
            backgroundColor: '#FDE2E2',
            textColor: '#060606',
            icon: <ErrorIcon />,
          });
        }
      }
    };

    sendInitialOtp();
  }, [currentView]);

  const renderVerifyOtpScreen = () => {
    return (
      <div className="p-6 space-y-4 flex flex-col items-center justify-center relative">
        <button
          onClick={() => setCurrentView('phone-and-rsvp')}
          className="p-2 text-white hover:bg-white/10 rounded-full transition-colors absolute top-0 left-0"
        >
          <ChevronLeft className="w-5 h-5" />
        </button>

        <h3 className="text-white text-lg font-semibold text-center">
          Enter verification code
        </h3>
        <p className="text-gray-400 text-sm text-center">
          We sent a code to {formatPhoneNumber(phoneNumber)}
        </p>
        <div className="flex justify-center">
          <OTPInput
            ref={otpInputRef}
            length={6}
            onChange={(otp: string) => {
              setOtp(otp);
              setErrors({ otp: '' });
            }}
          />
        </div>
        {errors.otp && (
          <Text size="extra-small" type="error">
            {errors.otp}
          </Text>
        )}
        <ResendTimer
          initialTimer={30}
          onResend={handleResendOtp}
          loading={isSendingOtp}
        />
        <button
          onClick={handleVerifyOtp}
          disabled={isVerifyingOtp}
          className="w-full p-3 rounded-lg bg-[#6000E9] text-white font-medium"
        >
          {isVerifyingOtp ? 'Verifying...' : 'Verify'}
        </button>
      </div>
    );
  };
  if (!isDrawerVisible) {
    return (
      <DraggableContainer
        onDragStart={() => setIsDrawerVisible(true)}
        longTouchThreshold={10}
      >
        <div className="fixed bottom-0 left-0 right-0 flex flex-col bg-[#1F2339] px-7 py-5 w-full">
          {response && !isPoll && (
            <div className="flex justify-center items-center">
              <div className="w-[60px] h-[60px] bg-[#6000E9] rounded-full flex items-center justify-center">
                {response === ReceiveMeetResponseType.ACCEPTED && (
                  <span className="text-2xl">👍</span>
                )}
                {response === ReceiveMeetResponseType.DECLINED && (
                  <span className="text-2xl">😢</span>
                )}
                {response === ReceiveMeetResponseType.MAYBE && (
                  <span className="text-xl">🤔</span>
                )}
              </div>
            </div>
          )}
        </div>
      </DraggableContainer>
    );
  }

  if (isSubmitting) {
    return (
      <div className="fixed inset-0 bg-black/60 z-50 flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
      </div>
    );
  }

  return (
    <>
      {response && currentView === 'phone-and-rsvp' && (
        <div
          className="fixed left-1/2 -translate-x-1/2 z-50"
          style={{
            bottom: `${drawerHeight + 20}px`,
          }}
        >
          <div className="relative">
            <div className="w-[80px] h-[80px] bg-[#6000E9] rounded-full flex items-center justify-center">
              {response === ReceiveMeetResponseType.ACCEPTED && (
                <span className="text-3xl">👍</span>
              )}
              {response === ReceiveMeetResponseType.DECLINED && (
                <span className="text-3xl">😢</span>
              )}
              {response === ReceiveMeetResponseType.MAYBE && (
                <span className="text-2xl">🤔</span>
              )}
            </div>
            <button
              onClick={handleChevronClick}
              className="absolute -bottom-1 -right-1 w-[38px] h-[38px] bg-[#242632] rounded-full flex items-center justify-center shadow-lg"
            >
              <ChevronDown className="w-5 h-5 text-white" />
            </button>
          </div>
        </div>
      )}

      <Drawer.Root
        modal={false}
        onClose={() => setIsDrawerVisible(false)}
        // shouldScaleBackground={false}
        open={isDrawerVisible}
        repositionInputs={window.innerWidth < 368}
        // dismissible= {currentView !== 'unlock' && currentView !== 'name-input' && currentView !== 'phone-and-rsvp'}
        dismissible={false}
      >
        <Drawer.Portal>
          {(currentView === 'unlock' || currentView === 'verify-otp') && (
            <Drawer.Overlay className="fixed inset-0 bg-black/60" />
          )}
          <Drawer.Content
            id="drawer-content"
            style={{
              height: `${drawerHeight}px`, // Increased unlock height
              transition: 'height 0.2s ease-in-out',
            }}
            onClick={(e) => e.stopPropagation()}
            className={cn(
              'fixed inset-x-0 bottom-0 p-0 m-0',
              'bg-gradient-to-t from-[#0B091C] via-[rgba(10,8,26,0.95)] to-[rgba(10,8,26,0.90)]',
              'backdrop-blur-[11.5px]',
              'rounded-t-[12px]',
              'transform-gpu translate-y-0',
              'z-10',
              'pointer-events-auto',
            )}
          >
            {currentView === 'unlock' && (
              <div className="p-6 text-center">
                <h2 className="text-[#ABE49C] font-manrope text-2xl font-bold leading-[150%] mx-auto max-w-[265px] text-center mb-6">
                  Respond to unlock the rest of details
                </h2>
                <div className="relative -mt-4">
                  {' '}
                  {/* Added relative positioning */}
                  <RSVP
                    onRespond={handleResponseSelect}
                    // Force NOT_RESPONDED state when in unlock view to show all options
                    state={ReceiveMeetResponseState.NOT_RESPONDED}
                    setState={setState}
                  />
                </div>
              </div>
            )}

            {currentView === 'rsvp-selected' && (
              <div className="p-6 flex justify-center items-center">
                <div className="relative">
                  <div className="w-[80px] h-[80px] bg-[#6000E9] rounded-full flex items-center justify-center">
                    {response === ReceiveMeetResponseType.ACCEPTED && (
                      <span className="text-3xl">👍</span>
                    )}
                    {response === ReceiveMeetResponseType.DECLINED && (
                      <span className="text-3xl">😢</span>
                    )}
                    {response === ReceiveMeetResponseType.MAYBE && (
                      <span className="text-2xl">🤔</span>
                    )}
                  </div>
                  <button
                    onClick={handleChevronClick}
                    className="absolute -bottom-1 -right-1 w-[38px] h-[38px] bg-[#242632] rounded-full flex items-center justify-center shadow-lg"
                  >
                    <ChevronDown className="w-5 h-5 text-white" />
                  </button>
                </div>
              </div>
            )}

            {currentView === 'name-input' && (
              <div className="p-6 space-y-4">
                <h2
                  className={`text-[#ABE49C] font-manrope text-2xl font-bold leading-[150%] mx-auto text-center mb-6 ${
                    isPoll ? 'max-w-[365px]' : 'max-w-[265px]'
                  }`}
                >
                  {isPoll
                    ? 'First, answer below so the host knows who you are!'
                    : 'Almost there! One final thing!'}
                </h2>

                <div className="space-y-2">
                  <label className="text-white font-manrope text-sm font-semibold">
                    {"What's your name"} <span className="text-white">*</span>
                  </label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="w-full h-[48px] px-4 rounded-[4px] bg-[#32395E] text-white font-manrope"
                    placeholder="Enter your name"
                  />
                </div>

                <div className="space-y-2">
                  <label className="text-white font-manrope text-sm font-semibold">
                    What is your phone number(for reminders)?
                  </label>
                  <input
                    type="tel"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="w-full h-[48px] px-4 rounded-[4px] bg-[#32395E] text-white font-manrope"
                    placeholder="Optional"
                  />
                </div>

                <p className="text-[rgba(255,255,255,0.8)] font-manrope text-[13px] font-medium leading-[150%] tracking-[-0.429px]">
                  Entering your phone number, you agree to the Terms and
                  Conditions
                </p>

                <button
                  onClick={handleDetailsSubmit}
                  disabled={isSubmitting}
                  className="w-full h-[48px] flex justify-center items-center rounded-[100px] bg-[#6000E9] text-white font-manrope font-medium shadow-[0px_4px_10px_0px_rgba(0,0,0,0.06)]"
                >
                  Done
                </button>
              </div>
            )}

            {currentView === 'phone-and-rsvp' && (
              <div className="p-6 space-y-4">
                <div className="space-y-2">
                  <label className="text-white font-manrope text-sm font-semibold">
                    What is your phone number(for reminders)?
                  </label>
                  <input
                    type="tel"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="w-full h-[48px] px-4 rounded-[4px] bg-[#32395E] text-white font-manrope"
                    placeholder="Enter your phone number"
                  />
                </div>

                <p className="text-[rgba(255,255,255,0.8)] font-manrope text-[13px] font-medium leading-[150%] tracking-[-0.429px]">
                  Entering your phone number, you agree to the Terms and
                  Conditions
                </p>

                <button
                  onClick={handleDetailsSubmit}
                  disabled={isSubmitting}
                  className="w-full h-[48px] flex justify-center items-center rounded-[100px] bg-[#6000E9] text-white font-manrope font-medium shadow-[0px_4px_10px_0px_rgba(0,0,0,0.06)]"
                >
                  Done
                </button>
              </div>
            )}

            {currentView === 'rsvp-view' && (
              <div className="p-6 text-center">
                <div className="relative">
                  <RSVP
                    onRespond={handleResponseSelect}
                    state={ReceiveMeetResponseState.NOT_RESPONDED}
                    setState={setState}
                  />
                </div>
              </div>
            )}

            {currentView === 'phone-input-poll' && (
              <div className="p-6 space-y-4">
                <div className="space-y-2">
                  <label className="text-white font-manrope text-sm font-semibold">
                    What is your phone number(for reminders)?
                  </label>
                  <input
                    type="tel"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    className="w-full h-[48px] px-4 rounded-[4px] bg-[#32395E] text-white font-manrope"
                    placeholder="Enter your phone number"
                  />
                </div>

                <p className="text-[rgba(255,255,255,0.8)] font-manrope text-[13px] font-medium leading-[150%] tracking-[-0.429px]">
                  Entering your phone number, you agree to the Terms and
                  Conditions
                </p>

                <button
                  onClick={handleDetailsSubmit}
                  disabled={
                    isSubmitting || (phoneNumber as unknown as string) === ''
                  }
                  className={`w-full h-[48px] ${(isSubmitting || (phoneNumber as unknown as string) === '') && 'opacity-50 cursor-not-allowed'} flex justify-center items-center rounded-[100px] bg-[#6000E9] text-white font-manrope font-medium shadow-[0px_4px_10px_0px_rgba(0,0,0,0.06)]`}
                >
                  Done
                </button>

                {/* <button
                onClick={submitPollVote} 
                disabled={
                  (selectedTimes?.length === 0 && 
                  !selectedTimes?.includes('Not available on all dates')) || 
                  isSubmitting
                }
                className="w-full h-[52px] flex items-center justify-center rounded-full bg-[#6000E9] text-white font-manrope font-medium disabled:opacity-50 disabled:cursor-not-allowed mt-2"
              >
                <div className="flex items-center gap-2">
                  {isSubmitting && (
                    <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                  )}
                  <span className="text-white font-medium">
                    {hasVoted ? 'Update response' : 'Submit response'}
                  </span>
                </div>
              </button> */}
              </div>
            )}
            {currentView === 'verify-otp' && renderVerifyOtpScreen()}

            {currentView === 'response-complete' && (
              <div className="p-6 text-center space-y-4">
                <div className="text-3xl">
                  {response === ReceiveMeetResponseType.ACCEPTED && '👍'}
                  {response === ReceiveMeetResponseType.DECLINED && '😢'}
                  {response === ReceiveMeetResponseType.MAYBE && '🤔'}
                </div>
                <h3 className="text-white text-xl font-semibold">
                  Thanks for responding!
                </h3>
                <button onClick={handleChevronClick} className="text-[#6000E9]">
                  Change response
                </button>
              </div>
            )}
          </Drawer.Content>
        </Drawer.Portal>
      </Drawer.Root>

      {/* {currentView === 'phone-input-poll' && (
  <div 
    className={`fixed bottom-0 left-0 w-full z-[60] transition-transform duration-200 ease-in-out ${
      showSubmitButton ? 'translate-y-0' : 'translate-y-full'
    }`}
  >
    <div className="bg-[#1F2339] border-t border-[#32395E]">
      <div className="max-w-md mx-auto px-4 py-4">
        <button
          onClick={submitPollVote} 
          disabled={
            (selectedTimes?.length === 0 && 
            !selectedTimes?.includes('Not available on all dates')) || 
            isSubmitting
          }
          className="w-full h-[52px] flex items-center justify-center rounded-full bg-[#6000E9] disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <div className="flex items-center gap-2">
            {isSubmitting && (
              <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
            )}
            <span className="text-white font-medium">
              {hasVoted ? 'Update response' : 'Submit response'}
            </span>
          </div>
        </button>
      </div>
    </div>
  </div>
)} */}
    </>
  );
};

export default PublicDrawer;
