import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { AuthProvider } from 'modules/auth/context/AuthContext';
import { HOME_ROUTES } from 'components/routes';

const HomeRoutes: React.FC = () => {
  return (
    <AuthProvider>
      <Routes>
        {HOME_ROUTES.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
      </Routes>
    </AuthProvider>
  );
};

export default HomeRoutes;
