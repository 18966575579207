import React, { useState } from 'react';
import { ChevronLeft } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import SideBarButton from 'components/sidebar/sidebarButton';

type ContactsLayoutProps = {
  children: React.ReactElement;
  title: string;
  bottomChildren?: React.ReactNode;
};

const ContactsLayoutV2 = ({
  children,
  title,
  bottomChildren,
}: ContactsLayoutProps) => {
  const navigate = useNavigate();
  return (
    <div className="w-full flex flex-col relative bg-transparent">
      <div className="w-full fixed left-0 top-0 py-5 px-5 flex items-center justify-between bg-[#0a0118] z-10">
        <div className="flex items-center">
          <ChevronLeft
            className="cursor-pointer"
            onClick={() => {
              navigate(-1);
            }}
          />
        </div>
        <div
          className="flex justify-center items-center text-[1.5rem] Westmount"
          onClick={() => {
            window.scrollTo({ top: 0, behavior: 'smooth' });
          }}
        >
          {title}
        </div>
        <div className="flex items-center ">
          <SideBarButton />
        </div>
      </div>
      <div className="mr-0 mt-16">{children}</div>
      <div className="fixed w-full left-0 bottom-0 px-5 pb-6">
        {bottomChildren}
      </div>
    </div>
  );
};

export default ContactsLayoutV2;
