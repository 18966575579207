import { IEvent } from 'types';

/**
 * Determines if an event is past based on its end date or calculated end time
 * If end date is null, it calculates end time as start date + 2 hours
 * @param event The event object or null
 * @returns boolean indicating if the event is in the past
 */
export const isPastEvent = (event: IEvent | null): boolean => {
  if (!event) return false;

  const currentDate = new Date();

  // If end date exists, use it
  if (event.end) {
    // Handle the Date object or ensure it's properly converted
    const endDate = event.end instanceof Date ? event.end : new Date(event.end);
    return endDate < currentDate && event.type !== 'poll';
  }

  // If no end date but we have a start date, calculate end as start + 2 hours
  if (event.start) {
    // Handle the Date object or ensure it's properly converted
    const startDate =
      event.start instanceof Date ? event.start : new Date(event.start);
    const calculatedEndDate = new Date(startDate);
    calculatedEndDate.setHours(calculatedEndDate.getHours() + 2);
    return calculatedEndDate < currentDate && event.type !== 'poll';
  }

  // If neither start nor end date, we can't determine if it's past
  return false;
};

/**
 * Gets the end date for an event, using start date + 2 hours if end date is null
 * @param event The event object or null
 * @returns Date object representing the end date
 */
export const getEventEndDate = (event: IEvent | null): Date => {
  if (!event) return new Date();

  // If end date exists, use it
  if (event.end) {
    // Handle the Date object or ensure it's properly converted
    return event.end instanceof Date ? event.end : new Date(event.end);
  }

  // If no end date but we have a start date, calculate end as start + 2 hours
  if (event.start) {
    // Handle the Date object or ensure it's properly converted
    const startDate =
      event.start instanceof Date ? event.start : new Date(event.start);
    const calculatedEndDate = new Date(startDate);
    calculatedEndDate.setHours(calculatedEndDate.getHours() + 2);
    return calculatedEndDate;
  }

  // Default to current date if neither exists
  return new Date();
};
