import { useEffect, useState, useMemo } from 'react';
import { isArray, isEmpty, isString, debounce } from 'lodash';
import { Plus, Search } from 'lucide-react';
import { IUser } from 'modules/auth/types/types';
import { Text } from 'components/text/primary/Text';
import { Contact } from 'modules/profile/types/types';

import ContactActions from 'modules/settings/enums/enum';
import Drawer from 'modules/settings/components/contactActions/Drawer/Drawer';
import AddContact from 'modules/settings/components/contactActions/AddContact';
import ImportContacts from 'modules/settings/components/contactActions/ImportContacts';
import TextField from 'components/textfield/TextField';
import ProfileAvatar from 'components/avatar/ProfileAvatar';
import ContactsLayout from 'modules/settings/layouts/ContactsLayout';
import { ContactActionProps } from 'modules/settings/types/types';
import { getFromNowTime } from 'utils/dateTimeUtils';
import { ReactComponent as Logo } from 'assets/images/profile/caleido-logo.svg';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setUser } from 'modules/auth/slices/authSlice';
import { deleteContactFromUser } from 'utils/helpers';
import ContactsLayoutV2 from 'modules/settings/layouts/ContactsLayoutV2';
import DropdownFilter from 'components/v2/DropdownFilter/DropdownFilter';
import ContactItem from '../pages/Contacts/ContactItem';
import InviteContacts from '../pages/Contacts/InviteContacts';
import { useGetAvailableContactsQuery } from 'services/event.service';
import dayjs from 'dayjs';
import Button from 'components/v2/button/Button';

const SORT_OPTIONS = [
  { label: 'All Friends', value: 'all' },
  { label: 'Free Now', value: 'free-now' },
];

type ContactsProps = {
  setPrevOrSkip: () => void;
  update?: (contacts: Contact[]) => Promise<IUser | null>;
  data?: IUser;
  title?: string;
  loading?: boolean;
  error?: boolean;
  deleteContact?: (phoneNumber: string) => Promise<void>;
};

const ContactActionComponents: {
  [key in ContactActions]: React.FC<ContactActionProps>;
} = {
  [ContactActions.IMPORT_CONTACTS]: ImportContacts,
  [ContactActions.ADD_CONTACTS]: AddContact,
  [ContactActions.EDIT_CONTACTS]: AddContact,
  [ContactActions.VIEW_CONTACTS]: AddContact,
};

const Contacts = ({
  update,
  data,
  title,
  loading,
  error,
  deleteContact,
}: ContactsProps) => {
  const { data: availableContacts } = useGetAvailableContactsQuery();
  const [sortOption, setSortOption] = useState<{
    label: string;
    value: string;
  }>(SORT_OPTIONS[0]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentContact, setCurrentContact] = useState<Contact | undefined>(
    undefined,
  );
  const [importedContacts, setImportedContacts] = useState<Contact[]>([]);
  const [filteredContacts, setFilteredContacts] = useState<Contact[]>([]);
  const [visible, setVisible] = useState(false);
  const [contactAction, setContactAction] = useState<ContactActions>(
    ContactActions.IMPORT_CONTACTS,
  );
  const dispatch = useAppDispatch();

  const ActionComponent = ContactActionComponents[contactAction];

  const sanitizePhoneNumber = (phoneNumber: string): string => {
    return phoneNumber.replace(/\D/g, '');
  };

  const onSubmitForm = async (contacts: Contact[]) => {
    try {
      if (update) {
        // Use the new update function that takes contacts directly
        const response = await update(contacts);
        if (response) {
          handleClose();
        }
      } else {
        // If no update function provided, show error or handle gracefully
        console.error('No update function provided');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleOpenDrawer = (action: ContactActions, value?: Contact) => {
    setVisible(false);
    if (value) {
      const viewContact = {
        ...value,
        phoneNumber: sanitizePhoneNumber(value.phoneNumber),
      };
      setCurrentContact(viewContact);
    }
    setVisible(true);
    setContactAction(action);
  };

  const handleClose = () => {
    setVisible(false);
    setCurrentContact(undefined);
  };

  const actionProps: ContactActionProps = {
    initialContacts: importedContacts,
    handleContactsChange: onSubmitForm,
    setContactAction: setContactAction,
    loading: loading!,
    error: error!,
    contact: currentContact,
    isEdit: contactAction === ContactActions.EDIT_CONTACTS,
    isView: contactAction === ContactActions.VIEW_CONTACTS,
    isAdd: contactAction === ContactActions.ADD_CONTACTS,
    deleteContact: async (phoneNumber: string) => {
      if (deleteContact) {
        // Use the passed in deleteContact function for real-time updates
        await deleteContact(phoneNumber);
        handleClose();
      } else {
        // Fall back to the old implementation if not provided
        await deleteContactFromUser('+' + phoneNumber);
      }
    },
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchTerm(query);
    debouncedSearch(query);
  };

  const debouncedSearch = useMemo(
    () =>
      debounce((query: string) => {
        const lowerCaseQuery = query.toLowerCase();
        const filtered = importedContacts?.filter((contact) =>
          contact.name.toLowerCase()?.includes(lowerCaseQuery),
        );
        setFilteredContacts(filtered);
      }, 300),
    [importedContacts],
  );

  useEffect(() => {
    const existingContacts = data?.contacts as any;

    if (isString(existingContacts)) {
      setImportedContacts(JSON.parse(existingContacts) ?? []);
    } else if (isArray(existingContacts)) setImportedContacts(existingContacts);
  }, [data?.contacts]);

  useEffect(() => {
    if (!visible) setCurrentContact(undefined);
  }, [visible]);

  useEffect(() => {
    setFilteredContacts(importedContacts);
  }, [importedContacts]);

  const sortedContacts = useMemo(() => {
    if (sortOption.value === 'free-now') {
      return [
        ...(availableContacts?.data
          ?.filter((contact) => dayjs(contact.endDate).isAfter(dayjs()))
          .sort((a, b) => a.name.localeCompare(b.name)) ?? []),
      ];
    }
    return [...filteredContacts].sort((a, b) => a.name.localeCompare(b.name));
  }, [availableContacts, sortOption, filteredContacts]);

  return (
    <div className="flex flex-col items-center w-full gap-2 mb-12 px-5">
      <div className="flex flex-col w-full ">
        <div className="flex flex-col w-full">
          <ContactsLayoutV2
            title="Friends"
            bottomChildren={
              <Button
                onClick={() => handleOpenDrawer(ContactActions.ADD_CONTACTS)}
                className="p-5 text-center"
              >
                Add Contacts
              </Button>
            }
          >
            <div className="w-full flex flex-col gap-4">
              <div className="flex flex-col gap-4">
                <TextField
                  placeholder="Search contacts"
                  value={searchTerm}
                  onChange={handleSearchChange}
                  icon={<Search className="w-[1.125rem] h-[1.125rem]" />}
                />
                <InviteContacts />
              </div>
              <div className="flex flex-col gap-4">
                <div className="flex items-center justify-between">
                  <p className="text-[1rem] font-bold">
                    {sortedContacts?.length === 0 || !sortedContacts
                      ? 'No contacts found'
                      : `${sortedContacts && sortedContacts.length > 0 && sortedContacts?.length} ${sortedContacts?.length === 1 ? 'Friend' : 'Friends'}`}
                  </p>
                  <DropdownFilter
                    options={SORT_OPTIONS}
                    selectedValue={sortOption}
                    setSelectedValue={setSortOption}
                  />
                </div>
                <div className="flex flex-col gap-4">
                  {sortedContacts.map((contact: any, index) => (
                    <ContactItem
                      key={index}
                      isAvailable={dayjs(contact.endDate).isAfter(dayjs())}
                      phoneNumber={contact.phoneNumber}
                      onClick={() =>
                        handleOpenDrawer(ContactActions.VIEW_CONTACTS, contact)
                      }
                      name={contact?.name}
                      isUser={!!contact.userId}
                      photoURL={
                        contact.userId
                          ? `${process.env.REACT_APP_BASE_URL}/users/profile-pic/${contact.userId}`
                          : ''
                      }
                    />
                  ))}
                </div>
              </div>
            </div>
          </ContactsLayoutV2>
        </div>

        <Drawer
          setVisible={setVisible}
          visible={visible}
          className="w-full mx-auto border border-none outline-none max-w-none"
        >
          <ActionComponent {...actionProps} />
        </Drawer>
      </div>
    </div>
  );
};

export default Contacts;
