import React, { useEffect, useState } from 'react';
import OneSignal from 'react-onesignal';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAppSelector } from 'hooks/useAppSelector';
import { RootState } from 'app/store';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { Text } from 'components/text/primary/Text';
import { ReactComponent as CalendarIcon } from 'assets/images/common/calendar.svg';
import { ReactComponent as NotificationIcon } from 'assets/images/common/notification.svg';
import { ReactComponent as PrivacyIcon } from 'assets/images/common/privacy.svg';
import { ReactComponent as AboutIcon } from 'assets/images/common/about.svg';
import { ReactComponent as HelpIcon } from 'assets/images/common/help.svg';
import { ReactComponent as LogoutIcon } from 'assets/images/common/logout.svg';
import LinkButton from 'components/buttons/main/LinkButton';
import {
  whoAmIAction,
  logoutAction,
  deleteUserAction,
} from 'modules/auth/slices/actions';
import ProfileAvatar from 'components/avatar/ProfileAvatar';
import SettingsLayout from 'modules/settings/layouts/SettingsLayout/SettingsLayout';
import Drawer from 'modules/settings/components/contactActions/Drawer/Drawer';
import ActionButton from 'components/buttons/action/ActionButton';
import {
  useDeleteFileMutation,
  useUploadFileMutation,
} from 'services/upload.service';
import { useUpdateUserMutation } from 'modules/auth/services/user.mutation.service';
import tokenService from 'modules/auth/utils/tokenUtils';
import OverlayLoader from 'components/loader/OverlayLoader';
import { useAuth } from 'modules/auth/context/AuthContextV2';

interface SettingsLinkButtonProps {
  to: string;
  icon: React.ReactNode;
  text: string;
}

const SettingsLinkButton: React.FC<SettingsLinkButtonProps> = ({
  to,
  icon,
  text,
}) => {
  return (
    <LinkButton
      to={to}
      className="w-full px-4 py-4 bg-white border-0 rounded-lg bg-opacity-10"
    >
      <div className="flex items-center">
        {icon}
        <p className="inline-block ml-3">{text}</p>
      </div>
    </LinkButton>
  );
};

const Settings: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [visible, setVisible] = useState(false);
  const { user } = useAuth();
  const [isLoading, setIsLoading] = useState(false);

  const [
    uploadFile,
    { isLoading: isUploading, error, data: uploadFileData, isSuccess },
  ] = useUploadFileMutation();
  const [
    deleteFile,
    { isLoading: isDeleting, error: deleteError, isSuccess: deleteIsSuccess },
  ] = useDeleteFileMutation();

  const [updateUser, { isLoading: updateUserIsLoading, data }] =
    useUpdateUserMutation();

  const handleConfirmationModal = (value: boolean) => {
    setVisible(value);
  };

  const handleDeleteUser = async () => {
    if (user?.id) {
      await dispatch(deleteUserAction(user?.id));
      OneSignal.logout();
      navigate(`/auth/start`);
      tokenService.clearTokens();
    }
    handleConfirmationModal(false);
  };

  useEffect(() => {
    dispatch(whoAmIAction());
  }, [dispatch, location]);

  const handleUpload = async (file: File) => {
    if (file) {
      setIsLoading(true);
      try {
        const response = await uploadFile({ file, optimize: true }).unwrap();
        if (response) {
          await updateUser({
            data: { photoURL: response.signedUrl } as any,
          }).unwrap();
          window.location.reload();
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  };

  const handleDelete = async () => {
    try {
      if (data?.photoURL) {
        setIsLoading(true);
        await deleteFile({ key: data.photoURL }).unwrap();

        await updateUser({
          data: {
            // we use command delete for remove image from user
            photoURL: 'DELETE',
          } as any,
        }).unwrap();
        window.location.reload();
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  return (
    <SettingsLayout>
      {(!user || isLoading) && <OverlayLoader />}
      <div className="flex flex-col w-full h-full my-8 mb-6">
        <div className="flex w-full gap-4 align-top flex-col xs:flex-row text-center xs:text-left">
          <ProfileAvatar
            size={65}
            url={user?.photoURL}
            uploadable={!user?.photoURL}
            onUpload={(file) => handleUpload(file)}
            onDelete={handleDelete}
            loading={isUploading || isDeleting}
            disableDelete
          />

          <div className="flex flex-col w-full">
            <Text size="medium" className="mb-2 truncate text-pretty">
              {user?.name}
            </Text>
            <div className="flex w-full gap-2 align-baseline">
              <LinkButton
                to="/settings/edit/contacts"
                className="w-full px-2 py-1 text-sm font-semibold text-center bg-purple-300 rounded-sm text-violet-800 text-ellipsis whitespace-nowrap content-center"
              >
                My contacts
              </LinkButton>
              <LinkButton
                to="/settings/edit/profile"
                className="w-full px-2 py-1 text-sm font-semibold text-center text-white border-2 border-purple-300 rounded-sm text-ellipsis whitespace-nowrap"
              >
                Edit my profile
              </LinkButton>
            </div>
          </div>
        </div>
        <div className="flex-grow my-4">
          <div className="flex flex-col items-center w-full gap-2 mt-12 mb-8">
            <div className="grid w-full grid-cols-1 gap-y-3">
              <SettingsLinkButton
                to="/settings/edit/availability"
                icon={<CalendarIcon className="inline-block" />}
                text="Edit Availability"
              />
              <SettingsLinkButton
                to="/settings/edit/notifications"
                icon={<NotificationIcon className="inline-block" />}
                text="Notification Settings"
              />
              <SettingsLinkButton
                to="/privacy"
                icon={<PrivacyIcon className="inline-block" />}
                text="Privacy"
              />
              <SettingsLinkButton
                to="/about"
                icon={<AboutIcon className="inline-block" />}
                text="About Caleido"
              />
              <SettingsLinkButton
                to="/settings/help"
                icon={<HelpIcon className="inline-block" />}
                text="Help"
              />
              <div
                onClick={() => {
                  dispatch(logoutAction());
                  tokenService.clearTokens();
                }}
                className="w-full px-4 py-4 bg-white border-0 rounded-lg cursor-pointer bg-opacity-10"
              >
                <div className="flex items-center">
                  <LogoutIcon className="inline-block" />
                  <p className="inline-block ml-3">Logout</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col justify-end w-full h-full">
        <hr className="mb-5 opacity-20" />
      </div>
      <Text
        size="small"
        className="w-full mb-6 text-left cursor-pointer"
        type="error"
        onClick={() => handleConfirmationModal(true)}
      >
        Delete Account
      </Text>
      <Drawer
        setVisible={setVisible}
        visible={visible}
        className="flex flex-col w-full p-4"
      >
        <Text
          size="medium"
          className="px-4 mt-2 mb-2 font-semibold text-center"
        >
          Are you sure you want to delete your account?
        </Text>
        <Text size="medium" className="mb-6 text-lg font-light text-center">
          You will not be able to access your account once you delete it
        </Text>
        <div className="flex items-center w-full gap-4 px-2 mb-2">
          <ActionButton
            variant="secondary"
            label="Cancel"
            onClick={() => handleConfirmationModal(false)}
          />
          <ActionButton
            label="Yes, Delete"
            onClick={() => handleDeleteUser()}
          />
        </div>
      </Drawer>
    </SettingsLayout>
  );
};

export default Settings;
