import { Check, ChevronLeft, MapPin, Send, Clock } from 'lucide-react';
import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import SideBarButton from 'components/sidebar/sidebarButton';
import DropdownFilter from 'components/v2/DropdownFilter/DropdownFilter';
import { cn } from 'utils/helpers';
import {
  useGetAllEventsQuery,
  useGetEventByDateRangeQuery,
  useRespondEventMutation,
  useUpdateEventMutation,
  useGetAvailableContactsQuery,
} from 'services/event.service';
import Button from 'components/v2/button/Button';
import { EventStatus } from 'utils/constants';
import {
  FILTER_OPTIONS,
  UPDATE_RESPONSE_OPTIONS,
} from 'modules/home/utils/constants';
import TimeSlotVotePopup from 'components/v2/timeSlotVote/TimeSlotVotePopup';
import { useAuth } from 'modules/auth/context/AuthContextV2';
import { IContact, IEvent } from 'types/event';
import UpdateButton from './UpdateButton';
import EmojiStack from './EmojiStack';
import CalendarCard from './Updates/CalendarCard';
import InviteFriendsCard from './Updates/InviteFriendsCard';
import FriendsCard from './Updates/FriendsCard';
import UpdatesCard from './UpdatesCard';
import { UpdateCard as UpdateCardType } from '../hooks/useUpdates';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  updates: UpdateCardType[];
  handleDismiss: (eventId: string, expiresAt?: Date) => void;
};

const UpdatesPopup = ({ isOpen, setIsOpen, updates, handleDismiss }: Props) => {
  const { user } = useAuth();
  const [selectedFilter, setSelectedFilter] = useState<{
    label: string;
    value: string;
  }>(FILTER_OPTIONS[3]);
  const [updateResponse, setUpdateResponse] = useState<{
    label: string;
    value: string;
  }>({ label: 'Going', value: 'going' });
  const [isTimeSlotVoteOpen, setIsTimeSlotVoteOpen] = useState(false);
  const [selectedVoteTimeSlots, setSelectedVoteTimeSlots] = useState<Date[]>(
    [],
  );
  const { data: availableContacts } = useGetAvailableContactsQuery();

  const getDateRange = (offset: number, unit: dayjs.ManipulateType) => ({
    startDate: dayjs()
      .startOf('week')
      .add(1, 'day')
      .add(offset, unit)
      .format('YYYY-MM-DD'),
    endDate: dayjs()
      .endOf('week')
      .add(1, 'day')
      .add(offset, unit)
      .format('YYYY-MM-DD'),
  });

  const { data: thisWeekEvents } = useGetEventByDateRangeQuery(
    getDateRange(0, 'week'),
  );
  const { data: nextWeekEvents } = useGetEventByDateRangeQuery(
    getDateRange(1, 'week'),
  );
  const { data: nextMonthEvents } = useGetEventByDateRangeQuery(
    getDateRange(1, 'month'),
  );

  const memoizedEvents = useMemo(() => {
    let eventsToReturn = [];
    if (selectedFilter.value === 'this-week') {
      eventsToReturn = thisWeekEvents || [];
    } else if (selectedFilter.value === 'next-week') {
      eventsToReturn = nextWeekEvents || [];
    } else if (selectedFilter.value === 'next-month') {
      eventsToReturn = nextMonthEvents || [];
    }
    return eventsToReturn;
  }, [selectedFilter, thisWeekEvents, nextWeekEvents, nextMonthEvents]);

  const [updateEvent] = useUpdateEventMutation();

  if (!user) return null;

  const filteredUpdates = useMemo(() => {
    if (selectedFilter.value === 'most-recent') {
      return updates;
    } else {
      return memoizedEvents.filter((event: IEvent) => {
        return event.contacts?.some((contact: any) => contact._id === user._id);
      });
    }
  }, [user, selectedFilter, memoizedEvents, updates]);

  return (
    <div
      className={cn(
        'fixed inset-0 z-50 duration-300 ease-in-out bg-main flex flex-col p-5',
        isOpen
          ? 'opacity-100 pointer-events-auto'
          : 'opacity-0 pointer-events-none',
      )}
    >
      <div className="flex flex-col items-center">
        <header className="flex items-center justify-between w-full pb-5">
          <div className="flex items-center gap-2">
            <ChevronLeft
              className="cursor-pointer"
              onClick={() => {
                setIsOpen(false);
              }}
            />
          </div>
          <div className="flex items-center justify-center">
            <p className="text-[1.5rem] text-center Westmount">Updates</p>
          </div>
          <div className="flex items-center gap-2">
            <SideBarButton />
          </div>
        </header>
        <div className="flex flex-col gap-4 w-full">
          <div className="flex items-center text-[1rem] justify-between">
            <p className="text-white font-semibold">
              {`${filteredUpdates?.length} Updates`}
            </p>
            <DropdownFilter
              options={FILTER_OPTIONS}
              selectedValue={selectedFilter}
              setSelectedValue={setSelectedFilter}
            />
          </div>
          <div className="flex flex-col gap-4 overflow-y-scroll h-[87svh] pb-10">
            {filteredUpdates.length > 0 ? (
              <>
                {filteredUpdates
                  .filter((update: IEvent) => update.type === 'event')
                  .map((update: any, index: number) => (
                    <div key={index} className="w-full h-max">
                      <UpdatesCard
                        key={index}
                        title={update.activity}
                        className="!bg-[#110E2B] shadow-none"
                        emoji={update.emoji}
                        infoChildren={
                          <div className="flex flex-col gap-2">
                            <div className="flex items-center gap-1">
                              <Clock className="text-[#D7C0FB] w-4 h-4" />
                              <div className="text-[0.875rem]">
                                {update.pollData
                                  ? 'Pending date'
                                  : `${
                                      dayjs().isSame(dayjs(update.start), 'day')
                                        ? 'Today'
                                        : dayjs(update.start).format('MMM D')
                                    } at ${dayjs(update.start).minute() === 0 ? dayjs(update.start).format('h A') : dayjs(update.start).format('h:mm A')}`}
                              </div>
                            </div>
                            {update.location && (
                              <div className="flex items-center gap-1">
                                <MapPin className="text-[#D7C0FB] w-4 h-4" />
                                <div className="text-[0.875rem]">
                                  {update.location}
                                </div>
                              </div>
                            )}
                            <div className="flex items-center gap-1">
                              <Send className="text-[#D7C0FB] w-4 h-4" />
                              <div className="text-[0.875rem]">
                                {update.organizer} invited you
                              </div>
                            </div>
                            {update.contacts && (
                              <div
                                className={cn(
                                  'flex items-center',
                                  update.contacts?.length === 1 && 'gap-1',
                                )}
                              >
                                <EmojiStack
                                  images={update.contacts?.map(
                                    (contact: IContact) => ({
                                      image: contact.photoURL ?? '',
                                      name: contact.name,
                                    }),
                                  )}
                                  size="small"
                                />

                                <div className="flex justify-between">
                                  {!!update.contacts?.length && (
                                    <div>
                                      {update.contacts
                                        ?.slice(0, 2)
                                        .map(
                                          (
                                            contact: IContact,
                                            index: number,
                                          ) => (
                                            <span key={index}>
                                              {contact.name.split(' ')[0]}
                                              {index <
                                                (update.contacts?.length ?? 0) -
                                                  1 && ', '}
                                            </span>
                                          ),
                                        )}

                                      {update.contacts?.length > 2 && (
                                        <span>
                                          {` and +${update.contacts?.length - 2} going`}
                                        </span>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </div>
                            )}
                          </div>
                        }
                        actionsChildren={
                          <>
                            {!update.pollData ? (
                              <UpdateButton
                                className="min-w-[65%]"
                                eventId={update._id}
                                options={UPDATE_RESPONSE_OPTIONS}
                                selectedValue={updateResponse}
                                setSelectedValue={setUpdateResponse}
                              >
                                <div className="flex items-center gap-2.5">
                                  <Check className="h-4 w-4 shrink-0" />
                                  {updateResponse.label}
                                </div>
                              </UpdateButton>
                            ) : (
                              <Button
                                className="min-w-[65%] py-3"
                                onClick={() => {
                                  setIsTimeSlotVoteOpen(true);
                                }}
                              >
                                Tap to respond
                              </Button>
                            )}
                          </>
                        }
                      />
                      {update.pollData && (
                        <TimeSlotVotePopup
                          isOpen={isTimeSlotVoteOpen}
                          setIsOpen={setIsTimeSlotVoteOpen}
                          selectedTimeSlots={selectedVoteTimeSlots}
                          setSelectedTimeSlots={setSelectedVoteTimeSlots}
                          pollData={update.pollData?.checkedTimes}
                          onVote={() => {
                            // updateEvent({
                            //   eventId: update._id,
                            //   response: EventStatus.Accepted,
                            //   phoneNumber: user.phoneNumber,
                            //   pollData: update.pollData,
                            //   eventContactId: update.contacts?.find(
                            //     (contact: IContact) =>
                            //       contact.phoneNumber === user.phoneNumber,
                            //   )?._id,
                            //   responderName: user.name,
                            // });
                            updateEvent({
                              ...update,
                              id: update._id,
                              contacts: update.contacts,
                              pollData: update.pollData,
                              updateEventMetadata: {
                                userId: user._id,
                                phoneNumber: user.phoneNumber,
                                name: user.name,
                                wasUpdatedFromPollVote: true,
                              },
                            });
                          }}
                        />
                      )}
                    </div>
                  ))}
                {!user.isCalendarSync &&
                  updates.find((event) => event.type === 'calendar') && (
                    <CalendarCard
                      className="w-full h-max"
                      handleDismiss={handleDismiss}
                      isShadows={false}
                    />
                  )}
                {updates.find((event) => event.type === 'inviteFriends') && (
                  <InviteFriendsCard
                    className="w-full h-max"
                    handleDismiss={handleDismiss}
                    isShadows={false}
                  />
                )}
                {updates.find((event) => event.type === 'friends') &&
                  availableContacts &&
                  availableContacts?.data?.length > 0 && (
                    <FriendsCard
                      className="w-full h-max"
                      handleDismiss={handleDismiss}
                      isShadows={false}
                    />
                  )}
              </>
            ) : (
              <div className="flex items-center justify-center h-full">
                <p className="text-white text-center">
                  You haven't received an invite <br /> for the event yet.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdatesPopup;
