import { Navigate, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { getDomainType } from 'utils/helpers';
import { isPWA } from 'utils/isPWA';

import OverlayLoader from 'components/loader/OverlayLoader';
import AuthRoutes from 'components/routes/AuthTree';
import BaseTree from 'components/routes/BaseTree';
import NotFound from 'modules/auth/pages/404/NotFound';
import LetsMeetRoutes from 'components/routes/LetsMeet';
import ActivitiesRoutes from 'components/routes/ActivityTree';
import Notifications from 'modules/letsMeet/utils/notification';
import HomeRoutes from 'components/routes/HomeTree';
import PrivacyPolicy from 'modules/auth/pages/privacy-policy/PrivacyPolicy';
import LandingPage from 'modules/landing/pages/MainPage';
import { Toaster } from 'components/ui/toast/toaster';
import { AuthProvider, useAuth } from 'modules/auth/context/AuthContextV2';
import NotificationPrompt from 'modules/notifications/components/NotificationPrompt';
import About from './modules/auth/pages/about/About';
import Terms from './modules/auth/pages/terms/Terms';

import './App.css';
import 'react-loading-skeleton/dist/skeleton.css';
import 'assets/styles/variables.css';
import 'assets/fonts/fonts.css';

import SettingsTree from './components/routes/SettingsTree';
import SharePage from './modules/recieveEvent/SharePage';
import ReceivePublicEvent from './modules/letsMeet/pages/receivePublicEvent/receivePublicEvent';

/**
 * Main App component that handles routing and app initialization
 */
function App() {
  const { loading } = useAuth();

  const isPrivacyOrTOCPage =
    window.location.pathname.includes('privacy') ||
    window.location.pathname.includes('terms');
  const domainType = getDomainType();
  const isRunningAsPWA = isPWA();

  // Handle special case for landing page
  if (domainType === 'landing' && !isRunningAsPWA && !isPrivacyOrTOCPage) {
    return <LandingPage />;
  }

  return (
    <HelmetProvider>
      <AuthProvider>
        <>
          {loading && <OverlayLoader />}
          <Notifications />
          <NotificationPrompt
            delayMs={3000}
            onPermissionGranted={() => console.log('Notifications enabled!')}
          />
          <Routes>
            {/* Public routes */}
            <Route path="/share/:id" element={<SharePage />} />
            <Route path="/public/:id" element={<ReceivePublicEvent />} />
            <Route path="landing" element={<LandingPage />} />
            <Route path="terms" element={<Terms />} />
            <Route path="privacy" element={<PrivacyPolicy />} />
            <Route path="about" element={<About />} />

            {/* Auth routes */}
            <Route path="auth/*" element={<AuthRoutes />} />
            <Route path="auth/" element={<Navigate to="/auth/login" />} />

            {/* Application routes */}
            <Route path="profile/*" element={<BaseTree />} />
            <Route path="profile/" element={<Navigate to="/profile/start" />} />
            <Route path="/" element={<HomeRoutes />} />
            <Route path="settings/*" element={<SettingsTree />} />
            <Route path="lets-meet/*" element={<LetsMeetRoutes />} />
            <Route path="activities/*" element={<ActivitiesRoutes />} />
            <Route path="home/*" element={<HomeRoutes />} />

            {/* Fallback routes */}
            <Route path="*" element={<NotFound />} />
            <Route path="/404" element={<NotFound />} />
          </Routes>
          <Toaster />
        </>
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;
