import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'modules/auth/context/AuthContextV2';
import Button from 'components/v2/button/Button';
import { cn } from 'utils/helpers';
import UpdatesCard from '../UpdatesCard';
import EmojiAvatar from '../EmojiAvatar';
import { useGetAvailableContactsQuery } from 'services/event.service';
import dayjs from 'dayjs';

type Props = {
  className?: string;
  handleDismiss: (eventId: string, expiresAt?: Date) => void;
  isShadows?: boolean;
};

const FriendsCard = ({ handleDismiss, isShadows = true, className }: Props) => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const { data: availableContacts } = useGetAvailableContactsQuery();
  const [selectedContacts, setSelectedContacts] = useState<Set<string>>(
    new Set(),
  );

  if (!user) return null;

  const toggleContactSelection = (contactId: string) => {
    setSelectedContacts((prevSelected) => {
      const newSelected = new Set(prevSelected);
      if (newSelected.has(contactId)) {
        newSelected.delete(contactId);
      } else {
        newSelected.add(contactId);
      }
      return newSelected;
    });
  };

  const handleNavigate = () => {
    const selectedContactsIds = Array.from(selectedContacts).join(',');
    navigate(
      `/lets-meet?selectedContactsIds=${encodeURIComponent(selectedContactsIds)}`,
    );
  };

  return (
    <>
      {isShadows && (
        <div className="absolute bg-transparent w-full h-full shadow-[0px_50px_110px_-61px_#AAA8FF] rounded-[6rem] z-[-1]"></div>
      )}
      <UpdatesCard
        title={`You have (${availableContacts?.data?.length}) friends free now`}
        isEmojiIcon={false}
        className={cn('shadow-none', className)}
        description="Copy your customer profile link below and share your profile with others to join you on Caleido for faster meetup coordination"
        infoChildren={
          <div className="w-full flex flex-col gap-2 overflow-hidden">
            <div className="text-[0.875rem]">Swipe to see all</div>

            <div
              className="w-[calc(100svw-5rem)] overflow-x-auto"
              onTouchMove={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="w-max flex items-start gap-[1rem]">
                {availableContacts?.data?.map((contact, index: number) => (
                  <div
                    className={`flex flex-col py-2 px-0 min-w-[4rem] w-full rounded-[1rem] transition-all duration-300 justify-center items-center gap-1 ${selectedContacts.has(contact.phoneNumber) ? 'bg-[#434572]' : ''}`}
                    key={index}
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleContactSelection(contact.phoneNumber);
                    }}
                  >
                    <EmojiAvatar
                      size="medium-large"
                      fullName={contact.name}
                      image={`${process.env.REACT_APP_BASE_URL}/users/profile-pic/${contact.userId}`}
                      isOnline={true}
                    />
                    <div className="flex flex-col gap-[0.125rem]">
                      <div className="text-center text-[0.875rem]">
                        {contact.name.split(' ').slice(0, 2).join(' ')}
                      </div>
                      <div className="text-center text-[0.75rem] text-[#ABE49C]">
                        {`until ${dayjs(contact.endDate).minute() === 0 ? dayjs(contact.endDate).format('ha') : dayjs(contact.endDate).format('h:mma')}`}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        }
        actionsChildren={
          <>
            <Button
              className="min-w-[65%] py-3"
              variant="primary"
              onClick={handleNavigate}
              disabled={selectedContacts.size === 0}
            >
              Tap to hang out
            </Button>
            <Button
              variant="secondary"
              className="py-3"
              onClick={() => {
                handleDismiss('friends');
              }}
            >
              Dismiss
            </Button>
          </>
        }
      />
    </>
  );
};

export default FriendsCard;
