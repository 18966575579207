import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ONBOARDING_ROUTES } from 'components/routes';
import PrivateRoute from './PrivateRoute';
import { useAuth } from 'modules/auth/context/AuthContextV2';

const BaseTree: React.FC = () => {
  const { isAuthenticated } = useAuth() ?? false;

  return (
    <>
      <Routes>
        {ONBOARDING_ROUTES?.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <PrivateRoute isAuthenticated={isAuthenticated}>
                {route.element}
              </PrivateRoute>
            }
          />
        ))}
      </Routes>
    </>
  );
};

export default BaseTree;
