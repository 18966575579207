import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import OverlayLoader from 'components/loader/OverlayLoader';
import OnboardingLayout from 'modules/auth/layouts/AuthLayout';
import { useAuth } from 'modules/auth/context/AuthContextV2';
import tokenUtils from 'modules/auth/utils/tokenUtils';

const SharePage = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { user } = useAuth();

  useEffect(() => {
    const redirectTimer = setTimeout(() => {
      if (user === undefined && (tokenUtils.getToken() !== null || tokenUtils.getRefreshToken() !== null)) {
        return; // Wait for user state to be determined
      }

      if (user === null || (tokenUtils.getToken() === null && tokenUtils.getRefreshToken() === null)) {
        navigate(`/public/${id}`);
      } else {
        navigate(`/lets-meet/receive/${id}`);
      }
      setLoading(false);
    }, 300);

    return () => clearTimeout(redirectTimer);
  }, [user, navigate, id]);

  return <OnboardingLayout>{loading && <OverlayLoader />}</OnboardingLayout>;
};

export default SharePage;
