import { forwardRef, type InputHTMLAttributes, useState } from 'react';
import { X } from 'lucide-react';
import ProfileAvatar from 'components/avatar/ProfileAvatar'; // Fixed import statement
import { Text } from 'components/text/primary/Text';
import { cn } from 'utils/helpers';
import { Emoji } from 'modules/letsMeet/components/emoji/emoji';
import { Contact } from 'modules/profile/types/types';

interface SquareInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  className?: string;
  required?: boolean;
  error?: boolean | string;
  endAdornment?: React.ReactNode;
  emoji?: string;
  selectedMultipleOptions?: Contact[];
  selectedOption?: string;
  removeOption?: (option: string) => void;
  onClear?: () => void;
}

const SquareInput = forwardRef<HTMLInputElement, SquareInputProps>(
  (
    {
      label,
      className,
      required,
      error,
      endAdornment,
      emoji,
      selectedMultipleOptions,
      selectedOption,
      removeOption,
      onClear,
      ...props
    },
    ref,
  ) => {
    const [isFocused, setIsFocused] = useState(false);

    return (
      <div className="relative flex flex-col w-full my-2">
        <div className="absolute top-[-0.750rem] left-5 flex items-center bg-main z-10 justify-start space-x-2 px-1 mb-2">
          <Text
            size="small"
            className={cn(
              'text-base font-sofia-normal transition-colors duration-300',
              isFocused ? 'text-[#ABE49C]' : 'text-[#D7C0FB]',
            )}
          >
            {label}
          </Text>

          {required && <Text size="small">*</Text>}
        </div>

        <div
          className={cn(
            'relative flex justify-start items-center border-2 h-full border-solid border-[#D7C0FB] rounded p-4 pl-12 focus-within:border-[#ABE49C]',
            `${selectedMultipleOptions && selectedMultipleOptions.length > 0 ? 'pl-14' : ''}`,
          )}
        >
          {selectedMultipleOptions && selectedMultipleOptions.length > 0 && (
            <div className="flex w-4/5 overflow-scroll gap-2">
              {selectedMultipleOptions.map((option, index) => (
                <div
                  key={index}
                  className="h-full flex items-center justify-between rounded-full bg-box text-white text-sm px-3 py-2 "
                >
                  <ProfileAvatar
                    size={20}
                    url={`${process.env.REACT_APP_BASE_URL}/users/profile-pic/${option.userId}`}
                    hideBorder
                  />

                  <div className="text-sm whitespace-nowrap overflow-hidden text-ellipsis">
                    {option.name}
                  </div>

                  <div
                    onClick={() => removeOption?.(option.phoneNumber)}
                    className={'ml-2.5 flex-shrink-0'}
                  >
                    <X
                      size={14}
                      className={cn(
                        'transition-colors duration-300',
                        isFocused ? 'text-[#ABE49C]' : 'text-[#D7C0FB]',
                      )}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}

          {selectedOption && (
            <div className="flex items-center justify-between rounded-full  text-white text-sm px-3 pr-0 m-1 mr-0">
              <div className="text-sm whitespace-nowrap overflow-hidden text-ellipsis">
                {selectedOption}
              </div>
            </div>
          )}

          {selectedOption && (
            <div className="absolute w-full flex justify-end items-end flex-wrap top-[1.7rem] right-0 pointer-events-none">
              <div className="relative text-sm w-full rounded pl-16 pr-5 py-1 flex items-center justify-end ">
                <X
                  onClick={onClear}
                  className={cn(
                    'h-5 w-5 cursor-pointer pointer-events-auto transition-colors duration-300',
                    isFocused ? 'text-[#ABE49C]' : 'text-[#D7C0FB]',
                  )}
                />
              </div>
            </div>
          )}

          <input
            type="text"
            className={cn(
              'w-full max-w-sm h-full mx-auto font-sans text-sm font-medium text-gray-300 rounded-[4px] bg-transparent disabled:opacity-30 focus:outline-none pl-4 py-4 pr-6 font-sofia-light',
              className,
              `${error ? 'border-red-600' : 'border-slate-600 border-opacity-0'}`,
              `${selectedMultipleOptions && selectedMultipleOptions.length > 0 ? 'pl-2' : ''}`,
            )}
            ref={ref}
            required={required}
            autoComplete="off"
            disabled
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            {...props}
          />

          {emoji && (
            <div className="absolute left-3 h-12 w-12 flex justify-center items-center">
              {emoji && <Emoji size="medium" emoji={emoji} />}
            </div>
          )}
        </div>

        {error && (
          <Text size="extra-small" type="error" className="mt-1.5">
            {error}
          </Text>
        )}
      </div>
    );
  },
);

SquareInput.displayName = 'SquareInput';

export default SquareInput;
