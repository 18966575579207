import { useNavigate } from 'react-router-dom';

import { Bell } from 'lucide-react';
import CalendarButton from 'modules/home/pages/homePage/CalendarButton';
import EmojiAvatar from 'modules/home/pages/homePage/EmojiAvatar';
import { useAuth } from 'modules/auth/context/AuthContextV2';

const BottomNav: React.FC = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  if (!user) return null;

  return (
    <div
      className="fixed bottom-0 w-full bg-[#0A0118] flex justify-between items-end gap-4 px-[1.75rem] pb-[1.875rem] pt-3 max-w-md"
      style={{ margin: 0 }}
    >
      <EmojiAvatar fullName={user.name} image={user.photoURL} size="large" />
      <CalendarButton iconClassName="w-11 h-11" className="mb-[0.375rem]" />
      <div className="flex items-center gap-2">
        <Bell
          className="w-10 h-10 cursor-pointer mb-1"
          onClick={() => navigate('/activities')}
        />
      </div>
    </div>
  );
};

export default BottomNav;
