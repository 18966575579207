import { useCallback, useEffect, useRef, useState } from 'react';
import { SquareAutocompleteInput } from 'components/v2/squareAutocompleteInput/SquareAutocompleteInput';

interface PlacePrediction {
  description: string;
  place_id: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
}

type Props = {
  selectedLocation: string | null;
  setSelectedLocation: (location: string | null) => void;
  setSelectedLocationName: (location: string) => void;
  editInputValue?: string;
  error?: string | null;
};

export const SquareLocationInput = ({
  selectedLocation,
  setSelectedLocation,
  setSelectedLocationName,
  editInputValue,
  error,
}: Props) => {
  const [suggestions, setSuggestions] = useState<PlacePrediction[]>([]);
  const autocompleteServiceRef =
    useRef<google.maps.places.AutocompleteService | null>(null);

  useEffect(() => {
    const loadScript = (url: string, callback: () => void) => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.onload = callback;
      document.body.appendChild(script);
    };

    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
      () => {
        autocompleteServiceRef.current =
          new window.google.maps.places.AutocompleteService();
      },
    );
  }, []);

  const onLocationChange = (location: string) => {
    setSelectedLocationName(location);
  };

  const handleLocationChange = useCallback((value: string) => {
    if (value) {
      autocompleteServiceRef.current?.getPlacePredictions(
        { input: value, types: ['establishment', 'geocode'] },
        (predictions, status) => {
          if (
            status === window.google.maps.places.PlacesServiceStatus.OK &&
            predictions
          ) {
            setSuggestions((prev) => {
              const newItems = predictions.filter(
                (prediction) =>
                  !prev.some((item) => item.place_id === prediction.place_id),
              );

              return [...prev, ...newItems] as PlacePrediction[];
            });
          } else {
            setSuggestions((prev) => {
              const newItems = predictions.filter(
                (prediction) =>
                  !prev.some((item) => item.place_id === prediction.place_id),
              );

              return [...prev, ...newItems] as PlacePrediction[];
            });
          }
        },
      );
    } else {
      return;
    }
  }, []);

  return (
    <SquareAutocompleteInput
      label="Location"
      dropdownLabel="CHOOSE LOCATION"
      dropdownEmptyLabel="No locations added yet."
      placeholder="Enter location"
      defaultEmoji="📍"
      editInputValue={editInputValue}
      options={suggestions.map((suggestion) => ({
        label:
          suggestion?.structured_formatting?.main_text ||
          suggestion.description,
        value: suggestion.place_id,
        subLabel: suggestion.structured_formatting.secondary_text,
      }))}
      setSelectedOption={(option) => {
        setSelectedLocation(option);
        if (option) {
          const selectedSuggestion = suggestions.find(
            (s) => s.place_id === option,
          );
          if (selectedSuggestion) {
            onLocationChange(selectedSuggestion.description);
          }
        }
      }}
      onInputValueChange={handleLocationChange}
      selectedOption={selectedLocation}
      keepInputValue
      error={error}
    />
  );
};
