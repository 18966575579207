import React, { useMemo, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import dayjs from 'dayjs';
import { cn } from 'utils/helpers';
import CalendarPicker from 'components/v2/calendarPicker/CalendarPicker';
import TimeSlotPicker from 'components/v2/timeSlotPicker/TimeSlotPicker';
import Button from 'components/v2/button/Button';
import { SquareAutocompleteInput } from 'components/v2/squareAutocompleteInput/SquareAutocompleteInput';
import StepLaterDate from 'modules/letsMeet/pages/createEvent/StepLaterDate';
import { compareDates } from 'modules/letsMeet/utils/dates';
import { DURATION_OPTIONS } from 'utils/constants';
import { useGetAvailabilitiesQuery } from 'services/calendar.service';
import LetsMeetLayout from '../../layouts/LetsMeetLayout';

type Props = {
  isOpen: boolean;
  onBack: () => void;
  onSave: () => void;
  selectedDatesWithTime: Date[];
  setSelectedDatesWithTime: React.Dispatch<React.SetStateAction<Date[]>>;
  selectedDuration: string | null;
  setSelectedDuration: React.Dispatch<React.SetStateAction<string | null>>;
};

const CreateEventV2PollGuestsPopup = ({
  isOpen,
  onBack,
  onSave,
  selectedDatesWithTime,
  setSelectedDatesWithTime,
  selectedDuration,
  setSelectedDuration,
}: Props) => {
  const [selectedCalendarDates, setSelectedCalendarDates] = useState<Date[]>(
    [],
  );

  // Set default selected duration if not already set
  useEffect(() => {
    if (!selectedDuration) {
      setSelectedDuration(DURATION_OPTIONS[0]?.value || null); // Set to first option or null
    }
  }, [selectedDuration, setSelectedDuration]);

  const sortedSelectedCalendarDates = useMemo(() => {
    return [...selectedCalendarDates].sort((a, b) => dayjs(a).diff(dayjs(b)));
  }, [selectedCalendarDates]);

  const { data: availabilities } = useGetAvailabilitiesQuery(
    {
      dates: sortedSelectedCalendarDates.map((date) =>
        dayjs(date).format('YYYY-MM-DD'),
      ),
      interval: parseInt(selectedDuration || '30'),
    },
    {
      skip: !sortedSelectedCalendarDates.length,
    },
  );

  const sortedDatesWithAvailableTimeSlots = useMemo(() => {
    const timezone = availabilities?.find((a) => a.timezone)?.timezone;
    return sortedSelectedCalendarDates.map((date) => {
      const availableTimes = (
        availabilities?.find((a) =>
          dayjs(a.date, 'YYYY-MM-DD').isSame(dayjs(date), 'day'),
        )?.times ?? []
      ).map((time) => ({
        ...time,
        start: dayjs(time.start).tz(timezone, true).toISOString(),
        end: dayjs(time.end).tz(timezone, true).toISOString(),
      }));
      return {
        date,
        availableTimeSlots: availableTimes,
      };
    });
  }, [sortedSelectedCalendarDates, availabilities]);

  const handleDayTimeSlotChange = (date: Date, dateWithTimeSlots: Date[]) => {
    setSelectedDatesWithTime((prev) => [
      ...prev.filter((d) => !compareDates(d, date)),
      ...dateWithTimeSlots,
    ]);
  };

  const handleCalendarDateChange = (dates: Date[]) => {
    setSelectedCalendarDates(dates);
    setSelectedDatesWithTime((prev) =>
      prev.filter((d) => dates.some((date) => compareDates(d, date))),
    );
  };

  return createPortal(
    <div
      className={cn(
        'fixed inset-0 z-50 duration-300 ease-in-out',
        isOpen
          ? 'opacity-100 pointer-events-auto'
          : 'opacity-0 pointer-events-none',
      )}
    >
      <LetsMeetLayout
        title="Choose Dates"
        step="create"
        footerText={''}
        actionOnHeaderRight={''}
        widget="menu"
        handleGoBack={onBack}
        bottomChildren={
          <Button
            onClick={onSave}
            className={cn(
              'opacity-50',
              selectedDatesWithTime.length === 0 ? 'opacity-50' : 'opacity-100',
            )}
            disabled={selectedDatesWithTime.length === 0}
          >
            Save
          </Button>
        }
      >
        <div className="mt-4 flex flex-col">
          <div className="flex flex-col gap-4">
            <StepLaterDate step={1} label="Select duration" />
            <SquareAutocompleteInput
              label="Duration"
              options={DURATION_OPTIONS}
              selectedOption={selectedDuration}
              setSelectedOption={(option) => {
                if (option) {
                  setSelectedDuration(option);
                }
              }}
              dropdownLabel={'Choose duration'}
              placeholder={'Choose duration'}
              dropdownEmptyLabel={''}
              size="medium"
              isDropdownIcon={true}
            />
          </div>
          <div className="flex flex-col gap-4 mt-6">
            <StepLaterDate step={2} label="Select dates" />
            <CalendarPicker
              type="multiple"
              selectedDates={selectedCalendarDates}
              setSelectedDates={handleCalendarDateChange}
              only="future-dates"
            />
          </div>
          <div className="flex flex-col my-4">
            <StepLaterDate
              step={3}
              label="Confirm start time options"
              subLabel={
                selectedCalendarDates.length === 0
                  ? 'Times will populate after you select a duration and dates'
                  : undefined
              }
            />

            <div className="flex flex-col mt-2">
              {sortedDatesWithAvailableTimeSlots.map((date) => (
                <TimeSlotPicker
                  key={date.date.toISOString()}
                  selectedDate={date.date}
                  selectedDatesWithTime={selectedDatesWithTime.filter((d) =>
                    dayjs(d).isSame(dayjs(date.date), 'day'),
                  )}
                  setSelectedDatesWithTime={(dates) => {
                    handleDayTimeSlotChange(date.date, dates);
                  }}
                  availableTimeSlots={date.availableTimeSlots}
                />
              ))}
            </div>
          </div>
        </div>
      </LetsMeetLayout>
    </div>,
    document.body,
  );
};

export default CreateEventV2PollGuestsPopup;
