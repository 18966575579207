import React, { useState } from 'react';
import { cn } from 'utils/helpers';
import Button from 'components/v2/button/Button';
import UpdatesCard from '../UpdatesCard';

type Props = {
  className?: string;
  handleDismiss: (eventId: string, expiresAt?: Date) => void;
  isShadows?: boolean;
};

const InviteFriendsCard = ({
  handleDismiss,
  isShadows = true,
  className,
}: Props) => {
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyLink = async () => {
    const linkToCopy = 'https://caleido.social/';

    try {
      if (navigator.clipboard && window.isSecureContext) {
        await navigator.clipboard.writeText(linkToCopy);
      } else {
        const textArea = document.createElement('textarea');
        textArea.value = linkToCopy;
        textArea.style.position = 'absolute';
        textArea.style.left = '-9999px';
        document.body.appendChild(textArea);
        textArea.select();
        document.execCommand('copy');
        document.body.removeChild(textArea);
      }

      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (error) {
      console.error('Failed to copy text: ', error);
    }
  };

  return (
    <>
      {isShadows && (
        <div className="absolute bg-transparent w-full h-full shadow-[0px_50px_110px_-61px_#AAA8FF] rounded-[6rem] z-[-1]"></div>
      )}
      <UpdatesCard
        title="Invite your friends"
        description="Copy your customer profile link below and share your profile with others to join you on Caleido for faster meetup coordination"
        emoji="👬"
        className={cn('shadow-none', className)}
        actionsChildren={
          <>
            <Button
              className={cn(
                'min-w-[65%] py-3 relative transition-all duration-500 flex items-center justify-center',
                isCopied && 'bg-green-600 pointer-events-none',
              )}
              variant="primary"
              onClick={handleCopyLink}
            >
              <span
                className={cn(
                  'absolute transition-all duration-500',
                  !isCopied ? 'opacity-0' : 'opacity-100',
                )}
              >
                Copied!
              </span>

              <span
                className={cn(
                  'absolute transition-all duration-500',
                  isCopied ? 'opacity-0' : 'opacity-100',
                )}
              >
                🔗 Copy and share link
              </span>
            </Button>
            <Button
              variant="secondary"
              className="py-3"
              onClick={() => {
                handleDismiss('inviteFriends');
              }}
            >
              Dismiss
            </Button>
          </>
        }
      />
    </>
  );
};

export default InviteFriendsCard;
