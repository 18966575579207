import React from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
};

const MobilePopup = ({ isOpen, setIsOpen, children }: Props) => {
  const handleOverlayClick = () => {
    setIsOpen(false);
  };

  return (
    <>
      <div
        className={cn(
          'h-auto w-full fixed left-0 bottom-0 bg-main flex flex-col gap-2 px-5 py-2 rounded-t-[1.25rem] box-shadow-[0_1rem_1rem_0_rgba(232, 232, 232, 0.9)] z-[20] transition-transform,opacity duration-300',
          {
            'transform translate-y-0 opacity-100': isOpen,
            'transform translate-y-full opacity-0': !isOpen,
          },
        )}
      >
        <div className="max-h-[60svh] flex flex-col gap-2">{children}</div>
      </div>
      <div
        className={cn(
          'fixed inset-0 bg-black opacity-0 z-10 transition-opacity duration-300 pointer-events-none',
          {
            'opacity-90 pointer-events-auto': isOpen,
          },
        )}
        onClick={handleOverlayClick}
      />
    </>
  );
};

export default MobilePopup;
