import dayjs from 'dayjs';

export const generateTimeOptions = () => {
  const timeOptions = Array.from({ length: 48 }, (_, index) => {
    const hour = Math.floor(index / 2);
    const minute = index % 2 === 0 ? 0 : 30;
    const date = dayjs().hour(hour).minute(minute);

    return {
      label: date.format('hh:mmA'), // Changed to 'hh:mmA' to remove space
      value: date.toDate(),
      hour: hour,
      minute: minute,
    };
  });

  return timeOptions;
};

export const compareHoursAndMinutes = (date1: Date, date2: Date) => {
  const date1WithoutTimezone = new Date(
    date1.getTime() - date1.getTimezoneOffset() * 60000,
  );
  const date2WithoutTimezone = new Date(
    date2.getTime() - date2.getTimezoneOffset() * 60000,
  );

  return (
    date1WithoutTimezone.getFullYear() === date2WithoutTimezone.getFullYear() &&
    date1WithoutTimezone.getMonth() === date2WithoutTimezone.getMonth() &&
    date1WithoutTimezone.getDate() === date2WithoutTimezone.getDate() &&
    date1WithoutTimezone.getHours() === date2WithoutTimezone.getHours() &&
    date1WithoutTimezone.getMinutes() === date2WithoutTimezone.getMinutes()
  );
};

export const compareDates = (date1: Date, date2: Date) => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getDate() === date2.getDate()
  );
};
