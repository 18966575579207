import { CalendarPlus } from 'lucide-react';
import { cn } from 'utils/helpers';
import { useNavigate } from 'react-router-dom';

type Props = {
  className?: string;
  iconClassName?: string;
};

const CalendarButton = ({ className, iconClassName }: Props) => {
  const navigate = useNavigate();

  return (
    <div
      className={cn(
        'flex items-center gap-2 rounded-full bg-[#6000E9] px-4 py-4 cursor-pointer',
        className,
      )}
      onClick={() => navigate('/lets-meet')}
    >
      <CalendarPlus className={cn('w-11 h-11', iconClassName)} />
    </div>
  );
};

export default CalendarButton;
