import React, { useEffect, useRef } from 'react';

type Props = {
  children: React.ReactNode;
  onInView: () => void;
};

const InfiniteList = ({ children, onInView }: Props) => {
  const observerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          onInView();
        }
      },
      { threshold: 1.0 },
    );

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    return () => observer.disconnect();
  }, [onInView]);

  return (
    <div className="w-full h-full">
      {children}
      <div ref={observerRef} className="bg-transparent" />
    </div>
  );
};

export default InfiniteList;
