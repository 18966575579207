import React, { useState, useMemo, useEffect } from 'react';
import dayjs from 'dayjs';
import { X } from 'lucide-react';
import { Text } from 'components/text/primary/Text';
import Button from 'components/v2/button/Button';
import { Checkbox } from 'components/ui/checkbox';
import TimePicker from 'components/v2/timePicker/TimePicker';
import { compareHoursAndMinutes } from 'modules/letsMeet/utils/dates';
import { AvailabilitySlot } from 'services/calendar.service';
import MobilePopup from '../mobilePopup/MobilePopup';
import utc from 'dayjs/plugin/utc';
import { useAuth } from 'modules/auth/context/AuthContextV2';
import { useRespondEventMutation } from 'services/event.service';
import timezone from 'dayjs/plugin/timezone';
import { parseRangeDateString } from 'modules/letsMeet/utils/parseRangeDateString';

type Props = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedTimeSlots: Date[];
  setSelectedTimeSlots: (timeSlots: Date[]) => void;
  availableTimeSlots?: AvailabilitySlot[];
  pollData?: {
    time: string;
    voters: { name: string; userId: string; _id: string }[];
    id?: string;
  }[];
  onVote: (selectedTimeSlots: Date[]) => void;
};

const TimeSlotVotePopup = ({
  isOpen,
  setIsOpen,
  selectedTimeSlots,
  setSelectedTimeSlots,
  pollData = [],
  onVote,
}: Props) => {
  const { user } = useAuth();

  const handleTimeSlotClick = (timeSlot: { time: Date }) => {
    const timeSlotIndex = selectedTimeSlots.findIndex(
      (slot) => slot.getTime() === timeSlot.time.getTime(),
    );

    if (timeSlotIndex !== -1) {
      setSelectedTimeSlots(
        selectedTimeSlots.filter((_, index) => index !== timeSlotIndex),
      );
    } else {
      setSelectedTimeSlots([...selectedTimeSlots, timeSlot.time]);
    }
  };

  const handleConfirm = () => {
    // Add the user to the voters list for each selected time slot
    const updatedPollData = pollData.map((timeSlot) => {
      if (
        selectedTimeSlots.some((slot) =>
          dayjs(slot).isSame(dayjs(timeSlot.time), 'minute'),
        )
      ) {
        return {
          ...timeSlot,
          voters: Array.from(
            new Set([
              ...timeSlot.voters,
              {
                name: user?.name ?? '',
                userId: user?._id ?? '',
                _id: user?._id ?? '',
              },
            ]),
          ),
        };
      }
      return timeSlot;
    });

    onVote(selectedTimeSlots);
    setIsOpen(false);
  };

  const parsedPollDates = useMemo(() => {
    return pollData.map((timeSlot) => {
      return {
        ...timeSlot,
        times: parseRangeDateString(timeSlot.time),
      };
    });
  }, [pollData]);

  if (!user) {
    return null;
  }

  return (
    <MobilePopup isOpen={isOpen} setIsOpen={setIsOpen}>
      <>
        <div className="flex items-center justify-between">
          <Text
            size="small"
            className="text-[rgb(255,255,255,0.6)] font-semibold text-[0.875rem] py-2"
          >
            SELECT ALL THAT APPLY
          </Text>
        </div>
        <div className="flex flex-col">
          <div className="max-h-[28svh] flex flex-col gap-2 overflow-y-scroll">
            {parsedPollDates.length > 0 ? (
              parsedPollDates.map((timeSlot, index) => {
                const isSelected = selectedTimeSlots.some((slot) =>
                  dayjs(slot).isSame(dayjs(timeSlot.times.startDate), 'minute'),
                );

                return (
                  <div
                    key={index}
                    className="relative flex items-center gap-2 cursor-pointer"
                    onClick={() => {
                      handleTimeSlotClick({
                        time: dayjs(timeSlot.times.startDate).toDate(),
                      });
                    }}
                  >
                    <Checkbox checked={isSelected} />
                    <div className="flex flex-col text-[0.875rem] font-normal font-manrope py-2 px-4">
                      <div className="text-white">
                        {`${dayjs(timeSlot.times.startDate).format('ddd, MMM D')} at ${dayjs(timeSlot.times.startDate).format('h:mm A')}`}
                      </div>
                      <div className="text-[rgb(255,255,255,0.6)]">{`${timeSlot.voters.length} voted`}</div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="flex items-center justify-center h-full mb-4">
                <Text size="small" className="text-gray-500">
                  No time slots available
                </Text>
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col justify-center gap-2 pt-5">
          <Button onClick={handleConfirm}>Confirm</Button>
        </div>
      </>
    </MobilePopup>
  );
};

export default TimeSlotVotePopup;
