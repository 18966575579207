import React, { useState } from 'react';
import { ChevronDown, Check } from 'lucide-react';
import cn from 'classnames';
import { useUpdateEventMutation } from 'services/event.service';
import { EventStatus } from 'utils/constants';

type Props = {
  children: React.ReactNode;
  options: { label: string; value: string }[];
  selectedValue: { label: string; value: string };
  setSelectedValue: (value: { label: string; value: string }) => void;
  className?: string;
  eventId: string;
};

const UpdateButton = ({
  children,
  options,
  selectedValue,
  setSelectedValue,
  className,
  eventId,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const [updateEvent] = useUpdateEventMutation();

  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const handleOptionClick = (option: { label: string; value: string }) => {
    setSelectedValue(option);
    setIsOpen(false);
    updateEvent({
      eventId: eventId,
      response: EventStatus[option.value as keyof typeof EventStatus],
    });
  };

  return (
    <div className={cn('relative w-full', className)}>
      <button
        onClick={toggleDropdown}
        className="w-full flex justify-between items-center text-white font-semibold bg-[#6000E9] border-none active:border-none rounded-full px-4 py-3 whitespace-nowrap"
      >
        {children} <ChevronDown className="ml-2 h-4 w-4 shrink-0" />
      </button>
      {isOpen && (
        <div className="absolute w-full flex flex-col bg-main whitespace-nowrap shadow-[1px_2px_10px_-5px_#ffffff71,-1px_2px_10px_0px_#ffffff71,0px_2px_10px_-6px_#ffffff71] text-white text-left font-normal leading-normal text-xs border-none mt-2 rounded-[0.75rem]">
          <p className="uppercase font-semibold text-[rgb(255,255,255,0.6)] py-2 px-4">
            Update response
          </p>
          {options.map((option, index) => (
            <div
              key={option.value}
              className={cn(
                'py-3 px-4 flex items-center justify-between w-full',
                selectedValue.value === option.value && 'bg-[#32395E]',
                index === options.length - 1 && 'rounded-b-[0.75rem]',
              )}
              onClick={() => handleOptionClick(option)}
            >
              {option.label}
              {selectedValue.value === option.value && (
                <Check className="h-4 w-4 shrink-0" />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default UpdateButton;
