import { format } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import { ReactComponent as MapIcon } from 'assets/images/icons/map.svg';
import { ReactComponent as ClockIcon } from 'assets/images/icons/clockLg.svg';
import { ReactComponent as CalendarIcon } from 'assets/images/icons/calendar.svg';
import { ReactComponent as InfoIcon } from 'assets/images/icons/info.svg';

import { Text } from 'components/text/primary/Text';

import { IEvent } from 'types/event';
import { Separator } from 'components/ui/separator';

// Render a line with icon
const MeetInfoItem = ({ text, Icon }: { text: string; Icon: any }) => {
  return (
    <div className="flex gap-2">
      <div className="h-5 flex items-center">{Icon}</div>
      <Text size="small" className="w-full">
        {text}
      </Text>
    </div>
  );
};

// TODO: We really need to make consistency with dates in the app overall...
interface IEventWithStringDates extends Omit<IEvent, 'start' | 'end'> {
  start: string;
  end: string;
  withPadding?: boolean;
}

interface MeetNowProps {
  type: string;
  location: string;
  start?: Date;
  end?: Date;
  withPadding?: boolean;
  details?: string;
}

export const MeetInfo = (props: MeetNowProps) => {
  const getOrdinalIndicator = (day: number) => {
    const j = day % 10;
    const k = day % 100;
    if (j === 1 && k !== 11) {
      return 'st';
    }
    if (j === 2 && k !== 12) {
      return 'nd';
    }
    if (j === 3 && k !== 13) {
      return 'rd';
    }
    return 'th';
  };

  const formatDate = (date: Date, timezone: string) => {
    const zonedDate = toZonedTime(date, timezone);
    const dayOfMonth = format(zonedDate, 'd');
    const ordinalIndicator = getOrdinalIndicator(Number(dayOfMonth));
    return `${format(zonedDate, 'EEE, MMM')} ${dayOfMonth}${ordinalIndicator}`;
  };

  const formatDateToLocalTimezone = (
    dateString: string,
    timezone: string,
    formatStr: string,
  ) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    const zonedDate = toZonedTime(date, timezone);
    return format(zonedDate, formatStr);
  };

  const getUserTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  const startDateStr = props.start
    ? new Date(props.start).toISOString()
    : undefined;
  const endDateStr = props.end ? new Date(props.end).toISOString() : undefined;
  const localStartDate = formatDateToLocalTimezone(
    startDateStr ?? '',
    getUserTimezone(),
    'h:mma',
  );

  const localEndTime = formatDateToLocalTimezone(
    endDateStr ?? '',
    getUserTimezone(),
    'h:mma',
  );
  const localEndDate = formatDateToLocalTimezone(
    endDateStr ?? '',
    getUserTimezone(),
    'EEE, MMM do',
  );

  return (
    <div className="flex flex-col gap-2.5 my-6">
      {props.type === 'now' && props.end && (
        <>
          <MeetInfoItem
            text={`NOW until ${localEndTime}`}
            Icon={<ClockIcon />}
          />
          <MeetInfoItem text={localEndDate} Icon={<CalendarIcon />} />
        </>
      )}

      {props.type === 'later' && props.start && (
        <>
          <MeetInfoItem
            text={
              props.end ? `${localStartDate} - ${localEndTime}` : localStartDate
            }
            Icon={<ClockIcon />}
          />

          {
            // TODO: new Date(props.start) is very risky. Dates in events should be of one type (either dates or strings, not both)
          }
          <MeetInfoItem
            text={formatDate(new Date(props.start), getUserTimezone())}
            Icon={<CalendarIcon />}
          />
        </>
      )}

      {props.location && (
        <a
          href={`https://www.google.com/maps?q=${props.location}`}
          target={'_blank'}
          rel="noopener noreferrer"
          className={'underline '}
        >
          <MeetInfoItem text={props.location} Icon={<MapIcon />} />
        </a>
      )}

      {props.details && (
        <MeetInfoItem text={props.details ?? ''} Icon={<InfoIcon />} />
      )}

      {((props.type === 'now' && props.end) ||
        (props.type === 'later' && props.start && props.end) ||
        props.location ||
        props.details) && <Separator className="bg-white/20 my-6" />}
    </div>
  );
};
