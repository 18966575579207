import React, { useMemo } from 'react';
import { Check } from 'lucide-react';
import ProfileAvatar from 'components/avatar/ProfileAvatar';
import { cn } from 'utils/helpers';
import { Contact } from 'modules/profile/types/types';

type ContactsListProps = {
  userContacts: Contact[];
  selectedContacts: Contact[];
  currUserId: string;
  searchValue: string;
  onContactSelect: (contact: Contact) => void;
  onSearchChange: (value: string) => void;
  show: boolean;
};

const ContactsList: React.FC<ContactsListProps> = (props) => {
  const displayList = useMemo(() => {
    console.log(props.currUserId);
    if (props.searchValue === '') {
      return props.userContacts.filter(
        (contact) => contact.userId !== props.currUserId,
      );
    }

    const searchValue = props.searchValue.toLowerCase().trim();

    return props.userContacts
      .filter((contact) => contact.name.toLowerCase().includes(searchValue))
      .filter((contact) => contact.userId !== props.currUserId);
  }, [props.searchValue, props.currUserId, props.userContacts]);

  return (
    <div
      className={cn(
        'relative',
        (!props.show || displayList.length === 0) && 'hidden',
      )}
    >
      <div
        className={cn(
          'absolute z-20 top-[-0.6rem] bg-main rounded-b-xl w-full border-2 border-solid border-[#ABE49C] max-h-56 h-auto py-2 overflow-auto ',
          props.userContacts.length === 0 && 'overflow-visible text-center',
        )}
      >
        <div className="top-0 left-0 px-4 text-gray-500 text-sm uppercase">
          Choose friends
        </div>
        {props.userContacts.length === 0 ? (
          <div className="text-sm px-2 py-4">
            <div className="font-medium">No contacts added yet.</div>
            <div className="font-light pt-2 opacity-85">
              You can create a public link or import your contacts to Caleido
            </div>
          </div>
        ) : (
          displayList
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((contact, n) => (
              <React.Fragment key={contact._id}>
                <div
                  className={`flex items-center justify-between py-4 px-4 ${
                    props.selectedContacts.some(
                      (selected) => selected._id === contact._id,
                    )
                      ? 'bg-box'
                      : ''
                  }`}
                  onClick={() => props.onContactSelect(contact)}
                >
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center gap-2">
                      <ProfileAvatar
                        size={22}
                        url={`${process.env.REACT_APP_BASE_URL}/users/profile-pic/${contact.userId}`}
                        hideBorder
                      />
                      <div className="flex flex-col text-sm font-medium">
                        {contact.name}
                      </div>
                    </div>
                    {props.selectedContacts.some(
                      (selected) => selected._id === contact._id,
                    ) && <Check className="h-5 w-5" />}
                  </div>
                </div>
              </React.Fragment>
            ))
        )}
      </div>
    </div>
  );
};

export default ContactsList;
