export const DURATION_NOW_OPTIONS = [
  {
    label: '5 m',
    value: '5',
  },
  {
    label: '10 m',
    value: '10',
  },
  {
    label: '15 m',
    value: '15',
  },
  {
    label: '30 m',
    value: '30',
  },
  {
    label: '45 m',
    value: '45',
  },
  {
    label: '1 h',
    value: '60',
  },
  {
    label: '2 h',
    value: '120',
  },
  {
    label: '3 h',
    value: '180',
  },
  {
    label: '4 h',
    value: '240',
  },
  {
    label: '5 h',
    value: '300',
  },
  {
    label: '6 h',
    value: '360',
  },
  {
    label: '7 h',
    value: '420',
  },
  {
    label: '8 h',
    value: '480',
  },
  {
    label: '9 h',
    value: '540',
  },
  {
    label: '10 h',
    value: '600',
  },
  {
    label: '11 h',
    value: '660',
  },
  {
    label: '12 h',
    value: '720',
  },
];
