import React, { useState, useEffect } from 'react';
import { useAuth } from 'modules/auth/context/AuthContextV2';
import { notificationService } from 'services/notification-v2.service';

interface NotificationPromptProps {
  // Time to wait before showing the prompt (in milliseconds)
  delayMs?: number;
  // Optional callback when permission is granted
  onPermissionGranted?: () => void;
}

/**
 * NotificationPrompt - A user-friendly UI component for requesting notification permissions
 * 
 * Shows a banner explaining the benefits of notifications and requests permission
 * only after the user has context and explicitly chooses to enable them.
 */
const NotificationPrompt: React.FC<NotificationPromptProps> = ({
  delayMs = 2000, // Default delay of 2 seconds
  onPermissionGranted
}) => {
  const { user } = useAuth();
  const [showPrompt, setShowPrompt] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);

  useEffect(() => {
    if (!user?._id) return;
    
    let timer: ReturnType<typeof setTimeout>;
    
    const checkPermissionStatus = async () => {
      try {
        // Get current permission state
        const permission = await notificationService.getPermissionStatus();
        
        // Only show for users in 'default' state (haven't made a decision yet)
        if (permission === 'default') {
          // Delay showing the prompt for better UX
          timer = setTimeout(() => {
            setShowPrompt(true);
          }, delayMs);
        }
      } catch (error) {
        console.error('Error checking notification permission:', error);
      }
    };
    
    checkPermissionStatus();
    
    // Clean up timer on unmount
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [user, delayMs]);

  const handleEnableNotifications = async () => {
    if (!user?._id) return;
    
    setIsRequesting(true);
    try {
      const success = await notificationService.requestPermission();
      
      if (success) {
        setShowPrompt(false);
        if (onPermissionGranted) {
          onPermissionGranted();
        }
      }
    } catch (error) {
      console.error('Failed to enable notifications:', error);
    } finally {
      setIsRequesting(false);
    }
  };

  const handleDismiss = () => {
    setShowPrompt(false);
  };

  if (!showPrompt) {
    return null;
  }

  return (
    <div className="fixed bottom-0 left-0 right-0 p-4 bg-white border-t border-gray-200 shadow-lg z-50">
      <div className="container mx-auto max-w-3xl">
        <div className="flex items-center justify-between">
          <div className="flex-1">
            <h3 className="font-medium text-lg">Stay updated with notifications</h3>
            <p className="text-sm text-gray-600">
              Get timely alerts about important updates and activity in your account
            </p>
          </div>
          <div className="flex gap-3 ml-4">
            <button 
              onClick={handleDismiss}
              className="px-4 py-2 text-sm text-gray-600 hover:bg-gray-100 rounded"
              disabled={isRequesting}
            >
              Later
            </button>
            <button
              onClick={handleEnableNotifications}
              className="px-4 py-2 text-sm bg-blue-500 hover:bg-blue-600 text-white rounded"
              disabled={isRequesting}
            >
              {isRequesting ? 'Enabling...' : 'Enable Notifications'}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationPrompt;