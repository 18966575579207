import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { Pencil, RotateCcw, X } from 'lucide-react';
import LetsMeetLayout from 'modules/letsMeet/layouts/LetsMeetLayout';
import { EVENT_ACTIVITIES } from 'utils/constants';
import { Contact } from 'modules/profile/types/types';
import { SquareAutocompleteInput } from 'components/v2/squareAutocompleteInput/SquareAutocompleteInput';
import { SquareMultiSelectInput } from 'components/v2/squareMultiSelectInput/SquareMultiSelectInput';
import EmojiAvatar from 'modules/home/pages/homePage/EmojiAvatar';
import { SquareLocationInput } from 'components/v2/squareLocationInput/SquareLocationInput';
import { Text } from 'components/text/primary/Text';
import Button from 'components/v2/button/Button';
import { Checkbox } from 'components/ui/checkbox';
import { useCreateEventMutation } from 'services/event.service';
import { IPollData } from 'types/event';
import { ReactComponent as CustomEmojiIcon } from 'assets/images/activity/custom-emoji.svg';
import { useAuth } from 'modules/auth/context/AuthContextV2';
import { useGetAvailableContactsQuery } from 'services/event.service';
import CreateEventV2PollGuestsPopup from './CreateEventV2PollGuestsPopup';
import CreateEventV2NowTimePopup from './CreateEventV2NowTimePopup';
import CreateEventV2LaterTimePopup from './CreateEventV2LaterTimePopup';

const CreateEventV2: React.FC = () => {
  const { user } = useAuth();
  const [createEvent, { isLoading }] = useCreateEventMutation();

  const navigate = useNavigate();
  const location = useLocation();

  const { data: availableContacts } = useGetAvailableContactsQuery();

  const [validationError, setValidationError] = useState<{
    activity?: string | null;
    contacts?: string | null;
    location?: string | null;
    time?: string | null;
  }>({ activity: null, contacts: null, location: null, time: null });

  // activity
  const [customEmoji, setCustomEmoji] = useState<string | null>(null);
  const [selectedActivityId, setSelectedActivityId] = useState<string | null>(
    null,
  );

  // contacts
  const [selectedContactsIds, setSelectedContactsIds] = useState<string[]>([]);
  const [userContacts, setUserContacts] = useState<Contact[]>([]);

  // location
  const [selectedLocation, setSelectedLocation] = useState<string | null>(null);
  const [selectedLocationName, setSelectedLocationName] = useState<
    string | null
  >(null);

  // now time
  const [isNowVisible, setIsNowVisible] = useState(false);
  const [selectedTimeOption, setSelectedTimeOption] = useState<string | null>(
    null,
  );
  const [selectedDuration, setSelectedDuration] = useState<string | null>(null);

  // later time
  const [isLaterVisible, setIsLaterVisible] = useState(false);
  const [selectedDatesWithTimeRange, setSelectedDatesWithTimeRange] = useState<
    {
      date: Date;
      start: Date | null;
      end: Date | null;
    }[]
  >([]);

  // poll guests
  const [isPollGuestsVisible, setIsPollGuestsVisible] = useState(false);
  const [selectedDatesWithTime, setSelectedDatesWithTime] = useState<Date[]>(
    [],
  );

  //when combined
  const selectedOptionsToDisplay = useMemo(() => {
    if (selectedTimeOption === 'later') {
      if (selectedDatesWithTimeRange.length === 0) {
        return null;
      }

      return {
        type: 'later',
        dates: selectedDatesWithTimeRange,
      } as const;
    }

    if (selectedDatesWithTime.length === 0) {
      return null;
    }

    return {
      type: 'datesWithTime',
      dates: selectedDatesWithTime,
    } as const;
  }, [selectedTimeOption, selectedDatesWithTime, selectedDatesWithTimeRange]);

  console.log('selectedOptionsToDisplay', selectedOptionsToDisplay);

  // checkbox state
  const [isPublicEvent, setIsPublicEvent] = useState(false);

  const contactsInputLabel = useMemo(() => {
    if (selectedContactsIds.length === 0) {
      return 'With who?';
    }

    if (selectedContactsIds.length === 1) {
      return 'With 1 friend';
    }

    return `With ${selectedContactsIds.length} friends`;
  }, [selectedContactsIds]);

  const selectedContacts = useMemo(
    () =>
      userContacts.filter((contact) =>
        selectedContactsIds.includes(contact.phoneNumber),
      ),
    [userContacts, selectedContactsIds],
  );

  const pollData: IPollData = useMemo(() => {
    return {
      checkedTimes: selectedDatesWithTime.map((time) => ({
        time: `${dayjs(time).format('YYYY-MM-DD')}-${dayjs(time).format('HH:mm')}-${dayjs(
          time,
        )
          .add(parseInt(selectedDuration ?? '0'), 'minutes')
          .format('HH:mm')}`,
        voters: selectedContacts.map((contact) => ({
          name: contact.name,
          userId: contact.userId ?? null,
        })),
      })),
    };
  }, [selectedDatesWithTime, selectedContacts]);

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();

    setValidationError({});

    // Validate only activity
    if (!selectedActivityId) {
      setValidationError((prev) => ({
        ...prev,
        activity: 'Please select an activity.',
      }));
      return;
    }

    const newEvents: Parameters<typeof createEvent>[0][] = [];

    const eventEmoji =
      customEmoji ??
      EVENT_ACTIVITIES.find((activity) => activity.name === selectedActivityId)
        ?.emoji ??
      '📅';

    // If only activity is selected, create event with now type
    if (
      !selectedTimeOption &&
      !selectedLocation &&
      selectedContacts.length === 0
    ) {
      newEvents.push({
        activity: selectedActivityId,
        emoji: eventEmoji,
        location: null,
        contacts: [],
        details: 'Team sync-up',
        publicEvent: false,
        type: 'now',
        start: dayjs().toDate(),
        end: dayjs()
          .add(parseInt(selectedDuration ?? '60'), 'minutes')
          .toDate(),
      });
    } else {
      // poll guests
      if (selectedTimeOption === 'poll' && selectedDatesWithTime.length > 0) {
        newEvents.push({
          activity: selectedActivityId,
          emoji: eventEmoji,
          location: selectedLocationName,
          contacts: selectedContacts,
          details: 'Team sync-up',
          publicEvent: isPublicEvent,
          type: 'poll',
          start: selectedDatesWithTime[0],
          end: null,
          pollData,
        });
      }
      // later with time
      else if (
        selectedTimeOption === 'later' &&
        selectedDatesWithTimeRange.length > 0
      ) {
        selectedDatesWithTimeRange.forEach((date) => {
          newEvents.push({
            activity: selectedActivityId,
            emoji: eventEmoji,
            location: selectedLocationName,
            contacts: selectedContacts,
            details: 'Team sync-up',
            publicEvent: isPublicEvent,
            type: 'later',
            start: date.start,
            end: date.end,
          });
        });
      }
      // now
      else if (selectedTimeOption === 'now') {
        newEvents.push({
          activity: selectedActivityId,
          emoji: eventEmoji,
          location: selectedLocationName,
          contacts: selectedContacts,
          details: 'Team sync-up',
          publicEvent: isPublicEvent,
          type: 'now',
          start: dayjs().toDate(),
          end: selectedDuration
            ? dayjs().add(parseInt(selectedDuration), 'minutes').toDate()
            : null,
        });
      }
    }

    const events = await Promise.all(
      newEvents.map(async (newEvent) => {
        try {
          const response = await createEvent(newEvent);
          const responseId = response.data.message._id;

          return responseId as string;
        } catch (error) {
          console.error('Error creating event:', error);
        }
      }),
    );

    if (events[0]) {
      navigate(`./view/${events[0]}`);
    }
  };

  useEffect(() => {
    if (user && user.contacts) {
      setUserContacts([
        ...(availableContacts?.data.sort((a, b) =>
          a.name.localeCompare(b.name),
        ) ?? []),
        ...user.contacts
          .filter(
            (contact) =>
              !availableContacts?.data.some(
                (availableContact) =>
                  availableContact.phoneNumber === contact.phoneNumber,
              ),
          )
          .sort((a, b) => a.name.localeCompare(b.name)),
      ]);
    }
  }, [user, availableContacts]);

  useEffect(() => {
    if (selectedActivityId) {
      setValidationError((prev) => ({ ...prev, activity: null }));
    }
    if (selectedContactsIds.length > 0 || isPublicEvent) {
      setValidationError((prev) => ({ ...prev, contacts: null }));
    }
    if (selectedLocation) {
      setValidationError((prev) => ({ ...prev, location: null }));
    }
    if (selectedTimeOption) {
      setValidationError((prev) => ({ ...prev, time: null }));
    }
  }, [
    selectedActivityId,
    selectedContactsIds,
    isPublicEvent,
    selectedLocation,
    selectedTimeOption,
  ]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const selectedContactsIdsFromUrl = params.get('selectedContactsIds');
    if (selectedContactsIdsFromUrl) {
      setSelectedContactsIds(selectedContactsIdsFromUrl.split(','));
    }
  }, [location.search]);

  return (
    <LetsMeetLayout
      title="I want to"
      step="create"
      footerText={''}
      actionOnHeaderRight={''}
      widget="menu"
      navigateBackUrl="/home"
      bottomChildren={
        <>
          <Button onClick={handleSubmit} disabled={isLoading}>
            {isLoading ? 'Creating...' : 'Create'}
          </Button>
        </>
      }
    >
      <form className="w-full grid grid-cols-1 gap-6 pt-5 pb-[10px]">
        <SquareAutocompleteInput
          label="What?"
          defaultEmoji={<CustomEmojiIcon className="w-7 h-7" />}
          options={EVENT_ACTIVITIES.map((activity) => ({
            emoji: activity.emoji,
            value: activity.name,
            label: activity.name,
          }))}
          dropdownLabel="CHOOSE ACTIVITY"
          dropdownEmptyLabel="No activities added yet."
          placeholder="Enter activity"
          setSelectedOption={setSelectedActivityId}
          selectedOption={selectedActivityId}
          onCustomEmojiChange={setCustomEmoji}
          error={validationError.activity}
          customOption={{
            type: 'emoji',
            label: 'Set custom',
            emoji: customEmoji ?? <CustomEmojiIcon className="w-7 h-7" />,
          }}
        />
        <div className="flex flex-col gap-2">
          <SquareMultiSelectInput
            label={contactsInputLabel}
            options={
              userContacts.map((contact) => {
                const renderAvatar = (size: 'selected' | 'list') => (
                  <EmojiAvatar
                    fullName={contact.name}
                    size={size}
                    image={`${process.env.REACT_APP_BASE_URL}/users/profile-pic/${contact.userId}`}
                    isOnline={
                      !!contact?.endDate &&
                      dayjs(contact?.endDate).isAfter(dayjs())
                    }
                  />
                );

                return {
                  emoji: renderAvatar('list'),
                  getEmoji: (isSelected: boolean) =>
                    renderAvatar(isSelected ? 'selected' : 'list'),
                  value: contact.phoneNumber,
                  label: contact.name,
                };
              }) ?? []
            }
            setSelectedOptions={setSelectedContactsIds}
            selectedOptions={selectedContactsIds}
            dropdownLabel="CHOOSE CONTACTS"
            dropdownEmptyLabel="No contacts added yet."
            defaultEmoji="🫂"
            placeholder="Search..."
            disabled={isPublicEvent}
            error={validationError.contacts}
          />
          {selectedContactsIds.length === 0 && (
            <div className="flex items-center gap-2">
              <Checkbox
                checked={isPublicEvent}
                onClick={() => {
                  setIsPublicEvent(!isPublicEvent);
                }}
              />
              <Text
                size="small"
                onClick={() => {
                  setIsPublicEvent(!isPublicEvent);
                }}
              >
                My friends aren't on Caleido
              </Text>
            </div>
          )}
        </div>
        <SquareLocationInput
          selectedLocation={selectedLocation}
          setSelectedLocation={setSelectedLocation}
          setSelectedLocationName={setSelectedLocationName}
          error={validationError.location}
        />
        <SquareAutocompleteInput
          label="When?"
          defaultEmoji="🕙"
          options={[
            {
              emoji: '❗',
              value: 'now',
              label: 'Now',
              subLabel:
                selectedDuration && selectedTimeOption === 'now'
                  ? `Duration: ${parseInt(selectedDuration) >= 60 ? `${parseInt(selectedDuration) / 60}h` : `${selectedDuration}m`}`
                  : 'Spontaneous hangouts',
              onClick: () => {
                setIsNowVisible(true);
                setSelectedTimeOption('now');
              },
            },
            {
              emoji: '🗓️',
              value: 'later',
              label: 'Later',
              subLabel: 'Choose a specific time',
              onClick: () => {
                setIsLaterVisible(true);
                setSelectedTimeOption('later');
              },
            },
          ]}
          dropdownLabel="CHOOSE TIME"
          placeholder="Set time"
          isDropdownIcon={true}
          readonly={true}
          dropdownEmptyLabel="No time added yet."
          selectedOption={selectedTimeOption}
          setSelectedOption={(newOption) => {
            if (!newOption) {
              setSelectedDuration(null);
            }

            setSelectedTimeOption(newOption);
          }}
          error={validationError.time}
          inputChildren={
            !!selectedOptionsToDisplay && (
              <>
                <div className="flex flex-col gap-2 p-4">
                  <div className="flex flex-wrap items-center gap-2">
                    {selectedOptionsToDisplay.dates.map((option, index) => {
                      const startDate =
                        option instanceof Date ? option : option.start;
                      const endDate =
                        option instanceof Date ? null : option.end;

                      if (!startDate) {
                        return null;
                      }

                      return (
                        <div
                          className="w-[48%] flex justify-between items-center gap-2 border border-solid border-transparent bg-[#32395E] rounded-full pl-4 pr-3 py-2"
                          key={`${startDate}-${endDate}-${index}`}
                        >
                          <div className="flex flex-col">
                            <Text size="small" className="font-manrope">
                              {dayjs(startDate).format('ddd, MMM D')}
                            </Text>
                            <Text
                              size="small"
                              className="text-[#FFFFFF] opacity-[0.6] font-normal font-manrope"
                            >
                              {dayjs(startDate).format('h:mmA')}
                              {endDate &&
                                ` - ${dayjs(endDate).format('h:mmA')}`}
                            </Text>
                          </div>
                          <div
                            className="cursor-pointer"
                            onClick={() => {
                              if (selectedDatesWithTime.length !== 0) {
                                setSelectedDatesWithTime(
                                  selectedDatesWithTime.filter(
                                    (date) => date !== option,
                                  ),
                                );
                              }
                              if (selectedDatesWithTimeRange.length !== 0) {
                                setSelectedDatesWithTimeRange(
                                  selectedDatesWithTimeRange.filter(
                                    (date) => date !== option,
                                  ),
                                );
                              }
                              if (selectedDatesWithTime.length === 0) {
                                setSelectedTimeOption(null);
                              }
                            }}
                          >
                            <X size={16} className="text-[#ABE49C]" />
                          </div>
                        </div>
                      );
                    })}
                    <Pencil
                      size={20}
                      className="cursor-pointer text-[#ABE49C]"
                      onClick={() => {
                        if (selectedTimeOption === 'poll') {
                          setIsPollGuestsVisible(true);
                        } else if (selectedTimeOption === 'later') {
                          setIsLaterVisible(true);
                        }
                      }}
                    />
                  </div>
                  <div
                    className="flex items-center gap-2 pt-2"
                    onClick={() => {
                      setSelectedDatesWithTime([]);
                      setSelectedDatesWithTimeRange([]);
                      setSelectedDuration(null);
                      setSelectedTimeOption(null);
                    }}
                  >
                    <RotateCcw
                      size={20}
                      className="cursor-pointer text-red-500"
                    />
                    <Text size="small" className="cursor-pointer text-red-500">
                      Reset time
                    </Text>
                  </div>
                </div>
              </>
            )
          }
        />
        {selectedTimeOption !== 'now' &&
        selectedDatesWithTime.length === 0 &&
        selectedTimeOption !== 'later' ? (
          <Text size="small">
            Can't decide when?{' '}
            <Link
              to="/lets-meet"
              className="text-[#D7C0FB] underline"
              onClick={() => {
                setIsPollGuestsVisible(true);
                setSelectedTimeOption('poll');
              }}
            >
              Poll your guests
            </Link>
          </Text>
        ) : undefined}
      </form>

      <CreateEventV2NowTimePopup
        isOpen={isNowVisible}
        onBack={() => {
          setIsNowVisible(false);

          if (!selectedDuration) {
            setSelectedTimeOption(null);
          }
        }}
        onSave={() => {
          setIsNowVisible(false);
        }}
        selectedDuration={selectedDuration}
        setSelectedDuration={setSelectedDuration}
      />

      <CreateEventV2PollGuestsPopup
        isOpen={isPollGuestsVisible}
        onBack={() => {
          setIsPollGuestsVisible(false);

          if (!selectedDatesWithTime.length && !selectedDuration) {
            setSelectedTimeOption(null);
          }
        }}
        onSave={() => {
          setIsPollGuestsVisible(false);
        }}
        selectedDatesWithTime={selectedDatesWithTime}
        setSelectedDatesWithTime={setSelectedDatesWithTime}
        selectedDuration={selectedDuration}
        setSelectedDuration={setSelectedDuration}
      />

      <CreateEventV2LaterTimePopup
        isOpen={isLaterVisible}
        onBack={() => {
          setIsLaterVisible(false);

          if (!selectedDatesWithTime.length) {
            setSelectedTimeOption(null);
          }
        }}
        onSave={() => {
          setIsLaterVisible(false);
        }}
        selectedDatesWithTimeRange={selectedDatesWithTimeRange}
        setSelectedDatesWithTimeRange={setSelectedDatesWithTimeRange}
      />
    </LetsMeetLayout>
  );
};

export default CreateEventV2;
