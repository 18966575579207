import React from 'react';
import { Users } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

type Props = {
  contacts: number;
};

const FriendIconList = ({ contacts }: Props) => {
  const navigate = useNavigate();
  return (
    <div
      className="flex items-center gap-2 px-3 py-[0.375rem] bg-[#32395E] rounded-full"
      onClick={() => navigate('/settings/edit/contacts')}
    >
      <div className="flex items-center justify-center gap-1 bg-[#32395E] rounded-full text-center">
        <Users className="w-5 h-5" />
        <p className="text-[0.875rem] font-medium">{contacts}</p>
      </div>
    </div>
  );
};

export default FriendIconList;
