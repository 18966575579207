import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ChevronRight, ChevronDown } from 'lucide-react';
import { format } from 'date-fns';
import LetsMeetLayout from 'modules/letsMeet/layouts/LetsMeetLayout';
import OverlayLoader from 'components/loader/OverlayLoader';
import { Separator } from 'components/ui/separator';
import { MeetInfo } from 'modules/letsMeet/components/meetInfo/meetInfo';
import InviteList from 'modules/letsMeet/components/inviteList/inviteList';
import AvailableTimes from 'modules/letsMeet/components/meetInfo/availableTimes';
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from 'components/ui/collapsible';
import { toast } from 'components/ui/toast/use-toast';
import MainButton from 'modules/auth/components/MainButton';
import { Text } from 'components/text/primary/Text';
import {
  useAddEventCommentMutation,
  useGetEventQuery,
  useShareEventMutation,
} from 'services/event.service';
import EventIcon from 'components/eventIcon/EventIcon';
import { useAppSelector } from 'hooks/useAppSelector';
import { RootState } from 'app/store';
import { IEvent } from 'types/event';
import CommentList from 'modules/letsMeet/components/comments/commentList';
import { getFormattedSharingText } from 'utils/helpers';
import ViewInviteList from 'modules/letsMeet/components/inviteList/ViewInviteList';
import { useAuth } from 'modules/auth/context/AuthContextV2';

const ShareEvent = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { user} = useAuth();
  const {
    data: eventData,
    refetch: refetchEvent,
    error,
    isLoading,
  } = useGetEventQuery(id || '');
  const [shareEvent] = useShareEventMutation();
  const [event, setCurrentEvent] = useState<IEvent | null>(null);
  const [loading, setLoading] = useState(true);
  const [addCommentMutation] = useAddEventCommentMutation();

  useEffect(() => {
    if (!isLoading && eventData) {
      setCurrentEvent(eventData);
      setLoading(false);
    }
  }, [isLoading, eventData]);

  if (!id) {
    navigate('/home');
    return <></>;
  }

  const inviteeContactList =
    event?.contacts?.filter((contact) => contact.organizer === 0) ?? [];
  const myInvitees =
    event?.contacts?.filter((contact) => contact.organizer === 0) ?? [];

  const onShare = async (link: string) => {
    if (!navigator.share) {
      await navigator?.clipboard?.writeText(link);
      toast({
        title: 'Link copied to clipboard',
      });
    } else {
      await navigator.share({
        title: 'Share this event',
        /*
        text: `Your friend ${event?.organizer} invited you to ${event?.activity}! Click the link to join or learn.`,
        */
        text: getFormattedSharingText(user!, event!, link),
        url: link,
      });
    }
  };

  const handleSubmit = async () => {
    const link = `${process.env.REACT_APP_CLIENT_URL}/share/${event?._id}`;
    await onShare(link);

    if (event?.publicEvent === false) {
      await shareEvent({
        fromName: user?.name.split(' ')[0] ?? '',
        eventName: event?.activity,
        endTime:
          event?.type === 'now'
            ? event?.end
              ? format(new Date(event.end), 'hh:mm a')
              : ''
            : null,
        eventLink: link,
        to: inviteeContactList.map((contact) => contact.phoneNumber) ?? [],
        messageType:
          event?.type === 'now'
            ? 'MEET_NOW_NOTIFICATION'
            : event?.type === 'later'
              ? 'MEET_LATER_NOTIFICATION'
              : event?.type === 'poll'
                ? 'MEET_POLL_NOTIFICATION'
                : '',
      });
    }

    setTimeout(() => {
      navigate('/home');
    }, 3000);
  };

  return (
    <LetsMeetLayout
      source={'share'}
      eventId={id}
      deleteBtn={false}
      widget={'option'}
      actionOnHeaderRight=""
      footerText="Share Invite"
      onSubmit={handleSubmit}
    >
      {isLoading ? (
        <OverlayLoader />
      ) : event ? (
        <>
          <main className="flex-grow mb-4">
            <div className="w-full my-5 mb-6">
              <div className="flex gap-2.5 items-center">
                {event.emoji && <EventIcon icon={event.emoji} />}
                <div>
                  <div className="text-xl font-semibold leading-none">
                    {event.activity}
                  </div>
                  <div className="text-sm font-light">
                    Created by{' '}
                    <span className="font-bold leading-none">{'You'}</span>
                  </div>
                </div>
              </div>
              <MeetInfo {...event} />
              {event.type === 'poll' && event.pollData && (
                <>
                  <AvailableTimes
                    times={
                      event.pollData.checkedTimes.map((item) => item.time) || []
                    }
                    type={event.type}
                  />
                  <Separator className="bg-white/20 my-6" />
                </>
              )}
              {!event.publicEvent && (
                <ViewInviteList list={myInvitees} eventId={event?._id} />
                /*
                <>
                  <InviteList
                    title="Invited"
                    list={inviteeContactList.map((contact) => contact.name)}
                    eventId={event._id}
                    includeAll={true}
                  />
                </>
                */
              )}
              {/* <Collapsible className="w-full">
                <CollapsibleTrigger className="w-full flex justify-center mb-8">
                  Check what everyone is saying <ChevronDown className="ml-3"/>
                </CollapsibleTrigger>
                <CollapsibleContent className="flex justify-center">
                  No comments yet
                </CollapsibleContent>
              </Collapsible> */}
              <CommentList
                event={{ event: event }}
                refetchEvent={refetchEvent}
              />
            </div>
          </main>
          <footer className="pb-6">
            <MainButton
              type="primary"
              onClick={handleSubmit}
              icon={<ChevronRight color="#fff" />}
            >
              <Text size="small">Share invite</Text>
            </MainButton>
          </footer>
        </>
      ) : (
        <p>Error loading event</p>
      )}
    </LetsMeetLayout>
  );
};

ShareEvent.displayName = 'ShareEvent';
export default ShareEvent;
