'use client';
import EventCard from './EventCard';
import { useState, useEffect, useRef } from 'react';
import { useGetAllEventsQuery } from 'services/event.service';
import { cn } from 'utils/helpers';
import dayjs from 'dayjs';
import InfiniteList from './InfiniteList';
import { useNavigate } from 'react-router-dom';
import EmptyEventsList from './EmptyEventsList';
import useUpdates from '../hooks/useUpdates';
import { Loader2 } from 'lucide-react';

const PastEventsSection = () => {
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [events, setEvents] = useState<any[]>([]);

  const { data: pastEvents, isFetching } = useGetAllEventsQuery({
    page,
    history: 'true',
  });

  const { updates } = useUpdates();

  useEffect(() => {
    if (pastEvents) {
      if (pastEvents.length === 0) {
        setHasMore(false);
      }
    }
  }, [pastEvents]);

  return (
    <div>
      <div className="w-full flex justify-between items-center px-5 pb-3">
        <p className="text-[1.125rem] font-bold">
          {events?.length > 0 ? `${events?.length} events` : 'Events'}
        </p>
      </div>
      <div
        className={cn(
          'w-full max-h-[42svh] min-h-[21.75rem] flex flex-col gap-3 overflow-y-scroll',
          updates.length === 0 && 'max-h-[55svh]',
        )}
      >
        <InfiniteList
          onInView={() => {
            if (isFetching || !hasMore) return;

            setPage((prev) => prev + 1);
            setEvents((prev) => [...prev, ...pastEvents]);
          }}
          children={
            <div
              className={cn(
                'w-full flex flex-col justify-center items-center gap-3 overflow-y-scroll px-5 mb-[9rem]',
              )}
            >
              {events.length > 0 ? (
                events
                  .sort((a: any, b: any) => dayjs(a.start).diff(dayjs(b.start)))
                  .map((event: any, index: number) => (
                    <div
                      className="w-full flex"
                      key={index}
                      onClick={() => {
                        navigate(`/lets-meet/view/${event.event._id}`);
                      }}
                    >
                      <EventCard
                        title={event.event.activity}
                        description={event.event.details}
                        time={event.event.start}
                        emoji={event.event.emoji || '📅'}
                        organizerId={event.event.userId}
                        status={event.event.status}
                        organizerName={event.event.organizer}
                      />
                    </div>
                  ))
              ) : (
                <>
                  {!isFetching && pastEvents.length === 0 && (
                    <EmptyEventsList />
                  )}
                  {isFetching && (
                    <div className="flex justify-center items-center">
                      <Loader2 className="w-4 h-4 animate-spin" />
                    </div>
                  )}
                </>
              )}
              {isFetching && (
                <p className="text-center text-gray-500">Loading...</p>
              )}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default PastEventsSection;
