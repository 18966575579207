import { X } from 'lucide-react';
import { ReactNode, useEffect, useMemo, useRef, useState } from 'react';
import { ChevronDown } from 'lucide-react';
import { Text } from 'components/text/primary/Text';
import { useClickOutside } from 'hooks/useClickOutside';
import { cn } from 'utils/helpers';
import { CUSTOM_EMOJI_OPTIONS } from 'utils/constants';

type Option = {
  emoji?: ReactNode;
  value: string;
  label: string;
  subLabel?: string;
  onClick?: () => void;
};

type SquareAutocompleteInputProps = {
  setSelectedOption: (option: string | null) => void;
  selectedOption?: string | null;
  label: string;
  dropdownLabel: string;
  dropdownEmptyLabel: string;
  defaultEmoji?: ReactNode;
  options: Option[];
  placeholder?: string;
  keepInputValue?: boolean;
  onInputValueChange?: (value: string) => void;
  onCustomEmojiChange?: (emoji: string | null) => void;
  size?: 'medium' | 'large';
  isDropdownIcon?: boolean;
  inputChildren?: ReactNode;
  error?: string | null;
  customOption?: {
    type: 'emoji' | 'time';
    label: string;
    emoji?: ReactNode;
    renderInputChildren?: ({ onClear }: { onClear: () => void }) => ReactNode;
    resetOnNullValue?: boolean;
  };
  disabled?: boolean;
  readonly?: boolean;
  editInputValue?: string;
};

export const SquareAutocompleteInput = ({
  label,
  dropdownLabel,
  dropdownEmptyLabel,
  defaultEmoji,
  options,
  isDropdownIcon,
  setSelectedOption,
  selectedOption,
  placeholder,
  keepInputValue = false,
  onInputValueChange,
  size = 'large',
  inputChildren,
  error,
  customOption,
  disabled = false,
  onCustomEmojiChange,
  readonly = false,
  editInputValue,
}: SquareAutocompleteInputProps) => {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);
  const wrapperRef = useClickOutside<HTMLDivElement>(() => {
    setIsFocused(false);

    if (!isCustomOptionSelected && !editInputValue) {
      setInputValue('');
    } else {
      setSelectedOption(inputValue);
    }
  });

  useEffect(() => {
    if (editInputValue) {
      setInputValue(editInputValue);
    }
  }, [editInputValue]);

  const [isCustomOptionSelected, setIsCustomOptionSelected] = useState(false);
  const [isCustomEmojiPopupOpen, setIsCustomEmojiPopupOpen] = useState(false);
  const [customEmoji, setCustomEmoji] = useState<string | null>(null);

  const filteredOptions = useMemo(
    () => [
      ...(customOption
        ? [
            {
              label: customOption.label,
              emoji: customOption.emoji,
              value: 'custom',
              onClick: () => {
                setIsCustomOptionSelected(true);
                setIsFocused(false);

                if (customOption.type === 'emoji') {
                  setSelectedOption(customEmoji);
                  inputRef.current?.focus();
                }
              },
            },
          ]
        : []),
      ...options.filter((option) =>
        option.label.toLowerCase().includes(inputValue.toLowerCase()),
      ),
    ],
    [options, inputValue, customOption],
  );

  const selectedOptionInfo = useMemo(() => {
    return options.find((option) => option.value === selectedOption);
  }, [options, selectedOption, customOption]);

  const handleOptionClick = (option: Option) => {
    setSelectedOption(option.value);
    setIsFocused(false);

    if (!keepInputValue) {
      setInputValue('');
    }
  };

  useEffect(() => {
    onInputValueChange?.(inputValue);
  }, [inputValue, onInputValueChange]);

  useEffect(() => {
    onCustomEmojiChange?.(customEmoji);
  }, [customEmoji, onCustomEmojiChange]);

  useEffect(() => {
    if (customOption?.resetOnNullValue && !selectedOption) {
      setSelectedOption(null);
      setInputValue('');
      setIsFocused(false);
      setIsCustomOptionSelected(false);
      setCustomEmoji(null);
      setIsCustomEmojiPopupOpen(false);
    }
  }, [customOption, selectedOption]);

  const handleClear = () => {
    setSelectedOption(null);
    setInputValue('');
    setIsFocused(false);
    setIsCustomOptionSelected(false);
    setCustomEmoji(null);
    setIsCustomEmojiPopupOpen(false);
  };

  return (
    <div>
      <div className="relative flex flex-col" ref={wrapperRef}>
        <div
          className={cn(
            'w-full border-solid border-2 border-[#D7C0FB] rounded focus-within:border-[#ABE49C] relative duration-300 ease-in-out',
            isFocused && 'rounded-b-none',
          )}
        >
          <div className="absolute top-[-0.750rem] left-5 flex items-center bg-main z-10 justify-start space-x-2 px-1">
            <Text
              size="small"
              className={cn(
                'text-base font-sofia-normal transition-colors duration-300 ease-in-out',
                isFocused ? 'text-[#ABE49C]' : 'text-[#D7C0FB]',
              )}
            >
              {label}
            </Text>
          </div>

          {inputChildren ||
          (isCustomOptionSelected && !!customOption?.renderInputChildren) ? (
            (customOption?.renderInputChildren?.({ onClear: handleClear }) ??
            inputChildren)
          ) : (
            <>
              {(!!selectedOptionInfo?.emoji ||
                !!customOption?.emoji ||
                !!defaultEmoji) && (
                <div
                  className={cn(
                    'absolute top-[50%] -translate-y-1/2 left-6 text-2xl',
                    isCustomOptionSelected
                      ? 'cursor-pointer'
                      : 'pointer-events-none',
                  )}
                  onClick={() => {
                    if (!isCustomOptionSelected) {
                      return;
                    }

                    // Open custom emoji popup
                    setIsCustomEmojiPopupOpen(true);
                  }}
                >
                  {selectedOptionInfo?.emoji ||
                    customOption?.emoji ||
                    defaultEmoji}
                </div>
              )}

              <div className="flex text-2xl gap-3 items-center h-full">
                <input
                  disabled={disabled}
                  className={cn(
                    'w-full bg-transparent border-none outline-none text pr-4 pl-16 text-lg font-sans text-gray-300',
                    'placeholder:text-[#FFFFFF] placeholder:opacity-[0.6]',
                    selectedOptionInfo && !isFocused && 'opacity-0',
                    size === 'medium' && 'py-[18px] px-4',
                    size === 'large' && 'py-[26px]',
                  )}
                  placeholder={placeholder}
                  onFocus={() => setIsFocused(true)}
                  readOnly={readonly}
                  value={inputValue}
                  onChange={(e) => {
                    setInputValue(e.target.value);

                    if (isCustomOptionSelected) {
                      setSelectedOption(e.target.value);
                    }
                  }}
                  ref={inputRef}
                />

                {!isFocused && !!selectedOptionInfo && (
                  <div
                    className={cn(
                      'absolute inset-0 flex flex-col justify-center pr-4 pl-16 text-lg font-sans text-gray-300',
                      size === 'medium' && 'py-[18px] px-4',
                      size === 'large' && 'py-[26px]',
                    )}
                  >
                    <div>{selectedOptionInfo.label}</div>

                    {selectedOptionInfo.subLabel && (
                      <div className="text-sm text-gray-500 pr-8">
                        {selectedOptionInfo.subLabel}
                      </div>
                    )}
                  </div>
                )}
              </div>

              {(!!selectedOption || !!inputValue || isDropdownIcon) && (
                <div
                  onClick={() => {
                    setIsCustomOptionSelected(false);

                    if (size !== 'medium') {
                      handleClear();
                    } else {
                      setIsFocused((prev) => !prev);
                    }
                  }}
                  className={cn(
                    'absolute top-[50%] -translate-y-1/2 right-6 text-2xl cursor-pointer',
                    size === 'medium' && 'right-2',
                    size === 'large' && 'top-[50%]',
                  )}
                >
                  {!selectedOption && size !== 'medium' && (
                    <ChevronDown
                      size={28}
                      className={cn(
                        'transition-all duration-300',
                        isFocused ? 'rotate-0' : 'rotate-180',
                      )}
                    />
                  )}
                  {size === 'medium' && (
                    <ChevronDown
                      size={28}
                      className={cn(
                        'transition-all duration-300',
                        isFocused ? 'rotate-0' : 'rotate-180',
                      )}
                    />
                  )}
                  {selectedOption && size !== 'medium' && (
                    <X
                      size={28}
                      className={cn('transition-colors duration-300')}
                    />
                  )}
                </div>
              )}
            </>
          )}
        </div>

        {isFocused &&
          (!isCustomOptionSelected || customOption?.type === 'time') && (
            <div
              className={cn(
                'absolute z-20 top-[calc(100%-2px)] bg-main rounded-b-xl left-0 right-0 border-2 border-solid h-auto py-2 duration-300 ease-in-out',
                isFocused ? 'border-[#ABE49C]' : 'border-[#D7C0FB]',
              )}
            >
              <p className="text-sm text-gray-500 px-4 mt-2 mb-4 uppercase">
                {dropdownLabel}
              </p>

              {filteredOptions.length === 0 && (
                <div className="text-sm px-4 py-4">
                  <div className="font-medium">{dropdownEmptyLabel}</div>
                </div>
              )}

              {filteredOptions.length > 0 && (
                <div className="flex flex-col gap-1 max-h-[250px] overflow-scroll">
                  {filteredOptions.map((option, index) => (
                    <div
                      className="p-4 cursor-pointer text-md font-sans flex items-center gap-2 hover:bg-[#33335a] duration-300 ease-in-out"
                      key={`${index}-${option.value}`}
                      onClick={() => {
                        if (option.onClick) {
                          return option.onClick();
                        }

                        handleOptionClick(option);
                      }}
                    >
                      {!!option.emoji && (
                        <div className="text-2xl">{option.emoji}</div>
                      )}

                      <div className="flex flex-col gap-1">
                        {option.label}

                        {!!option.subLabel && (
                          <div className="text-sm text-gray-500">
                            {option.subLabel}
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}

        {/* Custom emoji popup */}
        {isCustomEmojiPopupOpen && (
          <div className="absolute top-[calc(100%-2px)] left-0 right-0 z-20 bg-main rounded-b-xl border-2 border-solid border-[#D7C0FB] py-2">
            <div className="flex justify-between items-center mb-4 px-4">
              <Text size="small" className="text-lg font-sofia-normal">
                Select an emoji
              </Text>
              <X
                size={24}
                className="text-[#D7C0FB] cursor-pointer"
                onClick={() => setIsCustomEmojiPopupOpen(false)}
              />
            </div>
            <div className="flex flex-wrap gap-4 px-4">
              {CUSTOM_EMOJI_OPTIONS.map((emoji) => (
                <div
                  key={emoji}
                  className="hover:bg-[#33335a] rounded-lg cursor-pointer text-2xl text-center"
                  onClick={() => {
                    setCustomEmoji(emoji);
                    setIsCustomEmojiPopupOpen(false);
                  }}
                >
                  {emoji}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Error */}
      {error && (
        <div className="text-red-500 text-sm px-2 mt-1 mb-1">{error}</div>
      )}
    </div>
  );
};
