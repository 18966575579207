import { TokenResponse } from 'modules/auth/types/types';

// Backup token keys
const BACKUP_TOKEN_KEY = 'backup_accessToken';
const BACKUP_REFRESH_TOKEN_KEY = 'backup_refreshToken';
const TOKEN_EXPIRY_KEY = 'tokenExpiry';

// Helper to log token operations (can be removed in production)
const logToken = (action: string, data?: any) => {
  console.log(`🔑 Token [${action}]`, data || '');
};

// Check if running as PWA
const isPWA = () => {
  return window.matchMedia('(display-mode: standalone)').matches || 
         (window.navigator as any).standalone === true;
};

// Original methods

const getToken = (): string | null => {
  return sessionStorage.getItem('accessToken');
};

const setToken = (token: string): void => {
  sessionStorage.setItem('accessToken', token);
  
  // Also set expiry time (60 minutes from now)
  const expiryTime = new Date().getTime() + (60 * 60 * 1000);
  localStorage.setItem(TOKEN_EXPIRY_KEY, expiryTime.toString());
  
  // For PWA, also store a backup copy
  if (isPWA() && token) {
    localStorage.setItem(BACKUP_TOKEN_KEY, token);
    logToken('Backup token stored for PWA');
  }
};

const getRefreshToken = (): string | null => {
  return localStorage.getItem('refreshToken');
};

const setRefreshToken = (token: string): void => {
  localStorage.setItem('refreshToken', token);
  
  // For PWA, also store a backup copy
  if (isPWA() && token) {
    localStorage.setItem(BACKUP_REFRESH_TOKEN_KEY, token);
    logToken('Backup refresh token stored for PWA');
  }
};

const clearTokens = (forceComplete = false): void => {
  // For PWA, we'll store backup copies before clearing
  // unless forceComplete is true (for secure logout)
  if (isPWA() && !forceComplete) {
    const currentToken = sessionStorage.getItem('accessToken');
    const currentRefreshToken = localStorage.getItem('refreshToken');
    
    if (currentToken) {
      localStorage.setItem(BACKUP_TOKEN_KEY, currentToken);
    }
    
    if (currentRefreshToken) {
      localStorage.setItem(BACKUP_REFRESH_TOKEN_KEY, currentRefreshToken);
    }
    
    logToken('Tokens backed up for PWA before clearing');
  }
  
  sessionStorage.removeItem('accessToken');
  localStorage.removeItem('refreshToken');
  
  // If forceComplete, also clear backups
  if (forceComplete) {
    localStorage.removeItem(BACKUP_TOKEN_KEY);
    localStorage.removeItem(BACKUP_REFRESH_TOKEN_KEY);
    localStorage.removeItem(TOKEN_EXPIRY_KEY);
    logToken('Backup tokens also cleared (secure logout)');
  }
};

const setTokens = (tokenResponse: TokenResponse): void => {
  setToken(tokenResponse?.access?.token);
  setRefreshToken(tokenResponse?.refresh?.token);
};

// New methods for PWA support and token expiry

// Restore tokens from backup (useful for PWA)
const restoreTokensFromBackup = (): boolean => {
  try {
    const backupToken = localStorage.getItem(BACKUP_TOKEN_KEY);
    const backupRefreshToken = localStorage.getItem(BACKUP_REFRESH_TOKEN_KEY);
    
    let restored = false;
    
    if (backupToken) {
      sessionStorage.setItem('accessToken', backupToken);
      restored = true;
    }
    
    if (backupRefreshToken) {
      localStorage.setItem('refreshToken', backupRefreshToken);
      restored = true;
    }
    
    if (restored) {
      logToken('Tokens restored from backup');
    }
    
    return restored;
  } catch (error) {
    logToken('Error restoring tokens from backup', error);
    return false;
  }
};

// Check if token is expired
const isTokenExpired = (): boolean => {
  try {
    const expiryTimeStr = localStorage.getItem(TOKEN_EXPIRY_KEY);
    if (!expiryTimeStr) return true;
    
    const expiryTime = parseInt(expiryTimeStr, 10);
    const currentTime = new Date().getTime();
    
    // Consider token expired 1 minute before actual expiry
    const isExpired = currentTime > (expiryTime - 60000);
    
    if (isExpired) {
      logToken('Token is expired');
    }
    
    return isExpired;
  } catch (error) {
    logToken('Error checking token expiry', error);
    return true; // Assume expired on error
  }
};

// Get token expiry time in seconds
const getTokenExpiryInSeconds = (): number | null => {
  try {
    const expiryTimeStr = localStorage.getItem(TOKEN_EXPIRY_KEY);
    if (!expiryTimeStr) return null;
    
    const expiryTime = parseInt(expiryTimeStr, 10);
    const currentTime = new Date().getTime();
    
    return Math.max(0, Math.floor((expiryTime - currentTime) / 1000));
  } catch (error) {
    logToken('Error getting token expiry', error);
    return null;
  }
};

// Check if we have valid tokens
const hasValidTokens = (): boolean => {
  const token = getToken();
  const refreshToken = getRefreshToken();
  
  // No tokens
  if (!token && !refreshToken) return false;
  
  // Have refresh token but no access token - potentially valid
  if (!token && refreshToken) return true;
  
  // Have access token - check if expired
  return !isTokenExpired();
};

const tokenUtils = {
  // Original methods
  getToken,
  setToken,
  getRefreshToken,
  setRefreshToken,
  clearTokens,
  setTokens,
  
  // New methods for PWA support
  restoreTokensFromBackup,
  isTokenExpired,
  getTokenExpiryInSeconds,
  hasValidTokens,
};

export default tokenUtils;