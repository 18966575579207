import { cn } from 'utils/helpers';
import EmojiAvatar from './EmojiAvatar';

type Props = {
  images: {
    image: string;
    name: string;
  }[];
  size?: 'small' | 'medium' | 'large';
};

const EmojiStack = ({ images, size = 'medium' }: Props) => {
  return (
    <div className="flex items-center">
      {images?.slice(0, 2).map((image, index) => (
        <div
          key={index}
          className={cn(
            'relative border border-[#2A2B4A] rounded-full',
            index > 0 && 'left-[-0.5rem]',
          )}
        >
          <EmojiAvatar image={image.image} fullName={image.name} size={size} />
        </div>
      ))}
    </div>
  );
};

export default EmojiStack;
