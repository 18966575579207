import { useForm, Controller } from 'react-hook-form';
import { Check, ChevronRight } from 'lucide-react';
import { useEffect, useState } from 'react';
import SettingsLayout from 'modules/settings/layouts/SettingsLayout/SettingsLayout';
import ToggleSwitch from 'components/toggle/ToggleSwitch';
import { Text } from 'components/text/primary/Text';
import MainButton from 'modules/auth/components/MainButton';
import { useAppSelector } from 'hooks/useAppSelector';
import { RootState } from 'app/store';
import {
  getNotificationPermissions,
  updateNotificationPermissions,
} from 'modules/auth/slices/actions';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { toast } from 'components/ui/toast/use-toast';
import { webPushNotifications } from 'utils/webPushNotifications';
import { useAuth } from 'modules/auth/context/AuthContextV2';

type FormValues = {
  calender: boolean;
  event: boolean;
  caleido: boolean;
  text: boolean;
};

const EditNotifications = () => {
  const dispatch = useAppDispatch();
  const notification = useAppSelector(
    (state: RootState) => state.auth.notification,
  );

  const { user } = useAuth();

  // Loading state
  const [loading, setLoading] = useState(false);
  // Push subscription state
  const [isPushEnabled, setIsPushEnabled] = useState(false);

  const { control, handleSubmit, reset } = useForm<FormValues>({
    defaultValues: {
      calender: notification.calender,
      event: notification.event,
      caleido: notification.caleido,
      text: notification.text,
    },
  });

  // Check if push notifications are already enabled
  useEffect(() => {
    const checkPushStatus = async () => {
      try {
        const isSubscribed = await webPushNotifications.checkSubscription();
        setIsPushEnabled(isSubscribed);
      } catch (error) {
        console.error('Error checking push subscription:', error);
        setIsPushEnabled(false);
      }
    };

    checkPushStatus();
  }, []);

  // Handle enabling or disabling push notifications
  const handlePushToggle = async (enabled: boolean) => {
    try {
      setLoading(true);
      
      if (enabled && user?.id) {
        // Enable push notifications
        await webPushNotifications.requestPermission(user.id);
        setIsPushEnabled(true);
        
        toast({
          title: '',
          description: <div>Push notifications enabled successfully!</div>,
          fullscreen: true,
          backgroundColor: '#E0F4DB',
          textColor: '#060606',
          icon: <Check />,
          duration: 5000,
        });
      } else {
        // Disable push notifications
        await webPushNotifications.unsubscribe();
        setIsPushEnabled(false);
        
        toast({
          title: '',
          description: <div>Push notifications disabled</div>,
          fullscreen: true,
          backgroundColor: '#E0F4DB',
          textColor: '#060606',
          icon: <Check />,
          duration: 5000,
        });
      }
    } catch (error) {
      console.error('Error toggling push notifications:', error);
      
      toast({
        title: '',
        description: <div>Failed to update push notification settings</div>,
        fullscreen: true,
        backgroundColor: '#FEE2E2',
        textColor: '#060606',
        duration: 5000,
      });
    } finally {
      setLoading(false);
    }
  };

  const onSubmit = async (formValues: FormValues) => {
    try {
      // Start loading
      setLoading(true);

      if (user && user.id) {
        await dispatch(updateNotificationPermissions(user.id, formValues));

        // Stop loading
        setLoading(false);
        toast({
          title: '',
          description: (
            <div>Notification settings have been successfully saved!</div>
          ),
          fullscreen: true,
          backgroundColor: '#E0F4DB',
          textColor: '#060606',
          icon: <Check />,
          duration: 5000,
        });
      }
    } catch (error) {
      console.error('Error updating notification settings:', error);
      setLoading(false);
      
      toast({
        title: '',
        description: <div>Failed to update notification settings</div>,
        fullscreen: true,
        backgroundColor: '#FEE2E2',
        textColor: '#060606',
        duration: 5000,
      });
    }
  };

  useEffect(() => {
    if (user && user.id) {
      dispatch(getNotificationPermissions(user.id));
    }
  }, [dispatch, user]);

  useEffect(() => {
    if (notification) {
      reset({
        calender: notification.calender,
        event: notification.event,
        caleido: notification.caleido,
        text: notification.text,
      });
    }
  }, [notification, reset]);

  return (
    <SettingsLayout>
      <main className="flex-grow mb-4">
        <div className="w-full my-8 mb-6">
          <Text size="large" className="w-full">
            Notification settings
          </Text>
          <form className="mt-12 space-y-5">
            {/* Push Notifications Toggle */}
            <div className="flex items-center justify-between">
              <Text size="small" className="font-semibold">
                Push Notifications
              </Text>
              <ToggleSwitch
                isChecked={isPushEnabled}
                onChange={(enabled) => !loading && handlePushToggle(enabled)}
                disabled={loading}
              />
            </div>
            
            <div className="flex items-center justify-between">
              <Text size="small" className="font-semibold">
                Calendar Invites
              </Text>
              <Controller
                name="calender"
                control={control}
                render={({ field }) => (
                  <ToggleSwitch
                    isChecked={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
            <div className="flex items-center justify-between">
              <Text size="small" className="font-semibold">
                Event Settings
              </Text>
              <Controller
                name="event"
                control={control}
                render={({ field }) => (
                  <ToggleSwitch
                    isChecked={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
            <div className="flex items-center justify-between">
              <Text size="small" className="font-semibold">
                From Caleido
              </Text>
              <Controller
                name="caleido"
                control={control}
                render={({ field }) => (
                  <ToggleSwitch
                    isChecked={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
            <div className="flex items-center justify-between">
              <Text size="small" className="font-semibold">
                Text notifications
              </Text>
              <Controller
                name="text"
                control={control}
                render={({ field }) => (
                  <ToggleSwitch
                    isChecked={field.value}
                    onChange={field.onChange}
                  />
                )}
              />
            </div>
          </form>
        </div>
      </main>{' '}
      <footer className="mb-4">
        <MainButton
          type="primary"
          icon={loading ? null : <ChevronRight color="#fff" />}
          onClick={handleSubmit(onSubmit)}
          disabled={loading}
        >
          <Text size="small">{loading ? 'Saving...' : 'Save Changes'}</Text>
        </MainButton>
      </footer>
    </SettingsLayout>
  );
};

export default EditNotifications;