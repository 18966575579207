import { createApi } from '@reduxjs/toolkit/query/react';
import baseQueryWithReauth from 'services/mutationInterceptor';
import { GCTokenResponse } from 'types/tokens';

interface eventsResponse {
  profile: string;
  events: any[];
}
export interface AvailabilitySlot {
  start: string;
  end: string;
}

interface AvailabilityResponse {
  date: string;
  times: AvailabilitySlot[];
  timezone: string;
}

interface GetAvailabilitiesRequest {
  dates: string[];
  interval: number;
}

export const calendarApi = createApi({
  reducerPath: 'calendarMutation',
  baseQuery: baseQueryWithReauth,
  tagTypes: ['Availability'],
  endpoints: (builder) => ({
    googleCalAuth: builder.query<string, void>({
      query: () => ({
        url: '/calendar/auth',
        method: 'GET',
      }),
    }),
    googleCalEvents: builder.mutation<eventsResponse, void>({
      // Update this line
      query: () => ({
        url: '/calendar/events',
        method: 'GET',
      }),
    }),
    googleCalTokenCheck: builder.query<GCTokenResponse | null, void>({
      query: () => ({
        url: '/calendar/checkCurrentToken',
        method: 'GET',
      }),
    }),
    getAvailabilities: builder.query<
      AvailabilityResponse[],
      GetAvailabilitiesRequest
    >({
      query: ({ dates, interval }) => ({
        url: `/calendar/availableSlots`,
        method: 'GET',
        params: {
          dates,
          interval,
        },
      }),
      providesTags: ['Availability'],
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          console.log('Query fulfilled:', data);
        } catch (error) {
          console.error('Error in onQueryStarted:', error);
        }
      },
      transformResponse: (response: any) => {
        console.log('response', response);

        return response;
      },
    }),
    unsyncGoogleCalender: builder.mutation<any, {}>({
      query: () => ({
        url: `/calendar/remove`,
        method: 'DELETE',
      }),
    }),
  }),
});

export const {
  useGoogleCalAuthQuery,
  useGoogleCalEventsMutation,
  useGoogleCalTokenCheckQuery,
  useGetAvailabilitiesQuery,
  useUnsyncGoogleCalenderMutation,
} = calendarApi;
