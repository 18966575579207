import { Text } from 'components/text/primary/Text';

type Props = {
  step: number;
  label: string;
  subLabel?: string;
};

const StepLaterDate = ({ step, label, subLabel }: Props) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-2">
        <Text size="small" className="text-[1rem] font-manrope font-normal">
          {`Step ${step}`}
        </Text>
        <Text
          size="small"
          className="text-[1.125rem] font-manrope font-semibold"
        >
          {label}
        </Text>
      </div>
      {subLabel && subLabel !== undefined && (
        <div className="flex items-center">
          <Text
            size="small"
            className="text-[rgb(255,255,255,0.6)] text-[1rem] font-manrope font-normal"
          >
            {subLabel}
          </Text>
        </div>
      )}
    </div>
  );
};

export default StepLaterDate;
