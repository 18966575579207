import { useEffect, useRef } from 'react';

export const useClickOutside = <TElement extends HTMLElement>(
  onOutsideClick: () => void,
  wrapperSelector?: string | null,
  excludedSelectors?: string[],
) => {
  const elementRef = useRef<TElement | null>(null);
  useEffect(() => {
    const handleClickOutside: EventListener = (event: Event) => {
      // check excluded selectors
      const excludedElems = excludedSelectors?.map((selector) =>
        document.querySelector(selector),
      );
      if (excludedElems) {
        for (let i = 0; i < excludedElems.length; i++) {
          if (
            excludedElems[i] &&
            excludedElems[i]?.contains(event.target as Node)
          ) {
            return;
          }
        }
      }
      if (
        elementRef.current &&
        !elementRef.current.contains(event.target as Node)
      ) {
        onOutsideClick();
      }
    };
    if (wrapperSelector) {
      const wrapper = document.querySelector(wrapperSelector);
      if (wrapper) {
        wrapper.addEventListener('mousedown', handleClickOutside);
      } else {
        document.addEventListener('mousedown', handleClickOutside);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      if (wrapperSelector) {
        const wrapper = document.querySelector(wrapperSelector);
        if (wrapper) {
          wrapper.removeEventListener('mousedown', handleClickOutside);
        } else {
          document.removeEventListener('mousedown', handleClickOutside);
        }
      }
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [excludedSelectors, onOutsideClick, wrapperSelector]);
  return elementRef;
};
