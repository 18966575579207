import { ArrowRight, Bell, Loader2 } from 'lucide-react';
import { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useAppSelector } from 'hooks/useAppSelector';
import { RootState } from 'app/store';
import { Switch } from 'components/ui/switch';
import CalendarButton from 'modules/home/pages/homePage/CalendarButton';
import {
  useDeleteTimeOffMutation,
  useGetAllEventsQuery,
  useGetPendingEventsQuery,
} from 'services/event.service';
import { useApiQuery } from 'hooks/axiosHooks';
import { cn } from 'utils/helpers';
import { useAuth } from 'modules/auth/context/AuthContextV2';
import { EventStatus } from 'utils/constants';
import { FILTER_OPTIONS } from 'modules/home/utils/constants';
import Header from './Header';
import EmojiAvatar from './EmojiAvatar';
import FriendIconList from './FriendIconList';
import EventsMenu from './EventsMenu';
import UpcomingEventsSection from './UpcomingEventsSection';
import DraftsEventsSection from './DraftsEventsSection';
import HostingEventsSection from './HostingEventsSection';
import PastEventsSection from './PastEventsSection';
import AvailabilityPopup from './AvailabilityPopup';
import Updates from './Updates';
import UpdatesPopup from './UpdatesPopup';
import useUpdates from '../hooks/useUpdates';
import BottomNav from 'components/bottomNav/bottomNav';

const EVENT_TYPES = [
  {
    label: 'Upcoming',
    value: 'upcoming',
  },
  {
    label: 'Hosting',
    value: 'hosting',
  },
  {
    label: 'Past Events',
    value: 'past-events',
  },
  {
    label: 'Drafts',
    value: 'drafts',
  },
];

const HomePage = () => {
  const navigate = useNavigate();

  const [isOpenAvailability, setIsOpenAvailability] = useState<boolean>(false);
  const [isOpenUpdates, setIsOpenUpdates] = useState<boolean>(false);
  const [isAvailabilityChecked, setIsAvailabilityChecked] =
    useState<boolean>(false);
  const [selectedAvailabilityDuration, setSelectedAvailabilityDuration] =
    useState<string | null>(null);

  const { user } = useAuth();

  const [eventType, setEventType] = useState<string>('upcoming');
  const [sortedEvents, setSortedEvents] = useState<{
    label: string;
    value: string;
  }>({ label: 'Most Recent', value: 'most-recent' });

  const { updates, handleDismiss } = useUpdates();

  // --- Time off ---
  const timeOffParams = useMemo(
    () => ({
      startDate: dayjs().toISOString(),
      endDate: dayjs().add(1, 'day').toISOString(),
    }),
    [],
  );

  const [deleteTimeOff] = useDeleteTimeOffMutation();

  const { data: timeOff, refetch } = useApiQuery<{
    message: string;
    timeOffRecords: {
      _id: string;
      userId: string;
      gCalendarEventId: string;
      start: string;
      end: string;
      timestamp: string;
      title: string;
    }[];
  }>(
    `/time-off?startDate=${timeOffParams.startDate}&endDate=${timeOffParams.endDate}`,
  );

  const { currentTimeOff, availabilityUntilDate } = useMemo(() => {
    const currentTimeOff = timeOff?.timeOffRecords.find((timeOff) => {
      return (
        dayjs(timeOff.start).isBefore(dayjs()) &&
        dayjs(timeOff.end).isAfter(dayjs())
      );
    });

    const availabilityUntilDate =
      !currentTimeOff && selectedAvailabilityDuration
        ? dayjs().add(parseInt(selectedAvailabilityDuration), 'minutes')
        : dayjs(currentTimeOff?.end);

    return { currentTimeOff, availabilityUntilDate };
  }, [timeOff, selectedAvailabilityDuration]);

  useEffect(() => {
    if (currentTimeOff) {
      setIsAvailabilityChecked(true);
      setSelectedAvailabilityDuration(
        dayjs(currentTimeOff.end).diff(dayjs(), 'minutes').toString(),
      );
    }
  }, [currentTimeOff]);

  useEffect(() => {
    if (selectedAvailabilityDuration) {
      refetch();
    }
  }, [selectedAvailabilityDuration]);

  if (!user) {
    return (
      <div className="text-white flex items-center justify-center h-screen">
        <Loader2 className="w-4 h-4 animate-spin" />
      </div>
    );
  }

  return (
    <div className="relative w-full h-full flex justify-center items-center max-w-[100svw] overflow-x-hidden">
      <div className="relative w-full h-full flex flex-col gap-5">
        <div className="mt-6">
          <Header />
        </div>
        <div className="flex justify-between items-center px-5">
          <div className="relative flex items-end gap-2">
            <div className="flex items-center gap-2 font-manrope">
              <div className="relative">
                <EmojiAvatar
                  image={user.photoURL}
                  fullName={user?.name}
                  isOnline={isAvailabilityChecked}
                />
              </div>
              <div className="flex flex-col">
                <p className="text-[0.875rem] font-semibold">{user?.name}</p>
                <p className="text-[0.875rem] font-light opacity-80">
                  @{user?.username}
                </p>
              </div>
            </div>

            <Switch
              checked={isAvailabilityChecked}
              onCheckedChange={() => {
                if (isAvailabilityChecked) {
                  setIsOpenAvailability(false);
                  setSelectedAvailabilityDuration(null);
                  if (currentTimeOff) {
                    deleteTimeOff({ id: currentTimeOff._id });
                  }
                } else {
                  setIsOpenAvailability(true);
                }
                setIsAvailabilityChecked((prev) => !prev);
              }}
            />
            {(!selectedAvailabilityDuration || !isAvailabilityChecked) && (
              <p className="text-[0.875rem] font-medium">Free now?</p>
            )}
            {availabilityUntilDate && isAvailabilityChecked && (
              <div className="w-full absolute bottom-[-1.1rem] left-[27%] text-xs text-[#ABE49C]">
                {`Available until ${availabilityUntilDate.format(
                  availabilityUntilDate.minute() === 0 ? 'ha' : 'h:mma',
                )}`}
              </div>
            )}
          </div>
          <FriendIconList contacts={user?.contacts?.length || 0} />
        </div>
        <div
          className={cn(
            'w-full flex flex-col gap-5',
            updates.length === 0 && 'gap-0',
          )}
        >
          <div className="flex flex-col justify-center items-center gap-3 px-5">
            <div className="w-full flex items-center justify-between z-10 ">
              <p
                className="text-[1.125rem] font-semibold"
                onClick={() => {
                  setIsOpenUpdates(true);
                }}
              >
                {updates.length > 0 ? `${updates.length} Updates` : 'Updates'}
              </p>
              <p>
                <ArrowRight
                  className="w-4 h-4"
                  onClick={() => {
                    setIsOpenUpdates(true);
                  }}
                />
              </p>
            </div>

            <div className="w-full h-full flex justify-center items-center">
              <Updates updates={updates} handleDismiss={handleDismiss} />
            </div>
          </div>
          <div
            className={cn(
              'w-full flex flex-col mt-5',
              updates.length === 0 && 'mt-0',
            )}
          >
            <EventsMenu
              eventOptions={EVENT_TYPES}
              eventType={eventType}
              setEventType={setEventType}
            />
          </div>
        </div>
        <div
          className={cn(
            'w-full h-full flex flex-col gap-3 mb-0',
            updates.length === 0 && 'mb-[3.2rem]',
          )}
        >
          {eventType === 'upcoming' && (
            <UpcomingEventsSection
              sortOptions={FILTER_OPTIONS}
              sortedEvents={sortedEvents}
              setSortedEvents={setSortedEvents}
            />
          )}
          {eventType === 'hosting' && <HostingEventsSection />}
          {eventType === 'past-events' && <PastEventsSection />}
          {eventType === 'drafts' && <DraftsEventsSection />}{' '}
        </div>
      </div>
      <BottomNav />
      <UpdatesPopup
        isOpen={isOpenUpdates}
        setIsOpen={setIsOpenUpdates}
        updates={updates}
        handleDismiss={handleDismiss}
      />
      <AvailabilityPopup
        isOpen={isOpenAvailability}
        onClose={() => {
          setIsAvailabilityChecked(false);
          setIsOpenAvailability(false);
          setSelectedAvailabilityDuration(null);
        }}
        onSave={(selectedDuration) => {
          setIsOpenAvailability(false);
          setSelectedAvailabilityDuration(selectedDuration);
        }}
      />
    </div>
  );
};

export default HomePage;
