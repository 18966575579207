import React from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'components/v2/button/Button';
import { cn } from 'utils/helpers';
import UpdatesCard from '../UpdatesCard';

type Props = {
  className?: string;
  handleDismiss: (eventId: string, expiresAt?: Date) => void;
  isShadows?: boolean;
};

const CalendarCard = ({
  handleDismiss,
  isShadows = true,
  className,
}: Props) => {
  const navigate = useNavigate();

  return (
    <>
      {isShadows && (
        <div className="absolute bg-transparent w-full h-full shadow-[0px_50px_110px_-61px_#AAA8FF] rounded-[6rem] z-[-1]"></div>
      )}
      <UpdatesCard
        title="Sync your calendar"
        description="Allows you to import your Google Calendar time blocks and push Caleido invites!"
        emoji="🗓️"
        className={cn('shadow-none', className)}
        actionsChildren={
          <>
            <Button
              className="min-w-[65%] py-3"
              variant="primary"
              onClick={() => {
                navigate('/settings/edit/availability');
              }}
            >
              Sync Google Calendar
            </Button>
            <Button
              variant="secondary"
              className="py-3"
              onClick={() => {
                handleDismiss('calendar');
              }}
            >
              Dismiss
            </Button>
          </>
        }
      />
    </>
  );
};

export default CalendarCard;
