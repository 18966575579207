// notification.service.ts
import { webPushNotifications } from 'utils/webPushNotifications';
import { isPWA } from 'utils/isPWA';
import apiClient from 'services/apiClient';

// Cache keys
const SETUP_CACHE_KEY = 'notification_setup_cache';
const PWA_SETTINGS_CACHE_KEY = 'pwa_settings_updated';

// Permission status type alias
type PermissionStatus = 'granted' | 'denied' | 'default' | 'unsupported';

/**
 * NotificationService - Manages notification permissions, subscriptions,
 * and related functionality in a clean, class-based structure.
 */
class NotificationService {
  private userId: string | null = null;
  private initialized = false;

  /**
   * Logs notification-related events with consistent formatting
   */
  private log(action: string, data?: any): void {
    // console.log(`📱 Notifications [${action}]`, data || '');
  }

  /**
   * Checks if the browser supports notifications
   */
  private supportsNotifications(): boolean {
    return 'Notification' in window;
  }

  /**
   * Checks if the browser supports push notifications
   */
  private supportsPushNotifications(): boolean {
    return (
      this.supportsNotifications() &&
      'serviceWorker' in navigator &&
      'PushManager' in window
    );
  }

  /**
   * Retrieves the current permissions status
   */
  async getPermissionStatus(): Promise<PermissionStatus> {
    if (!this.supportsNotifications()) {
      return 'unsupported';
    }
    return Notification.permission as PermissionStatus;
  }

  /**
   * Checks if notification setup is cached for the user
   */
  async isSetupCached(userId: string): Promise<boolean> {
    try {
      const cache = sessionStorage.getItem(SETUP_CACHE_KEY);
      if (!cache) return false;

      const cacheData = JSON.parse(cache);
      const isCacheValid =
        cacheData.userId === userId &&
        cacheData.timestamp > Date.now() - 1000 * 60 * 60; // 1 hour cache

      // Early return if cache is invalid
      if (!isCacheValid) return false;

      // Only validate subscription if cache is valid
      const isSubscriptionValid =
        await webPushNotifications.checkSubscription();

      this.log('Cache & Subscription Check', {
        userId,
        isCacheValid,
        isSubscriptionValid,
        cacheAge: cacheData.timestamp
          ? Math.round((Date.now() - cacheData.timestamp) / 1000) + 's'
          : 'none',
      });

      return isSubscriptionValid;
    } catch (error) {
      this.log('Cache Check Failed', error);
      return false;
    }
  }

  /**
   * Updates the setup cache after successful operations
   */
  private updateSetupCache(userId: string): void {
    try {
      sessionStorage.setItem(
        SETUP_CACHE_KEY,
        JSON.stringify({
          userId,
          timestamp: Date.now(),
        }),
      );
      this.log('Cache Updated', { userId });
    } catch (error) {
      this.log('Cache Update Failed', error);
    }
  }

  /**
   * Updates PWA mode settings if running as PWA
   */
  private async updatePWAMode(): Promise<boolean> {
    if (!isPWA()) return false;

    try {
      const pwaUpdated = sessionStorage.getItem(PWA_SETTINGS_CACHE_KEY);
      if (pwaUpdated) return true;

      await apiClient.patch('/users/settings', { usingPWAMode: true });
      sessionStorage.setItem(PWA_SETTINGS_CACHE_KEY, 'true');
      this.log('PWA Mode Updated');
      return true;
    } catch (error) {
      this.log('PWA Mode Update Failed', error);
      return false;
    }
  }

  /**
   * Initializes the notification system
   * - Sets up push notification infrastructure
   * - Does NOT request permission (that happens separately with user context)
   * - Updates PWA mode if applicable
   */
  async initialize(userId: string): Promise<boolean> {
    if (!userId) {
      this.log('Initialization Failed', 'No userId provided');
      return false;
    }

    this.userId = userId;
    this.log('Initializing Notification System', { userId });

    // Clear any existing permission cache to ensure we check fresh for new users
    const isNewSession = !sessionStorage.getItem('session_started');
    if (isNewSession) {
      this.log('New session detected, clearing notification cache');
      this.clearCache();
      sessionStorage.setItem('session_started', 'true');
    }

    // Check if already initialized with valid cache
    if (this.initialized || (await this.isSetupCached(userId))) {
      this.log('Already Initialized', { userId });
      return true;
    }

    try {
      // Check if notifications are supported
      if (!this.supportsNotifications()) {
        this.log('Notifications Not Supported');
        return false;
      }

      // Initialize push notification system if supported
      if (this.supportsPushNotifications()) {
        try {
          await webPushNotifications.init();
          this.log('Push Notification System Initialized');
        } catch (error) {
          this.log('Push Notification Init Failed', error);
          // Continue execution even if this fails
        }
      }

      // Check current permission status
      const permission = await this.getPermissionStatus();
      this.log('Current Permission Status', { permission });

      // If permission already granted, ensure subscription is valid
      if (permission === 'granted') {
        const hasSubscription = await webPushNotifications.checkSubscription();
        if (!hasSubscription) {
          try {
            const subscription =
              await webPushNotifications.requestPermission(userId);
            this.log('Subscription Creation Result', {
              success: !!subscription,
            });
          } catch (error) {
            this.log('Subscription Creation Failed', error);
            // Continue execution even if this fails
          }
        }
      }

      // Update PWA mode if applicable
      await this.updatePWAMode();

      // Mark as initialized but don't update cache for new users
      // This ensures the prompt can show without refresh
      this.initialized = true;

      // Only update cache for users who have made a decision
      if (permission !== 'default') {
        this.updateSetupCache(userId);
      }

      this.log('Initialization Complete', {
        userId,
        permissionStatus: permission,
      });
      return true;
    } catch (error) {
      this.log('Initialization Failed', error);
      return false;
    }
  }

  /**
   * Explicitly requests notification permission with user context
   * Should be called after user interaction (e.g., clicking a button)
   */
  async requestPermission(): Promise<boolean> {
    if (!this.userId) {
      this.log('Permission Request Failed', 'No userId set');
      return false;
    }

    if (!this.supportsNotifications()) {
      this.log('Notifications Not Supported');
      return false;
    }

    this.log('Requesting Permission', { userId: this.userId });

    try {
      // Ensure system is initialized
      if (!this.initialized) {
        await this.initialize(this.userId);
      }

      // Get current permission status
      const permission = await this.getPermissionStatus();

      // If already denied, we can't do anything
      if (permission === 'denied') {
        this.log('Permission Already Denied');
        return false;
      }

      // Request permission and create subscription
      const subscription = await webPushNotifications.requestPermission(
        this.userId,
      );
      const success = !!subscription;

      if (success) {
        this.log('Permission Granted & Subscription Created');
        this.updateSetupCache(this.userId);
      } else {
        this.log('Failed to Create Subscription');
      }

      return success;
    } catch (error) {
      this.log('Permission Request Failed', error);
      return false;
    }
  }

  /**
   * Unsubscribes from push notifications
   */
  async unsubscribe(): Promise<boolean> {
    this.log('Unsubscribing');
    try {
      await webPushNotifications.unsubscribe();
      sessionStorage.removeItem(SETUP_CACHE_KEY);
      this.log('Unsubscribe Success');
      return true;
    } catch (error) {
      this.log('Unsubscribe Failed', error);
      return false;
    }
  }

  /**
   * Sends a test notification
   */
  async sendTestNotification(
    userId: string = this.userId || '',
  ): Promise<boolean> {
    if (!userId) {
      this.log('Test Failed', 'No userId provided');
      return false;
    }

    this.log('Sending Test', { userId });
    try {
      await webPushNotifications.sendTestNotification(userId);
      this.log('Test Sent Successfully');
      return true;
    } catch (error) {
      this.log('Test Failed', error);
      return false;
    }
  }

  /**
   * Clears all notification-related caches
   */
  clearCache(): void {
    sessionStorage.removeItem(SETUP_CACHE_KEY);
    sessionStorage.removeItem(PWA_SETTINGS_CACHE_KEY);
    this.log('Cache Cleared');
  }
}

// Export a singleton instance
export const notificationService = new NotificationService();
