import React, { useCallback, useEffect, useRef, useState } from 'react';
import SquareInput from 'components/squareInput/SquareInput';

interface PlacePrediction {
  description: string;
  place_id: string;
}

type CitySuggestionProps = {
  setSelectedLocation: (location: string) => void;
  locationMenuVisible: boolean;
  error: string;
  location: string | null;
};

declare global {
  namespace google {
    namespace maps {
      namespace places {
        class AutocompleteService {
          getPlacePredictions: (
            request: AutocompleteRequest,
            callback: (
              result: PlaceResult[],
              status: PlacesServiceStatus,
            ) => void,
          ) => void;
        }

        interface AutocompleteRequest {
          input: string;
          types: string[];
        }

        interface PlaceResult {
          description: string;
          place_id: string;
        }

        enum PlacesServiceStatus {
          OK,
        }
      }
    }
  }
}

const CitySuggestion: React.FC<CitySuggestionProps> = ({
  setSelectedLocation,
  locationMenuVisible,
  error,
  location,
}) => {
  const [suggestions, setSuggestions] = useState<PlacePrediction[]>([]);
  const [inputValue, setInputValue] = useState<string>('');
  const autocompleteServiceRef =
    useRef<google.maps.places.AutocompleteService | null>(null);
  const [isVisible, setIsVisible] = useState(false);
  const suggestionsRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const loadScript = (url: string, callback: () => void) => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.onload = callback;
      document.body.appendChild(script);
    };

    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`,
      () => {
        autocompleteServiceRef.current =
          new window.google.maps.places.AutocompleteService();
      },
    );
  }, []);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const value = event.target.value;
      setInputValue(value);
      setSelectedLocation(value);

      if (value) {
        autocompleteServiceRef.current?.getPlacePredictions(
          { input: value, types: ['establishment', 'geocode'] },
          (predictions, status) => {
            if (
              status === window.google.maps.places.PlacesServiceStatus.OK &&
              predictions
            ) {
              setIsVisible(true);
              setSuggestions(predictions);
            } else {
              setIsVisible(false);
              setSuggestions([]);
            }
          },
        );
      } else {
        setIsVisible(false);
        setSuggestions([]);
      }
    },
    [],
  );

  const handleSuggestionClick = useCallback(
    (suggestion: PlacePrediction) => {
      setIsVisible(false);
      setSelectedLocation(suggestion.description);
      setInputValue(suggestion.description);
      setSuggestions([]);
    },
    [setSelectedLocation],
  );

  useEffect(() => {
    if (!inputValue) {
      setIsVisible(false);
    }
  }, [locationMenuVisible, inputValue]);

  useEffect(() => {
    setInputValue(location || '');
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        suggestionsRef.current &&
        !suggestionsRef.current.contains(event.target as Node)
      ) {
        setIsVisible(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      <SquareInput
        emoji="📍"
        label="Where?"
        placeholder="Enter location"
        value={inputValue}
        onClear={() => {
          setInputValue('');
          setSelectedLocation('');
        }}
        onChange={handleInputChange}
        required={false}
        error={error}
        onClick={() => setIsVisible(true)} // Show suggestions on input click
      />
      {isVisible && (
        <div className="relative" id="g-map-suggestions" ref={suggestionsRef}>
          <div className="absolute z-20 top-[-0.6rem] bg-main rounded-b-xl w-full border-2 border-solid border-[#ABE49C] max-h-56 h-auto py-2 overflow-auto ">
            <div className="top-0 left-0 px-4 text-gray-500 text-sm uppercase">
              Choose location
            </div>
            {inputValue === '' ? (
              <>
                <div
                  className="px-4 py-3 cursor-pointer"
                  onClick={() => setSelectedLocation('Home')}
                >
                  <p className="text-sm">🏠 Set Home</p>
                </div>
                <div
                  className="px-4 py-3 cursor-pointer"
                  onClick={() => setSelectedLocation('School')}
                >
                  <p className="text-sm">🏫Set School</p>
                </div>
                <div
                  className="px-4 py-3 cursor-pointer"
                  onClick={() => setSelectedLocation('School')}
                >
                  <p className="text-sm text-[#ABE49C]">+ Add saved location</p>
                </div>
              </>
            ) : null}
            {suggestions.map((suggestion) => (
              <div
                className="px-4 cursor-pointer"
                key={suggestion.place_id}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                <p className="text-sm py-3">{suggestion.description}</p>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default CitySuggestion;
