import { createPortal } from 'react-dom';
import { cn } from 'utils/helpers';
import WheelSelector from 'components/v2/wheelSelector/WheelSelector';
import Button from 'components/v2/button/Button';
import { DURATION_NOW_OPTIONS } from 'modules/letsMeet/utils/constants';
import LetsMeetLayout from '../../layouts/LetsMeetLayout';

type Props = {
  isOpen: boolean;
  onBack: () => void;
  onSave: () => void;
  selectedDuration: string | null;
  setSelectedDuration: (duration: string | null) => void;
};

const CreateEventV2NowTimePopup = ({
  isOpen,
  onBack,
  onSave,
  selectedDuration,
  setSelectedDuration,
}: Props) => {
  return createPortal(
    <div
      className={cn(
        'fixed inset-0 z-50 duration-300 ease-in-out',
        isOpen
          ? 'opacity-100 pointer-events-auto'
          : 'opacity-0 pointer-events-none',
      )}
    >
      <LetsMeetLayout
        title="Set now time"
        step="create"
        footerText={''}
        actionOnHeaderRight={''}
        widget="menu"
        handleGoBack={onBack}
        bottomChildren={<Button onClick={onSave}>Save</Button>}
      >
        <div className="mt-[148px]">
          <WheelSelector
            label="How long?"
            options={DURATION_NOW_OPTIONS}
            selectedOption={selectedDuration}
            onChange={setSelectedDuration}
          />
        </div>
      </LetsMeetLayout>
    </div>,
    document.body,
  );
};

export default CreateEventV2NowTimePopup;
