import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { cn } from 'utils/helpers';
import { useAuth } from 'modules/auth/context/AuthContextV2';

interface EmojiAvatarProps {
  image?: string | null;
  fullName: string;
  size?: 'small' | 'medium' | 'medium-large' | 'large' | 'selected' | 'list';
  className?: string;
  isOnline?: boolean;
}

const EmojiAvatar: React.FC<EmojiAvatarProps> = ({
  image,
  fullName,
  size = 'medium',
  className,
  isOnline = false,
}) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [isError, setIsError] = useState<boolean>(false);

  return (
    <div
      className={cn(
        'relative flex items-center justify-center bg-[#32395E] rounded-full text-center cursor-pointer',
        size === 'large' && 'w-[3.5rem] h-[3.5rem]',
        size === 'small' && 'w-[1.25rem] h-[1.25rem]',
        size === 'medium' && 'w-[2.375rem] h-[2.375rem]',
        size === 'medium-large' && 'w-[2.875rem] h-[2.875rem]',
        size === 'selected' && 'w-[1.25rem] h-[1.25rem]',
        size === 'list' && 'w-[1.5rem] h-[1.5rem]',
        className,
      )}
      onClick={() => {
        if (user?.name === fullName) {
          navigate('/settings');
        }
      }}
    >
      {image && !isError ? (
        <img
          src={image}
          alt={fullName}
          onError={() => {
            setIsError(true);
          }}
          className={cn(
            'rounded-full',
            size === 'large' && 'w-[3.5rem] h-[3.5rem]',
            size === 'small' && 'w-[1.25rem] h-[1.25rem]',
            size === 'medium' && 'w-[2.375rem] h-[2.375rem]',
            size === 'medium-large' && 'w-[2.875rem] h-[2.875rem]',
            size === 'selected' && 'w-[1.25rem] h-[1.25rem]',
            size === 'list' && 'w-[1.5rem] h-[1.5rem]',
            className,
          )}
        />
      ) : (
        <p
          className={cn(
            'font-medium uppercase',
            size === 'large' && 'text-[1.75rem] font-normal',
            size === 'small' && 'text-[0.625rem] font-light',
            size === 'medium' && 'text-[1.25rem] font-normal',
            size === 'medium-large' && 'text-[1.5rem] font-normal',
            size === 'selected' && 'text-[0.625rem] font-light',
            size === 'list' && 'text-[0.75rem] font-normal',
          )}
        >
          {`${fullName
            .split(' ')
            .slice(0, 2)
            .map((name) => name.charAt(0))
            .join('')}`}
        </p>
      )}
      {isOnline && (
        <div className="absolute bottom-0 right-0 w-3 h-3 bg-[#ABE49C] border border-[#110E2B] rounded-full"></div>
      )}
    </div>
  );
};

export default EmojiAvatar;
