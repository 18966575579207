import { format, formatDistanceToNow } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';
import dayjs from 'dayjs';
import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import { Separator } from 'components/ui/separator';
import CommentList from 'modules/letsMeet/components/comments/commentList';
import InviteList from 'modules/letsMeet/components/inviteList/inviteList';
import { MeetInfo } from 'modules/letsMeet/components/meetInfo/meetInfo';
import PublicResponseDrawer from 'modules/letsMeet/components/meetResponse/publicResponseDrawer';
import { EventTitle } from 'modules/letsMeet/components/receive/eventTitle';
import ReceiveHeader from 'modules/letsMeet/components/receive/receiveHeader';
import { cn, getFormattedSharingText } from 'utils/helpers';
import useLocalStorage from 'hooks/useLocalStorage';
import useSessionStorage from 'hooks/useSessionStorage';
import { IContact, IEvent, IPollData } from 'types/event';
import {
  EventContactIds,
  EventResponses,
  EventType,
  ReceiveMeetResponseState,
  ReceiveMeetResponseType,
  ReceivePublicMeetResponseState,
} from 'modules/letsMeet/types/types';
import ModernActivityHeader from 'modules/activity/components/modernHeader';
import EventOrganizerHeader from 'modules/letsMeet/components/receive/eventOrganizerHeader';
import HorizontalInviteList from 'modules/letsMeet/components/inviteList/HorizontalInviteList';
import EventCard from 'modules/letsMeet/components/receive/eventCard';
import { useRespondEventMutation } from 'services/event.service';
import { IActivity } from 'types/activity';
import { useCreateActivityPublicMutation } from 'services/activity.service';
import { getUserPhotosForEvent } from 'modules/letsMeet/utils/user';
import { toast } from 'components/ui/toast/use-toast';
import PollTimeSelector from 'modules/letsMeet/components/meetResponse/pollTimeSelector';
import caleidoBaseApi from 'services/apiClient';
import PublicDrawer from './publicDrawer';
import { isPastEvent } from 'modules/letsMeet/utils/utils';

interface ReceiveMeetProps {
  event: IEvent | null;
  refetch?: () => void;
  type: 'now' | 'later' | 'poll';
}

const ReceivePublicMeet = ({ event, refetch, type }: ReceiveMeetProps) => {
  // State
  const [state, setState] = useState(ReceiveMeetResponseState.HIDDEN);
  const [response, setResponse] = useState<ReceiveMeetResponseType | undefined>(
    undefined,
  );
  const [pastEvent, setPastEvent] = useState<boolean>(false);
  const [organizerPhoto, setOrganizerPhoto] = useState<string>(
    event?.organizerPhoto ?? '',
  );
  const [userPhotos, setUserPhotos] = useState<{
    [key: string]: { photoURL: string; name: string };
  }>({});
  const [eventActivities, setEventActivities] = useState<any[]>([]);
  const [attendingInvitees, setAttendingInvitees] = useState<string[]>([]);
  const [eventContacts, setEventContacts] = useState<IContact[]>(
    event?.contacts ?? [],
  );
  const [createActivity] = useCreateActivityPublicMutation();
  // Storage hooks
  const [eventResponses, setEventResponses] = useLocalStorage(
    'cal-user-event-responses',
    {},
  );
  const [eventContactIds, setEventContactIds] = useLocalStorage(
    'cal-user-event-contact-ids',
    {},
  );
  const [validatedNumbers] = useLocalStorage(
    'cal-user-otp-validated-number',
    [],
  );

  const [phoneNumber, setPhoneNumber] = useSessionStorage(
    'cal-user-phone-number',
    '',
  );
  const [countryCode, setCountryCode] = useSessionStorage(
    'cal-user-country-code',
    '+1',
  );
  const [tmpUserId, setTmpUserId] = useSessionStorage(
    'cal-tmp-user-id',
    uuidv4(),
  );
  const [hasUserVotedBefore, setHasUserVotedBefore] = useSessionStorage(
    'cal-user-has-voted-before',
    false,
  );
  // Poll state
  const [pollData, setPollData] = useState<IPollData | undefined>(
    event?.pollData,
  );
  const [selectedTimes, setSelectedTimes] = useState<string[]>([]);
  const [hasVoted, setHasVoted] = useState(false);
  const [isLocked, setIsLocked] = useState(hasUserVotedBefore);
  const [responseState, setResponseState] = useState(
    ReceiveMeetResponseState.NOT_RESPONDED,
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submittedTimes, setSubmittedTimes] = useState<string[]>([]);

  const name = JSON.parse(
    sessionStorage.getItem('cal-user-name') ?? '""',
  ) as string;

  const checkContactExists = async (number: string) => {
    try {
      return await caleidoBaseApi
        .get(`/users/registered-number/${number}`)
        .then((response) => {
          return response?.data?.data?.isUser ? true : false;
        });
    } catch (e) {
      return false;
    }
  };

  // API hook
  const [respondEvent] = useRespondEventMutation();

  // Date formatting utilities

  const getUserTimezone = () =>
    Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formatDateToLocalTimezone = (
    dateString: string,
    timezone: string,
    formatStr: string,
  ) => {
    const date = new Date(dateString);
    const zonedDate = toZonedTime(date, timezone);
    return format(zonedDate, formatStr);
  };

  // Check for past event
  useEffect(() => {
    setPastEvent(isPastEvent(event));
  }, [event]);

  // Handle event end check for "now" events
  useEffect(() => {
    if (type === 'now') {
      const checkEnded = () => {
        const endDateStr =
          (event?.end as unknown as string) ?? new Date().toDateString();
        const formattedEnd = toZonedTime(
          new Date(endDateStr),
          getUserTimezone(),
        );
        const eventEndTime = dayjs.tz(formattedEnd);

        if (event?.end && eventEndTime.isBefore(dayjs.tz(new Date()))) {
          setState(ReceiveMeetResponseState.ENDED);
        }
      };

      checkEnded();
      const endCheck = setInterval(checkEnded, 1000 * 60);
      return () => clearInterval(endCheck);
    }
  }, [event, type]);

  useEffect(() => {
    // if user is not a guest (ie logged in) redirect. We'll handle this better later
    if (localStorage.getItem('refreshToken') != null) {
      // take them to event page from here
      window.location.href = `/lets-meet/receive/${event?._id}`;
    }
  });

  // Check previous responses on mount
  useEffect(() => {
    if (event?._id && eventResponses[event._id]) {
      const prevResponse = eventResponses[event._id];
      if (prevResponse === 1) setResponse(ReceiveMeetResponseType.ACCEPTED);
      else if (prevResponse === 2)
        setResponse(ReceiveMeetResponseType.DECLINED);
      else if (prevResponse === 3) setResponse(ReceiveMeetResponseType.MAYBE);
    }
  }, [event?._id, eventResponses]);

  useEffect(() => {
    if (event?._id) {
      getUserPhotosForEvent(event._id).then((photos) => {
        setUserPhotos(photos);
        const organizerNumber = event?.contacts?.find(
          (contact: IContact) => contact.organizer === 1,
        )?.phoneNumber;
        if (!organizerNumber) return;
        setOrganizerPhoto(photos[organizerNumber]?.photoURL ?? '');
      });
    }
  }, [event?._id]);

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (pollData && event?._id) {
      const contactId = tmpUserId;
      if (!contactId) return;

      // Get times from server data
      const userVotedTimes = pollData.checkedTimes
        .filter((timeSlot) =>
          timeSlot.voters.some((voter) => voter.userId === contactId),
        )
        .map((timeSlot) => timeSlot.time);

      // Only update states on initial mount or when locked
      if (isInitialMount.current || isLocked) {
        setSelectedTimes(userVotedTimes);
        setHasVoted(userVotedTimes.length > 0);
        setSubmittedTimes(userVotedTimes);

        if (userVotedTimes.length > 0) {
          setResponseState(
            userVotedTimes.includes('Not available on all dates')
              ? ReceiveMeetResponseState.DECLINED
              : ReceiveMeetResponseState.ACCEPTED,
          );
        }
      }

      isInitialMount.current = false;
    }
  }, [pollData, event?._id, eventContactIds]);

  const contactId =
    event?.type === 'poll'
      ? tmpUserId
      : (eventContactIds[event?._id ?? ''] ?? '');
  const handleTimeSelectionChange = (newSelectedTimes: string[]) => {
    if (!pollData || !event?._id) return;

    if (!tmpUserId) return;

    // Update selectedTimes immediately
    setSelectedTimes(newSelectedTimes);

    // Special handling for "Not available on all dates"
    if (newSelectedTimes.includes('Not available on all dates')) {
      setResponseState(ReceiveMeetResponseState.DECLINED);

      // // Update pollData to remove all our votes
      // const newPollData: IPollData = {
      //   checkedTimes: pollData.checkedTimes.map((timeSlot) => ({
      //     time: timeSlot.time,
      //     voters: timeSlot.voters.filter(voter => voter.userId !== tmpUserId)
      //   })),
      // };
      // setPollData(newPollData);

      return;
    }

    // Regular time slot handling - update tallies in real time
    const newPollData: IPollData = {
      checkedTimes: pollData.checkedTimes.map((timeSlot) => ({
        time: timeSlot.time,
        voters: newSelectedTimes.includes(timeSlot.time)
          ? !timeSlot.voters.some((voter) => voter.userId === tmpUserId)
            ? [
                ...timeSlot.voters,
                {
                  name: name || 'Anonymous',
                  userId: tmpUserId,
                },
              ]
            : timeSlot.voters
          : timeSlot.voters.filter((voter) => voter.userId !== tmpUserId),
      })),
    };

    setPollData(newPollData);

    if (newSelectedTimes.length > 0) {
      setResponseState(ReceiveMeetResponseState.ACCEPTED);
    } else {
      setResponseState(ReceiveMeetResponseState.NOT_RESPONDED);
    }
  };
  // Format dates
  const formatHeaderDate = (date: Date) => {
    try {
      return formatDistanceToNow(date, { addSuffix: true });
    } catch {
      return 'Invalid date';
    }
  };

  const startDate =
    (event?.start as unknown as string) ?? new Date().toDateString();
  const startTime = formatDateToLocalTimezone(
    startDate,
    getUserTimezone(),
    'h:mma',
  );

  // Update attendees when contacts change
  useEffect(() => {
    if (eventContacts) {
      setAttendingInvitees(
        eventContacts
          .filter((contact) => contact.status === 1)
          .map((contact) => contact.name),
      );
    }
  }, [eventContacts]);

  useEffect(() => {
    const fetchEventActivities = async () => {
      try {
        if (event?._id) {
          const accessToken = sessionStorage.getItem('accessToken');
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/activity/event/${event._id}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
          );
          setEventActivities(response.data);
        }
      } catch (error) {
        console.error('Error fetching activities:', error);
      }
    };
  
    // Initial fetch
    fetchEventActivities();
  
    // Set up polling interval
    const intervalId = setInterval(fetchEventActivities, 15000); // 15 seconds
  
    // Cleanup on unmount or when event._id changes
    return () => clearInterval(intervalId);
  }, [event?._id]);

  const submitPollVote = async () => {
    if (!event?._id || !pollData) return;

    setIsSubmitting(true);

    const originalPollData = pollData;
    const originalContacts = eventContacts;

    try {
      const isNotAvailable = selectedTimes.includes(
        'Not available on all dates',
      );
      const voteStatus = isNotAvailable ? 2 : selectedTimes.length > 0 ? 1 : 2;

      const fullPhoneNumber = `${countryCode}${phoneNumber}`;

      // Handle contact updates
      let updatedContacts = event?.contacts ?? [];
      const existingContact = updatedContacts.find((c) => c._id === tmpUserId);

      // Create new pollData with votes removed
      const newPollData: IPollData = {
        checkedTimes: pollData.checkedTimes.map((timeSlot) => ({
          ...timeSlot,
          voters: timeSlot.voters.filter((voter) => voter.userId !== tmpUserId),
        })),
      };

      // Add new votes if not selecting "Not available"
      if (!isNotAvailable && selectedTimes.length > 0) {
        newPollData.checkedTimes = newPollData.checkedTimes.map((timeSlot) => ({
          ...timeSlot,
          voters: selectedTimes.includes(timeSlot.time)
            ? [
                ...timeSlot.voters,
                {
                  name: name || 'Anonymous',
                  userId: tmpUserId,
                },
              ]
            : timeSlot.voters,
        }));

        // Update contacts
        if (existingContact) {
          updatedContacts = updatedContacts.map((contact) =>
            contact._id === tmpUserId
              ? { ...contact, status: voteStatus }
              : contact,
          );
        } else {
          updatedContacts = [
            ...updatedContacts,
            {
              name: name,
              phoneNumber: fullPhoneNumber,
              status: voteStatus,
              _id: tmpUserId,
              organizer: 0,
            },
          ];
        }
      } else {
        // Remove from contacts if not available
        updatedContacts = updatedContacts.filter((c) => c._id !== tmpUserId);
      }

      // Update UI with new vote tallies
      setEventContacts(updatedContacts);
      setPollData(newPollData);
      setHasVoted(true);
      setSubmittedTimes(selectedTimes);

      const responseData = await respondEvent({
        eventId: event._id,
        phoneNumber: fullPhoneNumber,
        response: voteStatus,
        status: voteStatus,
        responderName: name,
        eventContactId: eventContactIds[event._id] || '',
        tmpUserId,
        pollData: newPollData,
        isGuestFlow: true,
      } as any).unwrap();

      if (responseData?.contactId) {
        setEventContactIds((prev: EventContactIds) => ({
          ...prev,
          [event._id]: responseData.contactId,
        }));
      }
      setTmpUserId(responseData?.contactId);

      // Calculate vote changes for activity
      const addedVotes = selectedTimes.filter(
        (time) =>
          !submittedTimes.includes(time) &&
          time !== 'Not available on all dates',
      ).length;

      const removedVotes = submittedTimes.filter(
        (time) =>
          !selectedTimes.includes(time) &&
          time !== 'Not available on all dates',
      ).length;

      let activityMessage;
      if (isNotAvailable) {
        activityMessage = `${name} can't make it`;
      } else if (addedVotes > 0 && removedVotes > 0) {
        activityMessage = `${name} added ${addedVotes} vote${addedVotes !== 1 ? 's' : ''} and removed ${removedVotes} vote${removedVotes !== 1 ? 's' : ''}`;
      } else if (addedVotes > 0) {
        activityMessage = `${name} added ${addedVotes} vote${addedVotes !== 1 ? 's' : ''}`;
      } else if (removedVotes > 0) {
        activityMessage = `${name} removed ${removedVotes} vote${removedVotes !== 1 ? 's' : ''}`;
      } else {
        activityMessage = `${name} updated their votes`;
      }

      const hasAccount = await checkContactExists(fullPhoneNumber);
      if (!hasAccount) {
        const activityData: IActivity = {
          _id: `temp-${Date.now()}`,
          title: 'Poll Vote',
          userId: responseData?.contactId || tmpUserId,
          body: activityMessage,
          type: 'poll_vote',
          icon: isNotAvailable ? '😢' : '👍',
          eventId: event._id,
          organizerName: name,
          status: voteStatus,
          timestamp: new Date(),
        };

        setEventActivities((prev) => [activityData, ...prev]);
        await createActivity({
          title: 'Poll Vote',
          body: activityMessage,
          userId: responseData?.contactId || tmpUserId,
          type: 'poll_vote',
          icon: isNotAvailable ? '😢' : '👍',
          eventId: event._id,
        });
      }
      setIsLocked(true);
      toast({
        title: 'Times updated successfully',
      });
    } catch (error) {
      // Revert changes on error
      setPollData(originalPollData);
      setEventContacts(originalContacts);
      console.error('Error submitting poll vote:', error);
      toast({
        title: 'Failed to update times',
        variant: 'destructive',
      });
    } finally {
      setIsSubmitting(false);
    }
    setHasUserVotedBefore(true);
    refetch?.();
  };
  // Add response helpers
  const getResponseEmoji = (response: ReceiveMeetResponseType) => {
    switch (response) {
      case ReceiveMeetResponseType.ACCEPTED:
        return '👍';
      case ReceiveMeetResponseType.DECLINED:
        return '😢';
      case ReceiveMeetResponseType.MAYBE:
        return '🤔';
      default:
        return '';
    }
  };

  const getResponseText = (
    response: ReceiveMeetResponseType,
    userName: string,
  ) => {
    switch (response) {
      case ReceiveMeetResponseType.ACCEPTED:
        return `${userName} is going to the event`;
      case ReceiveMeetResponseType.DECLINED:
        return `${userName} declined the event`;
      case ReceiveMeetResponseType.MAYBE:
        return `${userName} might go to the event`;
      default:
        return '';
    }
  };

  const getResponseType = (response: number) => {
    switch (response) {
      case 1:
        return ReceiveMeetResponseState.ACCEPTED;
      case 2:
        return ReceiveMeetResponseState.DECLINED;
      case 3:
        return ReceiveMeetResponseState.MAYBE;
      default:
        return undefined;
    }
  };

  const handleSubmitResponse = async (data: {
    name: string;
    phoneNumber: string;
    countryCode: string;
    response: ReceiveMeetResponseType;
  }) => {
    if (!event?._id) return false;

    try {
      const voteStatus =
        data.response === ReceiveMeetResponseType.ACCEPTED
          ? 1
          : data.response === ReceiveMeetResponseType.DECLINED
            ? 2
            : data.response === ReceiveMeetResponseType.MAYBE
              ? 3
              : 0;

      const responseData = await respondEvent({
        eventId: event._id,
        phoneNumber: data.phoneNumber
          ? `${data.countryCode}${data.phoneNumber}`
          : '',
        response: voteStatus,
        status: voteStatus,
        responderName: data.name,
        eventContactId: eventContactIds[event._id] || '',
      } as any).unwrap();

      // Update event responses in local storage
      setEventResponses((prev: EventResponses) => ({
        ...prev,
        [event._id]: voteStatus,
      }));

      if (responseData?.contactId) {
        setEventContactIds((prev: EventContactIds) => ({
          ...prev,
          [event._id]: responseData.contactId,
        }));
      }
      setTmpUserId(responseData?.contactId);

      // Ugly solution but needed to remove duplicated guests

      if (
        responseData?.phoneNumber === '' ||
        responseData?.phoneNumber === null
      ) {
        localStorage.setItem('cal-id-without-phone', responseData?.contactId);
      }
      let contacts = event?.contacts;
      if (contacts) {
        const existingContact = contacts.find(
          (c) => c._id === responseData.contactId,
        );

        if (existingContact) {
          contacts = contacts.map((contact) => {
            if (contact._id === responseData.contactId) {
              return { ...contact, status: voteStatus };
            }
            return contact;
          });
        } else {
          contacts = [
            ...contacts,
            {
              name: data.name,
              phoneNumber: data.phoneNumber
                ? `${data.countryCode}${data.phoneNumber}`
                : '',
              status: voteStatus,
              _id: responseData.contactId,
              organizer: 0,
            },
          ];
        }
      }

      setEventContacts(contacts ?? eventContacts);
      // Create activity data for guests
      const hasAccount = await checkContactExists(
        `${data.countryCode}${data.phoneNumber}`,
      );

      if (!hasAccount) {
        const activityData = {
          _id: `temp-${Date.now()}`,
          title: 'RSVP',
          body: getResponseText(data.response, data.name),
          type: 'rsvp',
          icon: getResponseEmoji(data.response),
          eventId: event._id,
          timestamp: new Date().toISOString(),
          organizerName: data.name,
          status: voteStatus,
        } as unknown as IActivity;

        // Optimistically update activities
        setEventActivities((prev) => [activityData, ...prev]);

        // Create activity in backend
        try {
          await createActivity({
            title: 'RSVP',
            body: getResponseText(data.response, data.name),
            type: 'rsvp',
            icon: getResponseEmoji(data.response),
            eventId: event._id,
            userId: event.userId,
          });
        } catch (error) {
          // If activity creation fails, remove optimistic update
          setEventActivities((prev) =>
            prev.filter((a) => a._id !== activityData._id),
          );
          console.error('Failed to create activity:', error);
        }
      }

      setResponse(data.response);

      return true;
    } catch (error) {
      console.error('Failed to submit response:', error);
      return false;
    }
  };

  const handleShare = async () => {
    const link = `${process.env.REACT_APP_CLIENT_URL}/share/${event?._id}`;
    if (!navigator.share) {
      await navigator?.clipboard?.writeText(link);
      toast({
        title: 'Link copied to clipboard',
      });
    } else {
      await navigator.share({
        title: 'Share this event',
        text: getFormattedSharingText(contactId ?? tmpUserId, event!, link),
        url: link,
      });
    }
  };

  const handleButtonClick = () => {
    if (isLocked) {
      // If locked, unlock for editing
      setIsLocked(false);
    } else {
      // If unlocked, submit vote
      submitPollVote().then(() => setIsLocked(true));
    }
  };
  return (
    <div className="relative min-h-screen pb-[200px]">
      {isSubmitting && (
        <div className="fixed inset-0 bg-black/60 z-50 flex items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
        </div>
      )}
      <div className="w-full sticky top-0 z-10">
        <ModernActivityHeader
          event={event}
          originPage="lets-meet"
          isGuestView={true}
        />
      </div>

      <div className="px-8">
        <EventOrganizerHeader
          organizerPhoto={organizerPhoto}
          organizerName={event?.organizer ?? ''}
          sentTime={formatHeaderDate(new Date(event?.start ?? new Date()))}
          responseState={getResponseType(eventResponses[event?._id ?? ''])}
          isPast={pastEvent}
          isHost={false}
          eventType={event?.type}
          hasUserVoted={hasUserVotedBefore}
        />

        <div className="my-6">
          <EventCard
            title={event?.activity ?? ''}
            emoji={event?.emoji ?? ''}
            guests={attendingInvitees.length}
            location={event?.location ?? ''}
            startTime={type === 'poll' ? 'TBD' : startTime}
            dateOfEvent={event?.start?.toString() ?? ''}
            eventType={type}
          />
        </div>
        {type === 'poll' && pollData && !pastEvent && (
          <div className="mt-3 mb-6">
            <div className="rounded-2xl bg-[#1A1F37] flex flex-col">
              <PollTimeSelector
                containerProps={{ id: 'poll-selector' }}
                pollData={pollData}
                serverPollData={event?.pollData ?? { checkedTimes: [] }}
                userId={contactId}
                onTimeSelectionChange={handleTimeSelectionChange}
                eventId={event?._id ?? ''}
                submittedTimes={submittedTimes}
                isLocked={isLocked}
              />

              <div className="px-4 pb-4">
                <button
                  onClick={handleButtonClick}
                  disabled={!isLocked && selectedTimes.length === 0}
                  className="w-full h-[52px] flex items-center justify-center rounded-full bg-[#6000E9] text-white font-manrope font-medium transition-opacity hover:opacity-90 disabled:opacity-50 disabled:cursor-not-allowed mt-4"
                >
                  <span className="text-white font-medium">
                    {isLocked ? 'Change times' : 'Confirm times'}
                  </span>
                </button>
              </div>
            </div>
          </div>
        )}

        <HorizontalInviteList
          title="Attendee List"
          eventId={event?._id}
          contacts={eventContacts}
          userPhotos={userPhotos}
          shareEvent={handleShare}
        />

        <CommentList
          event={{ event }}
          refetchEvent={refetch}
          activities={eventActivities}
          userPhotos={userPhotos}
          disabled={pastEvent}
        />
      </div>

      {!pastEvent && (
        <PublicDrawer
          state={state}
          setState={setState}
          response={response}
          setResponse={setResponse}
          eventId={event?._id ?? ''}
          onSubmitResponse={handleSubmitResponse}
          isPoll={type === 'poll'}
          submitPollVote={submitPollVote}
          selectedTimes={selectedTimes}
          hasVoted={hasVoted}
          hostUserId={event?.userId}
        />
      )}
    </div>
  );
};
export default ReceivePublicMeet;
