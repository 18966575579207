import { forwardRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toZonedTime } from 'date-fns-tz';
import { format } from 'date-fns';
import { ChevronRight } from 'lucide-react';
import { useGetEventsByStatusQuery } from 'services/event.service';
import { TypeSelect } from 'modules/letsMeet/components/select/TypeSelect';
import BottomNav from 'components/bottomNav/bottomNav';

import { ReactComponent as DotIcon } from 'assets/images/icons/dot.svg';
import { ReactComponent as ClockIcon } from 'assets/images/icons/clock.svg';
import EventIconNoBg from 'components/eventIcon/EventIconNoBg';
import ViewListHeader from 'modules/letsMeet/components/viewListHeader/ViewListHeader';

const DraftEvents = forwardRef<() => void, {}>((props, ref) => {
  const { data: event, refetch: refetchEvent } = useGetEventsByStatusQuery(0);

  useEffect(() => {
    refetchEvent();
  }, []);

  const formatDateToLocalTimezone = (dateString: string, timezone: string) => {
    // Convert the UTC date string to a Date object
    const date = new Date(dateString);
    // Convert the date to the desired timezone
    const zonedDate = toZonedTime(date, timezone);
    // Format the date to get only the date part in YYYY-MM-DD format
    // const formattedDate = format(zonedDate, formatStr);
    return zonedDate;
  };

  const getUserTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  return (
    <>
      <div className={'m-5 text-white'}>
        <ViewListHeader />
        <div className={'mb-5'}>
          <div className={'py-1 flex items-center justify-between'}>
            <span className={'text-xs'}>Month:</span>
            <TypeSelect eventType={'Draft events'} />
          </div>
        </div>
        <div className="space-y-2 grid grid-cols-1 gap-y-1">
          {
            // event fallback
            !event && (
              <div className="bg-white bg-opacity-25 rounded-xl overflow-hidden">
                <div className="p-3.5">
                  <div className="text-center text-xs">No draft events</div>
                </div>
              </div>
            )
          }
          {event?.map((event: any, index: number) => (
            <Link
              key={index}
              to={`/lets-meet/edit/${event._id}`}
              className="hover:underline"
            >
              <div
                className={'bg-white bg-opacity-25 rounded-xl overflow-hidden'}
              >
                <div className={'pt-1.5 pb-3 px-3.5 '}>
                  <div className={'mb-2'}>
                    <span className={'text-xs'}>
                      Created by <b>You</b>
                    </span>
                    <div className={'inline-block mx-2 mb-1'}>
                      <DotIcon />
                    </div>
                    <span className={'text-xs'}>Draft</span>
                  </div>
                  <hr className="mb-3 opacity-20" />
                  <div className="grid grid-cols-[60px_1fr_auto] w-full">
                    <div className="w-[60px] flex items-center h-full">
                      <div className="bg-purple-100 h-[54px] w-[54px] rounded-full con-mid">
                        <p
                          className="text-xs text-purple-800 font-bold"
                          style={{ lineHeight: '14px' }}
                        >
                          {new Date(
                            formatDateToLocalTimezone(
                              event.start,
                              getUserTimezone(),
                            ),
                          ).toLocaleDateString('en-US', { weekday: 'short' })}
                          <br />
                          <span
                            className="text-xl text-purple-800 font-bold"
                            style={{ lineHeight: '20px' }}
                          >
                            {new Date(
                              formatDateToLocalTimezone(
                                event.start,
                                getUserTimezone(),
                              ),
                            ).getDate()}
                          </span>
                        </p>
                      </div>
                    </div>
                    <div className="pl-2 flex items-center h-full">
                      <div className={'grid grid-cols-1 gap-0'}>
                        <div>
                          <span className="text-md font-bold">
                            {event.activity}
                          </span>
                        </div>
                        <div>
                          <div className="inline-block mr-1">
                            <ClockIcon />
                          </div>
                          <span className="text-xs inline-block">
                            {format(
                              formatDateToLocalTimezone(
                                event.start,
                                getUserTimezone(),
                              ),
                              'h:mma',
                            )}{' '}
                            -{' '}
                            {format(
                              formatDateToLocalTimezone(
                                event.end,
                                getUserTimezone(),
                              ),
                              'h:mma',
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      className="relative flex items-center h-full"
                      style={{ right: '-22px' }}
                    >
                      <EventIconNoBg icon={event.emoji} />
                    </div>
                  </div>
                </div>
                <div
                  className={
                    'bg-purple-200 px-4 py-1 flex items-center justify-between'
                  }
                >
                  <div className={'text-purple-800 text-sm'}>
                    <b>Edit</b>
                  </div>
                  <ChevronRight className="h-4 w-4 text-purple-800 text-bold" />
                </div>
              </div>
            </Link>
          ))}
        </div>
        <BottomNav />
      </div>
    </>
  );
});

DraftEvents.displayName = 'DraftEvents';
export default DraftEvents;
