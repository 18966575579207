import { ReactNode } from 'react';
import { cn } from 'utils/helpers';

interface TextProps {
  children: ReactNode;
  size?: 'small' | 'medium' | 'large' | 'extra-small';
  className?: string;
  type?: 'error' | 'success';
  onClick?: React.MouseEventHandler<HTMLHeadingElement>;
}

export const Text = ({
  children,
  size,
  className,
  type,
  onClick,
}: TextProps) => {
  const baseClassName = 'font-sans text-white'; // Added break-words

  const sizeClassName = {
    'extra-small': 'text-xs',
    small: 'text-sm',
    medium: 'font-semibold text-2xl',
    large: 'font-bold text-3xl',
  }[size ?? 'medium'];

  const typeClassName =
    type &&
    {
      error: 'text-red-500',
      success: 'text-green-500',
    }[type];

  const wrapperClassName = cn(
    baseClassName,
    sizeClassName,
    type && typeClassName,
    className,
  );

  return (
    <h1 className={wrapperClassName} onClick={onClick}>
      {children}
    </h1>
  );
};
