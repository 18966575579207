import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { cn } from 'utils/helpers';
import { EventStatus } from 'utils/constants';
import { Send } from 'lucide-react';
import { useAuth } from 'modules/auth/context/AuthContextV2';

type Props = {
  title: string;
  description: string;
  emoji: string;
  time: string;
  status?: number;
  organizerId?: string;
  organizerName?: string;
};

const EventCard = ({
  title,
  description,
  emoji,
  time,
  status,
  organizerId,
  organizerName,
}: Props) => {
  const { user } = useAuth();
  const [formattedTime, setFormattedTime] = useState('');
  useEffect(() => {
    const formattedHour =
      dayjs(time).minute() === 0
        ? dayjs(time).format('hA')
        : dayjs(time).format('h:mmA');

    if (dayjs(time).format('YYYY-MM-DD') === dayjs().format('YYYY-MM-DD')) {
      setFormattedTime(`Today, ${formattedHour}`);
    } else {
      setFormattedTime(
        `on ${dayjs(time).format('MMMM D')} at ${formattedHour}`,
      );
    }
  }, [time]);

  return (
    <div className="flex flex-col justify-center items-start bg-[#110E2B] rounded-[1rem] w-full px-5 py-4">
      <div className="flex justify-between items-start w-full">
        <div className="flex flex-col gap-1 w-[60%]">
          <div>
            <div className="text-[1.125rem] font-bold">{`${title} ${formattedTime}`}</div>
          </div>
          <div className="text-[0.875rem] text-gray-500">{description}</div>
        </div>
        <div
          className={cn(
            'rounded-[0.75rem] w-[3.75rem] h-[3.75rem] bg-[#2A2B4A] p-2 text-[2rem]',
            {
              'w-[3.75rem] h-[3.75rem]': !emoji,
            },
          )}
        >
          <div className="w-full h-full flex justify-center items-center">
            {emoji}
          </div>
        </div>
      </div>
      <div className="w-full h-full flex justify-start items-start">
        {typeof status === 'number' && (
          <div className="flex gap-1">
            {status === EventStatus.NotResponded && (
              <div className="flex items-center gap-1 ">
                {organizerId !== user?._id && (
                  <Send className="text-[#D7C0FB] w-4 h-4" />
                )}
                <div className="text-[rgb(255,255,255,0.8)]">
                  {organizerId !== user?._id && `${organizerName} invited you`}
                </div>
              </div>
            )}
            {status === EventStatus.Accepted && (
              <div className="flex items-center gap-1">
                {user?._id === organizerId && (
                  <>
                    <div>👑</div>
                    <div>
                      {dayjs(time).diff(dayjs(), 'minutes') < 0
                        ? 'Hosted'
                        : 'Hosting'}
                    </div>
                  </>
                )}
                {user?._id !== organizerId && (
                  <>
                    <div>👍</div>
                    <div className="text-[rgb(255,255,255,0.8)]">
                      {dayjs(time).diff(dayjs(), 'minutes') < 0
                        ? 'went'
                        : 'Going'}
                    </div>
                  </>
                )}
              </div>
            )}
            {status === EventStatus.Declined && (
              <div className="flex items-center gap-1">
                <div>👎</div>
                <div className="text-[rgb(255,255,255,0.8)]">Declined</div>
              </div>
            )}
            {status === EventStatus.Maybe && (
              <div className="flex items-center gap-1">
                <div>😢</div>
                <div className="text-[rgb(255,255,255,0.8)]">Maybe</div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EventCard;
