import React from 'react';
import cn from 'classnames';
import { useNavigate } from 'react-router-dom';

type Props = {
  title: string;
  description?: string;
  emoji?: string;
  actionsChildren?: React.ReactNode;
  infoChildren?: React.ReactNode;
  className?: string;
  eventId?: string;
  isEmojiIcon?: boolean;
};

const UpdatesCard = ({
  title,
  description,
  emoji,
  actionsChildren,
  infoChildren,
  className,
  eventId,
  isEmojiIcon = true,
}: Props) => {
  const navigate = useNavigate();

  const handleCardClick = () => {
    if (eventId) {
      navigate(`/lets-meet/view/${eventId}`);
    }
  };

  const handleActionClick = (e: React.MouseEvent) => {
    e.stopPropagation();
  };

  return (
    <div
      className={cn(
        'w-full h-full bg-[#2A2B4A] shadow-[0_0_5px_-1px_white] rounded-[1rem] px-5 py-4 z-[5]',
        className,
      )}
    >
      <div
        className={cn(
          'w-full flex flex-col gap-4 items-center justify-between font-manrope',
          {
            'flex justify-center items-center gap-0': !isEmojiIcon,
          },
        )}
        onClick={handleCardClick}
      >
        <div
          className={cn(
            'min-h-[9rem] w-full flex justify-between gap-3 items-start',
            {
              'justify-center gap-0': !isEmojiIcon,
            },
          )}
        >
          <div
            className={cn('flex flex-col gap-3', {
              'justify-center': !isEmojiIcon,
            })}
          >
            <div
              className={cn(
                'text-[#D7C0FB] h-auto break-words text-[1.25rem] font-bold',
                {
                  'h-[3rem]': infoChildren || isEmojiIcon,
                },
              )}
            >
              {title}
            </div>
            {!infoChildren ? (
              <p className="text-[0.875rem] font-normal opacity-80">
                {description}
              </p>
            ) : (
              infoChildren
            )}
          </div>
          {isEmojiIcon && (
            <div
              className={cn(
                'bg-[#32395E] text-[2rem] rounded-[0.75rem] text-center p-[0.875rem]',
                {
                  'w-[3.75rem] h-[3.75rem]': !emoji,
                },
              )}
            >
              {emoji}
            </div>
          )}
        </div>
        <div
          className="w-full flex justify-center gap-2 z-[10]"
          onClick={handleActionClick}
        >
          {actionsChildren}
        </div>
      </div>
    </div>
  );
};

export default UpdatesCard;
