import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useGetEventByDateRangeQuery } from 'services/event.service';
import dayjs from 'dayjs';
import DropdownFilter from 'components/v2/DropdownFilter/DropdownFilter';
import { cn } from 'utils/helpers';
import { useGetAllEventsQuery } from 'services/event.service';
import EventCard from './EventCard';
import { useNavigate } from 'react-router-dom';
import EmptyEventsList from './EmptyEventsList';
import InfiniteList from 'modules/home/pages/homePage/InfiniteList';
import { Loader2 } from 'lucide-react';
import { EventStatus } from 'utils/constants';
import { useAuth } from 'modules/auth/context/AuthContextV2';
import useUpdates from '../hooks/useUpdates';

type Props = {
  sortOptions: { label: string; value: string }[];
  sortedEvents: { label: string; value: string };
  setSortedEvents: (value: { label: string; value: string }) => void;
};

const UpcomingEventsSection = ({
  sortOptions,
  sortedEvents,
  setSortedEvents,
}: Props) => {
  const { user } = useAuth();
  const [page, setPage] = useState(0);
  const [recentEvents, setRecentEvents] = useState<any[]>([]);
  const [hasMore, setHasMore] = useState(true);
  const observerRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();

  const { data: allEvents, isFetching } = useGetAllEventsQuery({
    page,
    history: 'false',
  });

  const { updates } = useUpdates();

  const getDateRange = (offset: number, unit: dayjs.ManipulateType) => ({
    startDate: dayjs()
      .startOf('week')
      .add(1, 'day')
      .add(offset, unit)
      .format('YYYY-MM-DD'),
    endDate: dayjs()
      .endOf('week')
      .add(1, 'day')
      .add(offset, unit)
      .format('YYYY-MM-DD'),
  });
  const inviteEvents = allEvents?.filter((event: any) => {
    return event.event?.contacts.some(
      (contact: any) =>
        contact.phoneNumber === user?.phoneNumber &&
        contact.status === EventStatus.Accepted,
    );
  });

  const { data: thisWeekEvents } = useGetEventByDateRangeQuery(
    getDateRange(0, 'week'),
  );
  const { data: nextWeekEvents } = useGetEventByDateRangeQuery(
    getDateRange(1, 'week'),
  );
  const { data: nextMonthEvents } = useGetEventByDateRangeQuery(
    getDateRange(1, 'month'),
  );

  const memoizedEvents = useMemo(() => {
    let eventsToReturn = [];
    if (sortedEvents.value === 'this-week') {
      eventsToReturn = thisWeekEvents || [];
    } else if (sortedEvents.value === 'next-week') {
      eventsToReturn = nextWeekEvents || [];
    } else if (sortedEvents.value === 'next-month') {
      eventsToReturn = nextMonthEvents || [];
    } else {
      eventsToReturn = [];
    }
    return eventsToReturn;
  }, [sortedEvents, thisWeekEvents, nextWeekEvents, nextMonthEvents]);

  const memoizedRecentEvents = useMemo(() => {
    return inviteEvents || [];
  }, [inviteEvents]);

  useEffect(() => {
    if (inviteEvents) {
      if (inviteEvents.length === 0) {
        setHasMore(false);
      }
    }
  }, [inviteEvents]);

  return (
    <div>
      <div className="w-full flex justify-between items-center px-5 pb-3">
        <p className="text-[1.125rem] font-bold">
          {(() => {
            switch (sortedEvents.value) {
              case 'this-week':
                return thisWeekEvents?.length > 0
                  ? `${thisWeekEvents?.length} events`
                  : 'Events';
              case 'next-week':
                return nextWeekEvents?.length > 0
                  ? `${nextWeekEvents?.length} events`
                  : 'Events';
              case 'next-month':
                return nextMonthEvents?.length > 0
                  ? `${nextMonthEvents?.length} events`
                  : 'Events';
              case 'most-recent':
                return allEvents?.length > 0
                  ? `${allEvents?.length} events`
                  : 'Events';
              default:
                return 'Events';
            }
          })()}
        </p>
        <div className="flex items-center">
          <DropdownFilter
            options={sortOptions}
            selectedValue={sortedEvents}
            setSelectedValue={setSortedEvents}
          />
        </div>
      </div>
      <div
        className={cn(
          'w-full max-h-[42svh] min-h-[21.75rem] flex flex-col gap-3 overflow-y-scroll',
          updates.length === 0 && 'max-h-[55svh]',
        )}
      >
        <InfiniteList
          onInView={() => {
            if (isFetching || !hasMore) return;

            if (sortedEvents.value === 'most-recent') {
              setPage((prev) => prev + 1);
              setRecentEvents((prev) => [...prev, ...memoizedRecentEvents]);
            }
          }}
          children={
            <div
              className={cn(
                'w-full flex flex-col justify-center items-center gap-3 overflow-y-scroll mb-[9rem] px-5',
              )}
            >
              {sortedEvents.value !== 'most-recent' ? (
                memoizedEvents?.length > 0 ? (
                  memoizedEvents.map((event: any, index: number) => (
                    <div
                      className="w-full flex"
                      key={index}
                      onClick={() => {
                        navigate(`/lets-meet/view/${event.event._id}`);
                      }}
                    >
                      <EventCard
                        title={event.event.activity}
                        description={event.event.description}
                        time={event.event.start}
                        emoji={event.event.emoji || '📅'}
                        status={event.event.status}
                        organizerId={event.event.organizerId}
                      />
                    </div>
                  ))
                ) : (
                  <>
                    {!isFetching && allEvents.length === 0 && (
                      <EmptyEventsList />
                    )}
                    {isFetching && (
                      <div className="flex justify-center items-center">
                        <Loader2 className="w-4 h-4 animate-spin" />
                      </div>
                    )}
                  </>
                )
              ) : null}
              {sortedEvents.value === 'most-recent' ? (
                recentEvents?.length > 0 ? (
                  recentEvents.map((event: any, index: number) => (
                    <div
                      className="w-full flex"
                      key={index}
                      onClick={() => {
                        navigate(`/lets-meet/view/${event.event._id}`);
                      }}
                    >
                      <EventCard
                        title={event.event.activity}
                        description={event.event.description}
                        time={event.event.start}
                        emoji={event.event.emoji}
                        status={event.event.status}
                        organizerId={event.event.userId}
                      />
                    </div>
                  ))
                ) : (
                  <>
                    {!isFetching && recentEvents.length === 0 && (
                      <EmptyEventsList />
                    )}
                    {isFetching && (
                      <div className="flex justify-center items-center">
                        <Loader2 className="w-4 h-4 animate-spin" />
                      </div>
                    )}
                  </>
                )
              ) : null}
            </div>
          }
        />
        {isFetching && <p className="text-center text-gray-500">Loading...</p>}

        {hasMore && <div ref={observerRef} className="bg-transparent" />}
      </div>
    </div>
  );
};

export default UpcomingEventsSection;
