'use client';

import React, { useCallback, useEffect, useMemo, useState } from 'react';

import dayjs from 'dayjs';
import { Check } from 'lucide-react';
import { Text } from 'components/text/primary/Text';
import TextField from 'components/textfield/TextField';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { getUserTimezone } from 'modules/home/utils/helpers';
import AvailabilityTimeSelector from 'modules/profile/components/availability/TimeDropDown/availabilityTimeSelector';
import { ITimeSlot } from 'modules/profile/components/availability/TimeSlot/TimeSlot';
import { useCreateTimeOffMutation } from 'services/event.service';
import { validateTimeSlotAvailability } from 'utils/calendarUtils';
import { useAppSelector } from 'hooks/useAppSelector';
import { RootState } from 'app/store';
import { IAvailability } from 'modules/profile/types/types';
import { toast } from 'components/ui/toast/use-toast';
import OverlayLoader from 'components/loader/OverlayLoader';
import useKeyboardHeightReset from 'hooks/useKeyboardHeightReset';
import { AddManualBlockProps } from './AddManualBlock.types';
import { useAuth } from 'modules/auth/context/AuthContextV2';

// NOTE: Didn't handle slots overlapping errors.

const AddManualBlock = ({
  onClose,
  onSuccess,
  endTime: _endTime,
  startTime: _startTime,
  title: _title,
}: AddManualBlockProps) => {
  const { isKeyboardOpen } = useKeyboardHeightReset();

  // get user from store
  const { user } = useAuth();

  // default date
  const dateToday = useMemo(() => new Date(), []);

  const [createTimeBlock, { isLoading, data }] = useCreateTimeOffMutation();

  // form errors
  const [errors, setErrors] = useState<{ [key: string]: string }>({});
  const [errorMessage, setErrorMessage] = useState<string>('');

  const [startTime, setStartTime] = useState<ITimeSlot>({
    timeZone: getUserTimezone(),
    dateTime: _startTime ? _startTime.toISOString() : new Date().toISOString(),
  });

  const [endTime, setEndTime] = useState<ITimeSlot | undefined>();

  const [title, setTitle] = useState<string>(_title ?? '');

  const dispatch = useAppDispatch();

  const validateForm = useCallback(() => {
    const errors: { [key: string]: string } = {};

    if (!title) {
      errors.activity = 'Activity is required';
    }

    if (!startTime.dateTime) {
      errors.startTime = 'Start time is required';
    }

    if (!endTime?.dateTime) {
      errors.endTime = 'End time is required';
    }

    if (startTime && endTime && startTime.dateTime && endTime?.dateTime) {
      if (new Date(startTime.dateTime) >= new Date(endTime.dateTime)) {
        errors.startTime = 'Start time should be before end time';
        errors.endTime = 'End time should be after start time';
      }
    }

    Object.keys(errors).forEach((key) => {
      if (!errors[key]) {
        delete errors[key];
      }
    });

    setErrors((prev) => errors);

    return Object.keys(errors).length === 0;
  }, [title, startTime, endTime]);

  // form submit handler
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
  };

  const createBlock = async () => {
    if (!validateForm()) return;
    try {
      const updateData = {
        startTime: new Date(startTime.dateTime ?? '').toISOString(),
        endTime: new Date(endTime?.dateTime ?? '').toISOString(),
      };

      const response = await createTimeBlock?.({
        endDate: updateData.endTime,
        startDate: updateData.startTime,
        title,
      }).unwrap();

      // close drawer after success
      if (response) {
        if (response?.timeOff?.id) {
          // created time off
          onSuccess && onSuccess();
          onClose && onClose();
          toast({
            title: '',
            description: <div>Time block created successfully!</div>,
            fullscreen: true,
            backgroundColor: '#E0F4DB',
            textColor: '#060606',
            icon: <Check />,
          });
        } else {
          // error creating time off
          toast;
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // validate errors
  useEffect(() => {
    if (title) {
      setErrors((prev) => ({ ...prev, activity: '' }));
    }
    if (startTime && endTime && startTime.dateTime && endTime?.dateTime) {
      if (new Date(startTime.dateTime) >= new Date(endTime.dateTime)) {
        setErrors((prev) => ({
          ...prev,
          startTime: 'Start time should be before end time',
          endTime: 'End time should be after start time',
        }));
      } else {
        setErrors((prev) => ({ ...prev, startTime: '', endTime: '' }));
      }
    }
    // show error if time slot is already marked as unavailable
    if (!errors.startTime && !errors.endTime) {
      if (
        !validateTimeSlotAvailability(
          startTime.dateTime,
          endTime?.dateTime,
          user?.availability as IAvailability,
        )
      ) {
        setErrorMessage('Selected time is already marked as unavailable!');
      } else {
        setErrorMessage('');
      }
    }
  }, [title, startTime, endTime]);

  // fixing the end time set issue made by Himansa's code
  useEffect(() => {
    if (_endTime) {
      setEndTime({
        timeZone: getUserTimezone(),
        dateTime: _endTime.toISOString(),
      });
    }
  }, [_endTime]);

  // handle end time change
  const handleEndTimeChange = (time: ITimeSlot) => {
    const originalDate = new Date(_startTime ?? dateToday);
    const timeDate = new Date(time.dateTime);
    originalDate.setHours(timeDate.getHours());
    originalDate.setMinutes(timeDate.getMinutes());
    setEndTime((prev) => {
      return { ...time, dateTime: originalDate.toISOString() };
    });
  };

  // handle start time change
  const handleStartTimeChange = (time: ITimeSlot) => {
    const originalDate = new Date(_startTime ?? dateToday);
    const timeDate = new Date(time.dateTime);
    originalDate.setHours(timeDate.getHours());
    originalDate.setMinutes(timeDate.getMinutes());
    setStartTime({ ...time, dateTime: originalDate.toISOString() });
  };

  // handle input fields change
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
  };

  return (
    <>
      {isLoading && <OverlayLoader />}
      <div className="mt-3 px-8 pb-[45px]" style={{}}>
        <h1 className="text-white text-[20px] font-semibold leading-normal">
          Add time block
        </h1>
        {
          <Text size="small" type="error" className="text-xs my-2">
            {errorMessage}
          </Text>
        }
        <form
          onSubmit={handleSubmit}
          className="w-full grid grid-cols-1 gap-4 pt-[10px]"
        >
          <div>
            <TextField
              label=" What will you be doing?"
              placeholder="Name the activity"
              name="activity"
              value={title}
              onChange={handleInputChange}
              error={errors.activity}
              className="w-full max-w-full"
              autoFocus={true}
            />
          </div>

          <div className="mb-1">
            <Text
              size="small"
              className="w-full text-sm flex justify-start space-x-2 mb-2"
            >
              <span>Start time</span>
              <span>*</span>
            </Text>

            <AvailabilityTimeSelector
              onTimeSelect={handleStartTimeChange}
              selectedTime={
                startTime
                  ? dayjs(startTime.dateTime).format('hh:mm A')
                  : undefined
              }
              className="m-0 max-w-full [&>div]:justify-start [&_.lucide]:hidden [&_button]:w-full"
              popupDirection="down"
              useDefaultTime
              error={!!errors.startTime}
            />

            {errors.startTime && (
              <Text size="small" type="error" className="text-xs mx-2 my-1">
                {errors.startTime}
              </Text>
            )}
          </div>

          <div className="mb-1">
            <Text
              size="small"
              className="w-full text-sm flex justify-start space-x-2 mb-2"
            >
              <span>End time</span>
              <span>*</span>
            </Text>
            <AvailabilityTimeSelector
              onTimeSelect={handleEndTimeChange}
              selectedTime={
                endTime ? dayjs(endTime.dateTime).format('hh:mm A') : undefined
              }
              className="m-0 max-w-full [&>div]:justify-start [&_.lucide]:hidden [&_button]:w-full"
              popupDirection="up"
              startTime={startTime.dateTime}
              useDefaultTime
              error={!!errors.endTime}
            />

            {errors.endTime && (
              <Text size="small" type="error" className="text-xs mx-2 my-1">
                {errors.endTime}
              </Text>
            )}
          </div>

          <div className="w-full flex justify-center mt-3">
            <input
              className="bg-secondary text-white font-semibold py-3 px-8 rounded-[100px] text-base leading-normal cursor-pointer"
              type="submit"
              onClick={createBlock}
              value="Add time block"
            />
          </div>
        </form>
      </div>
    </>
  );
};

export default AddManualBlock;
