import React, { useEffect, useState } from 'react';

type Props = {
  value?: {
    hours: string;
    minutes: string;
  } | null;
  onChange: (value: { hours: string; minutes: string }) => void;
};

const TimePicker = ({ value, onChange }: Props) => {
  const [hours, setHours] = useState('12');
  const [minutes, setMinutes] = useState('00');
  const [dayPart, setDayPart] = useState('AM');

  const validateTime = (h: string, m: string, d: string) => {
    const validatedHours = !h
      ? '12'
      : isNaN(parseInt(h))
        ? '12'
        : parseInt(h) > 12
          ? '12'
          : parseInt(h) < 1
            ? '01'
            : h.padStart(2, '0');

    const validatedMinutes = !m
      ? '00'
      : isNaN(parseInt(m))
        ? '00'
        : parseInt(m) > 59
          ? '59'
          : parseInt(m) < 0
            ? '00'
            : m.padStart(2, '0');

    const validatedDayPart = d.toUpperCase() === 'PM' ? 'PM' : 'AM';

    return {
      hours: validatedHours,
      minutes: validatedMinutes,
      dayPart: validatedDayPart,
    };
  };

  const handleBlur = () => {
    const validated = validateTime(hours, minutes, dayPart);
    setHours(validated.hours);
    setMinutes(validated.minutes);
    setDayPart(validated.dayPart);

    const hour =
      validated.dayPart === 'PM' && parseInt(validated.hours) !== 12
        ? parseInt(validated.hours) + 12
        : validated.dayPart === 'AM' && parseInt(validated.hours) === 12
          ? 0
          : parseInt(validated.hours);

    onChange({
      hours: hour.toString().padStart(2, '0'),
      minutes: validated.minutes.padStart(2, '0'),
    });
  };

  useEffect(() => {
    if (!value || (value.hours.length > 2 && value.minutes.length > 2)) {
      setHours('12');
      setMinutes('00');
      setDayPart('AM');
      onChange({
        hours: '12',
        minutes: '00',
      });
      return;
    }

    const dayPart = parseInt(value.hours) >= 12 ? 'PM' : 'AM';

    const hours =
      parseInt(value.hours) % 12 === 0
        ? '12'
        : (parseInt(value.hours) % 12).toString();

    const minutes = value.minutes;

    setHours(Number.isNaN(hours) ? '12' : hours.padStart(2, '0'));
    setMinutes(Number.isNaN(minutes) ? '00' : minutes.padStart(2, '0'));
    setDayPart(dayPart);
  }, [value]);

  return (
    <div className="flex items-center gap-[0.125rem]">
      <div className="flex items-center">
        <input
          className="w-9 bg-[#32395E] text-white rounded-full p-1 text-center text-sm"
          value={hours}
          onChange={(e) => setHours(e.target.value)}
          onBlur={handleBlur}
          maxLength={2}
        />
      </div>
      <span className="text-lg text-white">:</span>
      <div className="flex items-center">
        <input
          className="w-9 bg-[#32395E] text-white rounded-full p-1 text-center text-sm"
          value={minutes}
          onChange={(e) => setMinutes(e.target.value)}
          onBlur={handleBlur}
          maxLength={2}
        />
      </div>
      <div className="flex items-center ml-1">
        <input
          className="w-10 bg-[#32395E] text-white rounded-full p-1 text-center text-sm"
          value={dayPart}
          onChange={(e) => setDayPart(e.target.value)}
          onBlur={handleBlur}
          maxLength={2}
        />
      </div>
    </div>
  );
};

export default TimePicker;
