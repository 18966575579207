import { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight } from 'lucide-react';

import { useAppSelector } from 'hooks/useAppSelector';
import { RootState } from 'app/store';

import { ReactComponent as PhoneIcon } from 'assets/images/help/phone.svg';
import { ReactComponent as MailIcon } from 'assets/images/help/mail.svg';

import { sendHelpEmail } from 'modules/settings/services/help.service';

import SettingsLayout from 'modules/settings/layouts/SettingsLayout/SettingsLayout';
import TextField from 'components/textfield/TextField';
import MainButton from 'modules/auth/components/MainButton';
import { Text } from 'components/text/primary/Text';
import OverlayLoader from 'components/loader/OverlayLoader';
import TextArea from 'components/textArea/TextArea';
import { toast } from 'components/ui/toast/use-toast';

import { validateForm } from './HelpFormValidation';
import { useAuth } from 'modules/auth/context/AuthContextV2';

type ApiError = {
  data?: {
    message?: string;
  };
};

type FormValues = {
  name: string;
  email: string;
  message: string;
};

const Help = () => {
  const { user } = useAuth();
  const [formValues, setFormValues] = useState<FormValues>({
    name: '',
    email: '',
    message: '',
  });

  const [errors, setErrors] = useState<{ [key in keyof FormValues]?: string }>(
    {},
  );
  const [loading, setLoading] = useState(true);
  const [sendEmailIsLoading, setSendEmailIsLoading] = useState(false);

  const handleInputChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const { name, value } = event.target;
      setFormValues((prevValues) => ({ ...prevValues, [name]: value }));
      setErrors((prevErrors) => ({ ...prevErrors, [name]: undefined }));
    },
    [],
  );

  const handleSubmit = useCallback(async () => {
    const errors = validateForm(formValues);
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }
    try {
      setSendEmailIsLoading(true);
      if (user) {
        const timeZone = new Intl.DateTimeFormat().resolvedOptions().timeZone;

        const updatedData = { ...user, ...formValues, timeZone };
        const { name, email, message } = updatedData;

        const response = await sendHelpEmail({ name, email, message });
        if (response) {
          toast({
            title: 'Inquiry Sent!',
            description: 'Your inquiry has been sent successfully.',
          });
          setFormValues({
            name: user.name,
            email: '',
            message: '',
          });
        }
      }
    } catch (error: unknown) {
      const errMessage =
        (error as ApiError).data?.message || 'Failed to send inquiry';
      toast({ title: 'Error', description: errMessage });
    } finally {
      setSendEmailIsLoading(false);
    }
  }, [user, formValues]);

  useEffect(() => {
    if (user) {
      setFormValues({
        name: user?.name,
        email: '',
        message: '',
      });
      setLoading(false);
    }
  }, [user]);

  return (
    <SettingsLayout>
      {loading && <OverlayLoader />}
      <main className="flex-grow mb-4">
        <div className="w-full my-8 mb-6">
          <div className="flex flex-col items-center w-full gap-2">
            <Text size="medium" className="w-full">
              Help
            </Text>
            <Text size="small" className="my-3 mb-5">
              Send us any inquiries, questions, or feature requests!
            </Text>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
              className="w-full"
            >
              <TextField
                label="Name"
                placeholder="Enter your name"
                name="name"
                required={true}
                value={formValues.name}
                error={errors.name}
                onChange={handleInputChange}
              />
              <TextField
                label="Email"
                placeholder="Email address to get in touch"
                name="email"
                required={true}
                value={formValues.email}
                error={errors.email}
                onChange={handleInputChange}
              />
              <TextArea
                label="Message"
                placeholder="Type your message here."
                name="message"
                required={true}
                rows={6}
                value={formValues.message}
                error={errors.message}
                onChange={handleInputChange}
              />
              <hr className="my-8 opacity-20" />
              <div className={'grid grid-cols-2 gap-4'}>
                <Link
                  to="tel:+15555551234"
                  className="px-6 py-4 text-xs text-white rounded-xl bg-slate-800"
                >
                  <PhoneIcon className={'mb-3'} />
                  (555) 555-1234
                </Link>
                <Link
                  to="mailto:info@caleido.com"
                  className="px-6 py-4 text-xs text-white rounded-xl bg-slate-800"
                >
                  <MailIcon className={'mb-3'} />
                  info@caleido.com
                </Link>
              </div>
            </form>
          </div>
        </div>
      </main>
      <footer className="mb-4">
        <MainButton
          type="primary"
          icon={<ChevronRight color="#fff" />}
          onClick={handleSubmit}
          loading={sendEmailIsLoading}
        >
          <Text size="small">Send inquiry</Text>
        </MainButton>
      </footer>
    </SettingsLayout>
  );
};

export default Help;
