import { createPortal } from 'react-dom';
import dayjs from 'dayjs';
import { useState } from 'react';
import { cn } from 'utils/helpers';
import WheelSelector from 'components/v2/wheelSelector/WheelSelector';
import Button from 'components/v2/button/Button';
import LetsMeetLayout from 'modules/letsMeet/layouts/LetsMeetLayout';
import { DURATION_OPTIONS } from 'utils/constants';
import { useCreateTimeOffMutation } from 'services/event.service';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSave: (selectedDuration: string | null) => void;
};

const AvailabilityPopup = ({ isOpen, onClose, onSave }: Props) => {
  const [selectedDuration, setSelectedDuration] = useState<string | null>(null);

  const [createTimeOff] = useCreateTimeOffMutation();

  return createPortal(
    <div
      className={cn(
        'fixed inset-0 z-50 duration-300 ease-in-out',
        isOpen
          ? 'opacity-100 pointer-events-auto'
          : 'opacity-0 pointer-events-none',
      )}
    >
      <LetsMeetLayout
        title="Set availability time"
        step="create"
        footerText={''}
        actionOnHeaderRight={''}
        widget="menu"
        handleGoBack={onClose}
        bottomChildren={
          <Button
            onClick={() => {
              if (selectedDuration) {
                createTimeOff({
                  startDate: dayjs().toISOString(),
                  endDate: dayjs()
                    .add(parseInt(selectedDuration), 'minutes')
                    .toISOString(),
                  title: 'Time off',
                });
              }
              onSave(selectedDuration);
            }}
          >
            Set availability
          </Button>
        }
      >
        <div className="mt-[148px]">
          <WheelSelector
            label="How long?"
            options={DURATION_OPTIONS}
            selectedOption={selectedDuration}
            onChange={setSelectedDuration}
          />
        </div>
      </LetsMeetLayout>
    </div>,
    document.body,
  );
};

export default AvailabilityPopup;
