export const UPDATE_RESPONSE_OPTIONS = [
  {
    label: 'Going',
    value: 'Accepted',
  },
  {
    label: 'Maybe',
    value: 'Maybe',
  },
  {
    label: "Can't go",
    value: 'Declined',
  },
];

export const FILTER_OPTIONS = [
  {
    label: 'This Week',
    value: 'this-week',
  },
  {
    label: 'Next Week',
    value: 'next-week',
  },
  {
    label: 'Next Month',
    value: 'next-month',
  },
  {
    label: 'Most Recent',
    value: 'most-recent',
  },
];
