import dayjs from 'dayjs';
import { useEffect, useMemo, useState } from 'react';
import { X } from 'lucide-react';
import { Text } from 'components/text/primary/Text';
import Button from 'components/v2/button/Button';
import { Checkbox } from 'components/ui/checkbox';
import TimePicker from 'components/v2/timePicker/TimePicker';
import { compareHoursAndMinutes } from 'modules/letsMeet/utils/dates';
import { AvailabilitySlot } from 'services/calendar.service';
import MobilePopup from '../mobilePopup/MobilePopup';

// Add new type for custom time slots
type CustomTimeSlot = {
  id: string;
  hours: string;
  minutes: string;
};

type Props = {
  selectedDate: Date;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  selectedTimeSlots: Date[];
  setSelectedTimeSlots: (timeSlots: Date[]) => void;
  availableTimeSlots: AvailabilitySlot[];
};

const TimeSlotPickerPopup = ({
  selectedDate,
  isOpen,
  setIsOpen,
  selectedTimeSlots,
  setSelectedTimeSlots,
  availableTimeSlots,
}: Props) => {
  const [customTimeSlots, setCustomTimeSlots] = useState<CustomTimeSlot[]>([]);

  const selectedDateTimeOptions = useMemo(() => {
    return availableTimeSlots
      .filter((slot) => {
        const dayJsSlot = dayjs(slot.start);

        const isSameDay = dayjs(selectedDate).isSame(dayJsSlot, 'day');

        return isSameDay;
      })
      .map((slot) => {
        const dayJsSlot = dayjs(slot.start);

        return {
          label: dayJsSlot.format('hh:mm A'),
          hour: dayJsSlot.hour(),
          minute: dayJsSlot.minute(),
          value: dayjs(selectedDate)
            .set('hour', dayJsSlot.hour())
            .set('minute', dayJsSlot.minute())
            .toDate(),
        };
      });
  }, [selectedDate, availableTimeSlots]);

  const [beforeOpenSelectedTimeSlots, setBeforeOpenSelectedTimeSlots] =
    useState<Date[]>([]);

  useEffect(() => {
    if (isOpen) {
      setBeforeOpenSelectedTimeSlots(selectedTimeSlots);
    }
  }, [isOpen]);

  // Add an effect to sync customTimeSlots with selectedTimeSlots
  useEffect(() => {
    setCustomTimeSlots((prevCustomSlots) => {
      return prevCustomSlots.filter((customSlot) => {
        const customDate = dayjs(selectedDate)
          .set('hour', parseInt(customSlot.hours))
          .set('minute', parseInt(customSlot.minutes))
          .toDate();

        // Keep only if the time exists in selectedTimeSlots
        return selectedTimeSlots.some((selectedSlot) =>
          compareHoursAndMinutes(selectedSlot, customDate),
        );
      });
    });
  }, [selectedTimeSlots, selectedDate]);

  const handleConfirm = () => {
    setIsOpen(false);
  };

  // Update handleTimeSlotClick to also clean up custom slots when deselecting
  const handleTimeSlotClick = (
    timeSlot: (typeof selectedDateTimeOptions)[number],
    isSelected: boolean,
  ) => {
    if (!isSelected) {
      setSelectedTimeSlots([
        ...selectedTimeSlots.filter(
          (slot) => !compareHoursAndMinutes(slot, timeSlot.value),
        ),
        dayjs(selectedDate)
          .set('hour', timeSlot.hour)
          .set('minute', timeSlot.minute)
          .toDate(),
      ]);
    } else {
      setSelectedTimeSlots(
        selectedTimeSlots.filter(
          (slot) => !compareHoursAndMinutes(slot, timeSlot.value),
        ),
      );
    }
  };

  const addCustomTimeSlot = () => {
    const newSlot = {
      id: Math.random().toString(),
      hours: '12',
      minutes: '00',
    };

    // Check if this time already exists in selected time slots
    const newDate = dayjs(selectedDate)
      .set('hour', parseInt(newSlot.hours))
      .set('minute', parseInt(newSlot.minutes))
      .toDate();

    const isDuplicate = selectedTimeSlots.some((slot) =>
      compareHoursAndMinutes(slot, newDate),
    );

    if (isDuplicate) {
      return; // Don't add if it's a duplicate
    }

    setCustomTimeSlots([...customTimeSlots, newSlot]);
    setSelectedTimeSlots([...selectedTimeSlots, newDate]);
  };

  const removeCustomTimeSlot = (id: string) => {
    const slotToRemove = customTimeSlots.find((slot) => slot.id === id);
    if (slotToRemove) {
      const dateToRemove = dayjs(selectedDate)
        .set('hour', parseInt(slotToRemove.hours))
        .set('minute', parseInt(slotToRemove.minutes))
        .toDate();

      setSelectedTimeSlots(
        selectedTimeSlots.filter(
          (slot) => !compareHoursAndMinutes(slot, dateToRemove),
        ),
      );
    }
    setCustomTimeSlots(customTimeSlots.filter((slot) => slot.id !== id));
  };

  const handleCustomTimeChange = (
    id: string,
    value: { hours: string; minutes: string },
  ) => {
    // Check if the new time would create a duplicate
    const newDate = dayjs(selectedDate)
      .set('hour', parseInt(value.hours))
      .set('minute', parseInt(value.minutes))
      .toDate();

    const oldSlot = customTimeSlots.find((slot) => slot.id === id);
    const oldDate = oldSlot
      ? dayjs(selectedDate)
          .set('hour', parseInt(oldSlot.hours))
          .set('minute', parseInt(oldSlot.minutes))
          .toDate()
      : null;

    // Check if this time already exists in other time slots
    const isDuplicate = selectedTimeSlots.some(
      (slot) =>
        compareHoursAndMinutes(slot, newDate) &&
        (!oldDate || !compareHoursAndMinutes(slot, oldDate)),
    );

    if (isDuplicate) {
      return; // Don't update if it would create a duplicate
    }

    // Update custom time slots
    setCustomTimeSlots(
      customTimeSlots.map((slot) =>
        slot.id === id ? { ...slot, ...value } : slot,
      ),
    );

    // Update the corresponding selected time slot
    const updatedTimeSlots = selectedTimeSlots.map((slot) =>
      oldDate && compareHoursAndMinutes(slot, oldDate) ? newDate : slot,
    );
    setSelectedTimeSlots(updatedTimeSlots);
  };

  return (
    <MobilePopup isOpen={isOpen} setIsOpen={setIsOpen}>
      <>
        <div className="flex items-center justify-between">
          <Text
            size="small"
            className="text-[rgb(255,255,255,0.6)] font-semibold text-[0.875rem] py-2"
          >
            SELECT ALL THAT APPLY
          </Text>
        </div>
        <div className="flex flex-col">
          <div className="max-h-[28svh] flex flex-col gap-2 overflow-y-scroll">
            {availableTimeSlots.length > 0 ? (
              selectedDateTimeOptions.map((timeSlot) => {
                const isSelected = selectedTimeSlots.some((slot) =>
                  compareHoursAndMinutes(slot, timeSlot.value),
                );
                return (
                  <div
                    key={timeSlot.value.toISOString()}
                    className="relative flex items-center gap-2 cursor-pointer"
                  >
                    <Checkbox
                      checked={isSelected}
                      onClick={() => {
                        handleTimeSlotClick(timeSlot, isSelected);
                      }}
                    />
                    <Text
                      size="small"
                      className="text-[0.875rem] font-normal font-manrope py-2 px-4"
                      onClick={() => {
                        handleTimeSlotClick(timeSlot, isSelected);
                      }}
                    >
                      {timeSlot.label}
                    </Text>
                  </div>
                );
              })
            ) : (
              <div className="flex items-center justify-center h-full mb-4">
                <Text size="small" className="text-gray-500">
                  No time slots available
                </Text>
              </div>
            )}
            <button
              className="mt-2 text-sm text-white underline"
              onClick={addCustomTimeSlot}
            >
              Add Custom Time
            </button>
          </div>
          {/* Custom time slots section */}
          <div className="pt-4">
            <div className="flex flex-col gap-3 pl-1">
              {customTimeSlots.map((slot) => (
                <div key={slot.id} className="flex items-center gap-2">
                  <TimePicker
                    value={{ hours: slot.hours, minutes: slot.minutes }}
                    onChange={(value) => handleCustomTimeChange(slot.id, value)}
                  />
                  <X
                    className="w-5 h-5 text-red-500 cursor-pointer"
                    onClick={() => removeCustomTimeSlot(slot.id)}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="flex flex-col justify-center gap-2 pt-5">
          <Button onClick={handleConfirm}>Confirm</Button>
        </div>
      </>
    </MobilePopup>
  );
};

export default TimeSlotPickerPopup;
