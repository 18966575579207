import React, { useEffect, useState } from 'react';
import { ChevronRight } from 'lucide-react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Item } from '@radix-ui/react-dropdown-menu';
import LetsMeetLayout from 'modules/letsMeet/layouts/LetsMeetLayout';
import MainButton from 'modules/auth/components/MainButton';
import { Text } from 'components/text/primary/Text';
import OverlayLoader from 'components/loader/OverlayLoader';
import { Button } from 'components/ui/button';
import { MeetInfo } from 'modules/letsMeet/components/meetInfo/meetInfo';
import { Separator } from 'components/ui/separator';
import InviteList from 'modules/letsMeet/components/inviteList/inviteList';
import {
  useGetEventQuery,
  useUpdateEventStatusMutation,
} from 'services/event.service';
import EventIcon from 'components/eventIcon/EventIcon';
import AvailableTimes from 'modules/letsMeet/components/meetInfo/availableTimes';
import { toast } from 'components/ui/toast/use-toast';
import { ReactComponent as GreenBell } from 'assets/images/event/greenBell.svg';
import { IContact, IEvent } from 'types/event';
import { useSendNotificationMutation } from 'services/notification.service';
import { NotificationType, NotificationV2Type } from 'modules/letsMeet/utils/enum';
import { sendNotificationByType } from 'utils/helpers';

const ReviewEvent = () => {
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [event, setCurrentEvent] = useState<IEvent | null>(null);
  const [sendInvitesIsLoading, setSendInvitesIsLoading] = useState(false);
  const {
    data: eventData,
    isLoading,
    isFetching,
    refetch: refetchEvent,
  } = useGetEventQuery(id || '');

  const convertingPollId = sessionStorage.getItem(
    'cal-event-converting-poll-event-id',
  );

  const [sendNotification] = useSendNotificationMutation();

  const [updateEventStatus] = useUpdateEventStatusMutation();

  const location = useLocation();
  const isEditEvent = location.state?.isEditEvent;
  const isNewDate = location.state?.isNewDate;
  const isNewLocation = location.state?.isNewLocation;
  const isNewTime = location.state?.isNewTime;

  useEffect(() => {
    if (!isLoading && eventData) {
      setCurrentEvent(eventData); // Assuming `message` contains the event data
      setLoading(false); // Set loading to false once the data is loaded
    }
  }, [isLoading, eventData]);

  // scroll to top on component load
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (!id) {
    return null;
  }

  // We don't want to show the organizer in the invite list
  const inviteList =
    event?.contacts
      ?.filter((contact: IContact) => contact.organizer === 0)
      .map((contact: IContact) => contact.name) ?? [];

  const handleSubmit = async () => {
    setSendInvitesIsLoading(true);
    setLoading(true); // Set loading to true while updating the event status
    await updateEventStatus({ id, status: 1 });
    const inviteListLength = inviteList.length;
    const toastMessage =
      inviteListLength == 0
        ? 'Event has been created - share it with friends!'
        : 'Invites have been sent!';

    if (!isEditEvent) {
      if (event?.type === 'now') {
        // TODO: Don't think this needs to run synchronously but we want to be sure the notifications fire
        await sendNotificationByType(NotificationV2Type.INVITE_MEET_NOW, id);
      }
      if (event?.type === 'later') {
        await sendNotificationByType(NotificationV2Type.INVITE_MEET_LATER, id);
      }
      if (event?.type === 'poll') {
        await sendNotificationByType(NotificationV2Type.POLL_REQUEST, id);
        await sendNotificationByType(NotificationV2Type.REMINDER_TO_RESPOND_TO_POLL, id);
        await sendNotificationByType(NotificationV2Type.REMINDER_TO_PICK_DATE, id);
      }
    } else {
      if (event?.type === 'later') {
        const responseMzg = [];
        const notificationData: {
          newEventDate?: Date;
          newEventLocation?: string;
          newEventTime?: string;
        } = {};
        if (isNewTime) {
          responseMzg.push('Time');
          /* TODO We really need to properly fix event.start/end to be an actual Date object or string.... it's a mess now :( */
          const startDate = event.start ? new Date(event.start) : null;
          try {
            const startDate = event.start ? new Date(event.start) : null;
            if (startDate && !isNaN(startDate.getTime())) {
              notificationData.newEventTime = startDate.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true
              });
            }
          } catch (error) {
            console.error('Error parsing date:', error);
          }
        }
        if (isNewLocation) {
          responseMzg.push('Venue');
          notificationData.newEventLocation = event.location;
        }
        if (isNewDate) {
          responseMzg.push('Date');
          notificationData.newEventDate = event.start;
        }

        let response = '';
        if (responseMzg.length > 1) {
          // Join with " & " if there are multiple changes
          response =
            responseMzg.slice(0, -1).join(', ') + ' & ' + responseMzg.slice(-1);
        } else if (responseMzg.length === 1) {
          // Single change, just use it
          response = responseMzg[0];
        }

        // Send notification for the changes
        if (convertingPollId) {
          sendNotificationByType(NotificationV2Type.POLL_TURNED_INTO_DATE, id, notificationData.newEventDate, notificationData.newEventLocation, notificationData.newEventTime);
        } else {
          sendNotificationByType(NotificationV2Type.EVENT_CHANGE, id, notificationData.newEventDate, notificationData.newEventLocation, notificationData.newEventTime);
        }
      }
    }

    toast({
      title: '',
      description: <div>{toastMessage}</div>,
      fullscreen: true,
      backgroundColor: '#E0F4DB',
      textColor: '#060606',
      icon: <GreenBell />,
    });
    setLoading(false); // Set loading to false after the update
    navigate(`/lets-meet/view/${id}`);
    setSendInvitesIsLoading(false);
  };

  const SaveAsDraftButton = () => (
    <Button
      variant={'ghost'}
      className="text-neutral-200 hover:bg-white/10 hover:text-neutral-100"
    >
      Saved as Draft
    </Button>
  );
  if (isLoading || isFetching) {
    return <OverlayLoader />;
  }
  const InfoBox = ({ eventData }: { eventData: IEvent }) => (
    <div className="w-full bg-[#32395E] px-6 pt-6 pb-8 rounded-lg flex flex-col my-4">
      <div className="flex gap-3 items-center">
        {eventData.emoji && <EventIcon icon={eventData.emoji} />}
        <div className="flex flex-col items-center">
          <Text size="medium" className="w-full font-semibold font-sans">
            {eventData.activity}
          </Text>
        </div>
      </div>
      <Separator className="bg-white/20 mt-6" />
      {eventData.type === 'now' || eventData.type === 'later' ? (
        <MeetInfo {...eventData} />
      ) : (
        <AvailableTimes
          times={
            eventData.pollData?.checkedTimes.map((item) => item.time) || []
          }
          type={eventData.type}
        />
      )}
      {!eventData.publicEvent && (
        <>
          <InviteList
            list={inviteList}
            eventId={event?._id}
            excludeOrganizer={true}
          />
        </>
      )}
    </div>
  );

  return (
    <LetsMeetLayout
      eventId={id}
      source={'review'}
      deleteBtn={true}
      widget={'save'}
      actionOnHeaderRight={<SaveAsDraftButton />}
      footerText={
        event?.publicEvent
          ? `Share Invite`
          : `Send Invites (${inviteList.length || 0})`
      }
      onSubmit={() => {}}
    >
      {loading || !event ? (
        <OverlayLoader />
      ) : (
        event && (
          <>
            <main className="flex-grow mb-4">
              <div className="w-full my-8 mb-6">
                <div className="flex flex-col items-center w-full gap-2">
                  <Text size="medium" className="w-full font-serif font-light">
                    Review & Send
                  </Text>
                  <Text size="small" className="w-full">
                    Review the details below and send off your text invites or
                    share individually via a link!
                  </Text>
                  {event && <InfoBox eventData={event as IEvent} />}
                </div>
              </div>
            </main>
            <footer className="mb-4 pb-8">
              <MainButton
                type="primary"
                onClick={handleSubmit}
                icon={<ChevronRight color="#fff" />}
                loading={sendInvitesIsLoading}
              >
                {event?.publicEvent ? (
                  <Text size="small">Create and share event</Text>
                ) : (
                  <Text size="small">
                    {inviteList.length === 0
                      ? 'Create Event'
                      : inviteList.length === 1
                        ? 'Send invite (1)'
                        : `Send invites (${inviteList.length})`}
                  </Text>
                )}
              </MainButton>
            </footer>
          </>
        )
      )}
    </LetsMeetLayout>
  );
};

ReviewEvent.displayName = 'ReviewEvent';
export default ReviewEvent;
