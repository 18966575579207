import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import axios from 'axios';
import { useAppSelector } from 'hooks/useAppSelector';
import { IContact, IEvent, IPollData } from 'types/event';
import { NotificationType } from 'modules/letsMeet/utils/enum';
import {
  EventType,
  ReceiveMeetResponseState,
} from 'modules/letsMeet/types/types';

// Components
import ModernActivityHeader from 'modules/activity/components/modernHeader';
import EventOrganizerHeader from 'modules/letsMeet/components/receive/eventOrganizerHeader';
import EventCard from 'modules/letsMeet/components/receive/eventCard';
import HorizontalInviteList from 'modules/letsMeet/components/inviteList/HorizontalInviteList';
import CommentList from 'modules/letsMeet/components/comments/commentList';
import PollTimeSelector from 'modules/letsMeet/components/meetResponse/pollTimeSelector';
import { toast } from 'components/ui/toast/use-toast';

// Services
import {
  useUpdateEventMutation,
  useDeleteEventMutation,
} from 'services/event.service';
import {
  useGetInviteActivityQuery,
  useCreateActivityMutation,
} from 'services/activity.service';
import {
  useSendNotificationMutation,
  useStopNotificationMutation,
} from 'services/notification.service';
import { getUserPhotosForEvent } from 'modules/letsMeet/utils/user';
import {
  addContactToTargetUser,
  getFormattedSharingText,
  mutualAddContacts,
} from 'utils/helpers';
import { IActivity } from 'types/activity';
import useSessionStorage from 'hooks/useSessionStorage';
import { useAuth } from 'modules/auth/context/AuthContextV2';

interface PollViewProps {
  event: IEvent | null;
  refetch?: () => void;
}

const PollView = ({ event, refetch }: PollViewProps) => {
  const { user } = useAuth();
  const navigate = useNavigate();

  // State
  const [userPhotos, setUserPhotos] = useState<{
    [key: string]: { photoURL: string; name: string };
  }>({});
  const [organizerPhoto, setOrganizerPhoto] = useState<string>('');
  const [pollData, setPollData] = useState<IPollData | undefined>(
    event?.pollData,
  );
  const [selectedTimes, setSelectedTimes] = useState<string[]>([]);

  const [responseState, setResponseState] = useState(
    ReceiveMeetResponseState.NOT_RESPONDED,
  );
  const [visible, setVisible] = useState(false);
  const [pastEvent, setPastEvent] = useState(false);
  const [eventContacts, setEventContacts] = useState<IContact[]>(
    event?.contacts ?? [],
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submittedTimes, setSubmittedTimes] = useState<string[]>([]);
  const [eventActivities, setEventActivities] = useState<IActivity[]>([]);
  const [attendingInvitees, setAttendingInvitees] = useState<string[]>(
    event?.contacts
      ?.filter((contact) => contact.status === 1)
      .map((contact) => contact.name) ?? [],
  );

  // Queries and Mutations
  const [createActivity] = useCreateActivityMutation();
  const [updateEvent] = useUpdateEventMutation();
  const [deleteEvent] = useDeleteEventMutation();
  const [sendNotification] = useSendNotificationMutation();
  const [stopNotification] = useStopNotificationMutation();
  const [isLocked, setIsLocked] = useState(true);
  const [hasVoted, setHasVoted] = useSessionStorage(
    'cal-user-has-voted-before',
    false,
  );
  const { data: inviteActivity } = useGetInviteActivityQuery(event?._id ?? '', {
    skip: !event?._id,
  });

  // Effects
  useEffect(() => {
    if (event?._id) {
      getUserPhotosForEvent(event._id).then((photos) => {
        setUserPhotos(photos);
        const organizerNumber = event?.contacts?.find(
          (contact) => contact.organizer === 1,
        )?.phoneNumber;
        if (!organizerNumber) return;
        setOrganizerPhoto(photos[organizerNumber]?.photoURL ?? '');
      });
    }
  }, [event?._id, eventContacts]);

  useEffect(() => {
    const fetchEventActivities = async () => {
      try {
        if (event?._id) {
          const accessToken = sessionStorage.getItem('accessToken');
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/activity/event/${event._id}`,
            {
              headers: {
                Authorization: `Bearer ${accessToken}`,
              },
            },
          );
          setEventActivities(response.data);
        }
      } catch (error) {
        console.error('Error fetching activities:', error);
      }
    };
  
    // Initial fetch
    fetchEventActivities();
  
    // Set up polling interval
    const intervalId = setInterval(fetchEventActivities, 15000); // 15 seconds
  
    // Cleanup on unmount or when event._id changes
    return () => clearInterval(intervalId);
  }, [event?._id]);

  useEffect(() => {
    const endDate = new Date(event?.end ?? 0);
    const currentDate = new Date();
    setPastEvent(endDate < currentDate);
  }, [event?.end]);

  // Initialize selected times from existing votes
  // useEffect(() => {
  //   if (pollData && user?._id) {
  //     // Get all times where this user has voted
  //     const userVotedTimes = pollData.checkedTimes
  //       .filter(timeSlot =>
  //         timeSlot.voters.some(voter => voter.userId === user._id))
  //       .map(timeSlot => timeSlot.time);

  //     // Set initial times
  //     setSelectedTimes(userVotedTimes);
  //     setHasVoted(userVotedTimes.length > 0);

  //     // Update poll data with these selections
  //     const newPollData: IPollData = {
  //       checkedTimes: pollData.checkedTimes.map((timeSlot) => ({
  //         time: timeSlot.time,
  //         voters: userVotedTimes.includes(timeSlot.time)
  //           ? (!timeSlot.voters.some((voter) => voter.userId === user._id)
  //             ? [...timeSlot.voters, {
  //                 name: user.name ?? null,
  //                 userId: user._id ?? null
  //               }]
  //             : timeSlot.voters)
  //           : timeSlot.voters.filter((voter) => voter.userId !== user._id),
  //       })),
  //     };
  //     setPollData(newPollData);

  //     // Update response state based on votes
  //     if (userVotedTimes.length > 0) {
  //       setResponseState(
  //         userVotedTimes.includes('Not available on all dates')
  //           ? ReceiveMeetResponseState.DECLINED
  //           : ReceiveMeetResponseState.ACCEPTED
  //       );
  //     }
  //   }
  // }, [pollData, user?._id]);

  // Handle time selection changes
  // Handle time selection changes
  const handleTimeSelectionChange = (newSelectedTimes: string[]) => {
    if (!user?._id || !pollData) return;

    // Special handling for "Not available on all dates"
    if (newSelectedTimes.includes('Not available on all dates')) {
      // For "Not available", we don't need to update the checkedTimes voters
      setPollData(pollData); // Keep existing poll data
      setSelectedTimes(['Not available on all dates']);
      setResponseState(ReceiveMeetResponseState.DECLINED);
      return;
    }

    // Regular time slot handling
    const newPollData: IPollData = {
      checkedTimes: pollData.checkedTimes.map((timeSlot) => ({
        time: timeSlot.time,
        voters: newSelectedTimes.includes(timeSlot.time)
          ? !timeSlot.voters.some((voter) => voter.userId === user._id)
            ? [
                ...timeSlot.voters,
                {
                  name: user.name ?? null,
                  userId: user._id ?? null,
                },
              ]
            : timeSlot.voters
          : timeSlot.voters.filter((voter) => voter.userId !== user._id),
      })),
    };

    // setPollData(newPollData);
    setSelectedTimes(newSelectedTimes);

    // Update response state based on selection
    if (newSelectedTimes.length > 0) {
      setResponseState(ReceiveMeetResponseState.ACCEPTED);
    } else {
      setResponseState(ReceiveMeetResponseState.NOT_RESPONDED);
    }
  };

  // const handleTimeSelectionChange = (newSelectedTimes: string[]) => {
  //   if (!user?._id || !pollData) return;

  //   // Only update selectedTimes - no pollData updates
  //   if (newSelectedTimes.includes('Not available on all dates')) {
  //     setSelectedTimes(['Not available on all dates']);
  //     setResponseState(ReceiveMeetResponseState.DECLINED);
  //     return;
  //   }

  //   setSelectedTimes(newSelectedTimes);

  //   // Update response state based on selection
  //   if (newSelectedTimes.length > 0) {
  //     setResponseState(ReceiveMeetResponseState.ACCEPTED);
  //   } else {
  //     setResponseState(ReceiveMeetResponseState.NOT_RESPONDED);
  //   }
  // };
  // alert(JSON.stringify(eventContacts));
  useEffect(() => {
    if (eventContacts) {
      setAttendingInvitees(
        eventContacts
          .filter((contact) => contact.status === 1)
          .map((contact) => contact.name),
      );
    }
  }, [eventContacts]);
  const handleShare = async () => {
    const link = `${process.env.REACT_APP_CLIENT_URL}/share/${event?._id}`;
    if (!navigator.share) {
      await navigator?.clipboard?.writeText(link);
      toast({
        title: 'Link copied to clipboard',
      });
    } else {
      await navigator.share({
        title: 'Share this event',
        text: getFormattedSharingText(user!, event!, link),
        url: link,
      });
    }
  };

  const isInitialMount = useRef(true);
  useEffect(() => {
    if (pollData && event?._id) {
      if (!user?._id) return;

      // Get times from server data
      const userVotedTimes = pollData.checkedTimes
        .filter((timeSlot) =>
          timeSlot.voters.some((voter) => voter.userId === user?._id),
        )
        .map((timeSlot) => timeSlot.time);

      // On initial mount
      if (isInitialMount.current) {
        setSelectedTimes(userVotedTimes);
        setHasVoted(userVotedTimes.length > 0);
        setSubmittedTimes(userVotedTimes);
        setIsLocked(userVotedTimes.length > 0); // Lock if user has votes

        if (userVotedTimes.length > 0) {
          setResponseState(
            userVotedTimes.includes('Not available on all dates')
              ? ReceiveMeetResponseState.DECLINED
              : ReceiveMeetResponseState.ACCEPTED,
          );
        }
        isInitialMount.current = false;
      }
    }
  }, [pollData, event?._id]);

  const handleButtonClick = () => {
    if (isLocked) {
      // If locked, unlock for editing
      setIsLocked(false);
    } else {
      // If unlocked, submit vote
      submitVote().then(() => setIsLocked(true));
    }
  };
  const submitVote = async () => {
    if (!event?._id || !pollData || !user?._id) return;

    setIsSubmitting(true);

    // Store ALL previous states
    const previousContacts = [...eventContacts];
    const previousResponseState = responseState;
    const previousPollData = { ...pollData };
    const previousAttendees = [...attendingInvitees];

    try {
      const isNotAvailable = selectedTimes.includes(
        'Not available on all dates',
      );
      const voteStatus = isNotAvailable ? 2 : selectedTimes.length > 0 ? 1 : 2;

      // If user is voting for times (accepting) and hasn't previously voted
      if (!isNotAvailable && selectedTimes.length > 0 && !hasVoted) {
        try {
          await mutualAddContacts(user._id, event?.userId ?? '');
        } catch (error) {
          console.error('Failed to add user as contact:', error);
          // Continue with voting even if contact addition fails
        }
      }

      // Create new pollData with votes removed
      const newPollData: IPollData = {
        checkedTimes: pollData.checkedTimes.map((timeSlot) => ({
          ...timeSlot,
          voters: timeSlot.voters.filter((voter) => voter.userId !== user._id),
        })),
      };

      if (!isNotAvailable && selectedTimes.length > 0) {
        newPollData.checkedTimes = newPollData.checkedTimes.map((timeSlot) => ({
          ...timeSlot,
          voters: selectedTimes.includes(timeSlot.time)
            ? [
                ...timeSlot.voters,
                {
                  name: user.name ?? null,
                  userId: user._id ?? null,
                },
              ]
            : timeSlot.voters,
        }));
      }

      // Prepare contacts update
      let updatedContacts = event?.contacts ?? [];
      const existingContact = updatedContacts.find(
        (c) => c.phoneNumber === user.phoneNumber,
      );

      if (!isNotAvailable && selectedTimes.length > 0) {
        // Update or add contact for valid votes
        if (existingContact) {
          updatedContacts = updatedContacts.map((contact) =>
            contact.phoneNumber === user.phoneNumber
              ? { ...contact, status: voteStatus }
              : contact,
          );
        } else {
          updatedContacts = [
            ...updatedContacts,
            {
              name: user.name,
              phoneNumber: user.phoneNumber,
              status: voteStatus,
              _id: user._id,
              organizer: 0,
            },
          ];
        }
      } else {
        // Remove from contacts if not available or no times selected
        updatedContacts = updatedContacts.filter(
          (c) => c.phoneNumber !== user.phoneNumber,
        );
      }

      const { _id, userId: eventUserId, ...restOfEvent } = event;

      // Calculate vote changes for activity
      const addedVotes = selectedTimes.filter(
        (time) =>
          !submittedTimes.includes(time) &&
          time !== 'Not available on all dates',
      ).length;

      const removedVotes = submittedTimes.filter(
        (time) =>
          !selectedTimes.includes(time) &&
          time !== 'Not available on all dates',
      ).length;

      let activityMessage;
      if (isNotAvailable) {
        activityMessage = `${user.name ?? 'Someone'} can't make it`;
      } else if (addedVotes > 0 && removedVotes > 0) {
        activityMessage = `${user.name ?? 'Someone'} added ${addedVotes} vote${addedVotes !== 1 ? 's' : ''} and removed ${removedVotes} vote${removedVotes !== 1 ? 's' : ''}`;
      } else if (addedVotes > 0) {
        activityMessage = `${user.name ?? 'Someone'} added ${addedVotes} vote${addedVotes !== 1 ? 's' : ''}`;
      } else if (removedVotes > 0) {
        activityMessage = `${user.name ?? 'Someone'} removed ${removedVotes} vote${removedVotes !== 1 ? 's' : ''}`;
      } else {
        activityMessage = `${user.name ?? 'Someone'} updated their votes`;
      }

      const activityData = {
        _id: `temp-${Date.now()}`,
        title: 'Poll Vote',
        body: activityMessage,
        type: 'poll_vote',
        icon: isNotAvailable ? '😢' : '👍',
        eventId: event._id,
        timestamp: new Date().toISOString(),
        organizerName: user.name ?? 'Someone',
        userId: user._id,
        user: user,
        status: 1,
      } as unknown as IActivity;

      // Make the main API call
      await updateEvent({
        ...restOfEvent,
        id: _id,
        contacts: updatedContacts,
        pollData: newPollData,
        updateEventMetadata: {
          userId: user._id,
          phoneNumber: user.phoneNumber,
          name: user.name,
          wasUpdatedFromPollVote: true,
        },
      });

      // Send notification
      const notificationData = {
        type: NotificationType.POLL_VOTE,
        eventId: event._id,
        responder: user._id,
        response: selectedTimes.length ? (isNotAvailable ? 'No' : 'Yes') : 'No',
      };

      // await sendNotification(notificationData);

      // Create activity
      await createActivity({
        title: 'Poll Vote',
        body: activityMessage,
        type: 'poll_vote',
        icon: isNotAvailable ? '😢' : '👍',
        eventId: event._id,
        user: user,
      });

      // Update ALL UI states after successful API calls
      setPollData(newPollData);
      setEventContacts(updatedContacts);
      setAttendingInvitees((prev) => {
        if (voteStatus === 1) {
          const matchingContact = updatedContacts.find(
            (contact) => contact.phoneNumber === user?.phoneNumber,
          );
          if (matchingContact?.name && !prev.includes(matchingContact.name)) {
            return [...prev, matchingContact.name];
          }
        } else {
          return prev.filter((name) => {
            const matchingContact = updatedContacts.find(
              (contact) => contact.phoneNumber === user?.phoneNumber,
            );
            return name !== matchingContact?.name;
          });
        }
        return prev;
      });
      setEventActivities((prev) => [activityData, ...prev]);
      setHasVoted(true);
      setSubmittedTimes(selectedTimes);
      setIsLocked(true); // Lock the selection after successful submission

      toast({
        title: 'Times updated successfully',
      });
    } catch (error) {
      // Revert ALL states if anything fails
      setPollData(previousPollData);
      setEventContacts(previousContacts);
      setResponseState(previousResponseState);
      setAttendingInvitees(previousAttendees);
      setEventActivities((prev) =>
        prev.filter((a) => !a._id?.startsWith('temp-')),
      );
      setIsLocked(false);

      console.error('Error updating poll data:', error);
      toast({
        title: 'Failed to update times',
        variant: 'destructive',
      });
    } finally {
      setIsSubmitting(false);
      refetch?.();
    }
  };

  useEffect(() => {
    if (pollData && user?._id) {
      // Get all times where this user has voted
      const userSubmittedTimes = pollData.checkedTimes
        .filter((timeSlot) =>
          timeSlot.voters.some((voter) => voter.userId === user._id),
        )
        .map((timeSlot) => timeSlot.time);

      setSubmittedTimes(userSubmittedTimes);
    }
  }, [pollData, user?._id]);

  const isHost = event?.userId === user?._id;

  const formatHeaderDate = (date: Date) => {
    try {
      return formatDistanceToNow(date, { addSuffix: true });
    } catch {
      return 'Invalid date';
    }
  };

  return (
    <div className="relative min-h-screen pb-[200px]">
      {isSubmitting && (
        <div className="fixed inset-0 bg-black/60 z-50 flex items-center justify-center">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-white"></div>
        </div>
      )}
      <div className="w-full sticky top-0 z-10">
        <ModernActivityHeader
          isHost={isHost}
          event={event}
          originPage="lets-meet"
          onEditEvent={() => navigate(`/lets-meet/edit/${event?._id}`)}
          onDeleteEvent={() => setVisible(true)}
        />
      </div>

      <div className="px-8">
        <EventOrganizerHeader
          organizerPhoto={organizerPhoto}
          organizerName={event?.organizer ?? ''}
          sentTime={formatHeaderDate(
            inviteActivity?.timestamp
              ? new Date(inviteActivity.timestamp)
              : (event?.start ?? new Date()),
          )}
          responseState={responseState}
          isHost={isHost}
          isPast={pastEvent}
          eventType={event?.type}
          hasUserVoted={hasVoted}
        />

        <div className="my-6">
          <EventCard
            title={event?.activity ?? ''}
            emoji={event?.emoji ?? ''}
            guests={attendingInvitees.length}
            location={event?.location ?? ''}
            eventType={event?.type}
            startTime="TBD"
            dateOfEvent={event?.start?.toString() ?? ''}
          />
        </div>

        <div className="mt-3 mb-4">
          {pollData && (
            <PollTimeSelector
              pollData={pollData}
              serverPollData={event?.pollData ?? { checkedTimes: [] }}
              userId={user?._id ?? ''}
              onTimeSelectionChange={handleTimeSelectionChange}
              eventId={event?._id ?? ''}
              submittedTimes={submittedTimes}
              isLocked={isLocked}
            />
          )}
        </div>

        <HorizontalInviteList
          title="Attendee List"
          eventId={event?._id}
          contacts={eventContacts}
          userPhotos={userPhotos}
          isOrganizer={isHost}
          shareEvent={handleShare}
        />

        <CommentList
          event={{ event }}
          refetchEvent={refetch}
          userPhotos={userPhotos}
          disabled={pastEvent}
          activities={eventActivities}
        />
      </div>

      <div className="fixed bottom-0 left-0 w-full z-40">
        <div className="flex justify-center p-4">
          <button
            onClick={handleButtonClick}
            disabled={(!isLocked && selectedTimes.length === 0) || pastEvent}
            className="flex h-[60px] w-[384px] items-center justify-center gap-[10px] rounded-[100px] bg-[#6000E9] shadow-[0px_4px_10px_0px_rgba(0,0,0,0.06)] disabled:opacity-50 disabled:cursor-not-allowed"
          >
            <div className="flex items-center gap-2">
              <span className="text-white font-manrope text-base font-semibold">
                {isLocked ? 'Change times' : 'Confirm time'}
              </span>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default PollView;
