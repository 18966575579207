import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import {
  useGetAllEventsQuery,
  useGetAvailableContactsQuery,
} from 'services/event.service';
import { EventStatus } from 'utils/constants';
import { useAuth } from 'modules/auth/context/AuthContextV2';
import { IEvent, IContact } from 'types/event';

const LOCAL_STORAGE_KEY = 'dismissedEvents';

const PREDEFINED_UPDATES = {
  calendar: 'calendar',
  inviteFriends: 'inviteFriends',
  friends: 'friends',
} as const;

export type UpdateCard =
  | { type: 'event'; eventData: IEvent }
  | { type: 'calendar' }
  | { type: 'inviteFriends' }
  | { type: 'friends' };

type DismissedEvent = {
  eventId: string;
  expiresAt: string;
};

const useUpdates = () => {
  const { user } = useAuth();
  const { data: allEvents } = useGetAllEventsQuery({
    page: 0,
    history: 'false',
  });
  const { data: availableContacts } = useGetAvailableContactsQuery();

  const [updates, setUpdates] = useState<UpdateCard[]>([]);

  const getDismissedEvents = () => {
    let dismissedEvents: DismissedEvent[] = [];

    try {
      dismissedEvents = JSON.parse(
        localStorage.getItem(LOCAL_STORAGE_KEY) || '[]',
      ).filter((item: DismissedEvent) => {
        return dayjs(item.expiresAt).isAfter(dayjs());
      });
    } catch (error) {
      console.error(
        'Error getting dismissed events from local storage:',
        error,
      );
    }

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(dismissedEvents));

    return dismissedEvents;
  };

  const [_dismissedEvents, setDismissedEvents] = useState<DismissedEvent[]>([]);

  const addEventToUpdates = (event: IEvent) => {
    setUpdates((prevUpdates) => [
      ...prevUpdates.filter(
        (update) =>
          update.type !== 'event' ||
          (update.type === 'event' && update.eventData._id !== event._id),
      ),
      { type: 'event', eventData: event },
    ]);
  };

  useEffect(() => {
    const dismissedEvents = getDismissedEvents();

    const notAcceptedEvents =
      (allEvents as { event: IEvent }[])?.filter((event) => {
        return event?.event?.contacts?.some(
          (contact: IContact) =>
            contact.userId === user?._id &&
            contact.status === EventStatus.NotResponded,
        );
      }) ?? [];

    const notDismissedEvents = notAcceptedEvents.filter(
      (event) =>
        !dismissedEvents.some((item) => item.eventId === event.event._id),
    );

    for (const event of notDismissedEvents) {
      addEventToUpdates(event.event);
    }
  }, [allEvents, _dismissedEvents]);

  useEffect(() => {
    const dismissedEvents = getDismissedEvents();

    //Calendar Update
    if (
      !dismissedEvents.some(
        (event) => event.eventId === PREDEFINED_UPDATES.calendar,
      )
    ) {
      setUpdates((prevUpdates) => [
        ...prevUpdates.filter((update) => update.type !== 'calendar'),
        { type: 'calendar' },
      ]);
    } else {
      setUpdates((prevUpdates) =>
        prevUpdates.filter((update) => update.type !== 'calendar'),
      );
    }

    //Invite Friends Update
    if (
      !dismissedEvents.some(
        (event) => event.eventId === PREDEFINED_UPDATES.inviteFriends,
      )
    ) {
      setUpdates((prevUpdates) => [
        ...prevUpdates.filter((update) => update.type !== 'inviteFriends'),
        { type: 'inviteFriends' },
      ]);
    } else {
      setUpdates((prevUpdates) =>
        prevUpdates.filter((update) => update.type !== 'inviteFriends'),
      );
    }

    //Friends Update
    if (
      !dismissedEvents.some(
        (event) => event.eventId === PREDEFINED_UPDATES.friends,
      ) &&
      availableContacts &&
      availableContacts?.data?.length > 0
    ) {
      setUpdates((prevUpdates) => [
        ...prevUpdates.filter((update) => update.type !== 'friends'),
        { type: 'friends' },
      ]);
    } else {
      setUpdates((prevUpdates) =>
        prevUpdates.filter((update) => update.type !== 'friends'),
      );
    }
  }, []);

  const handleDismiss = (eventId: string, expiresAt?: Date) => {
    const dismissedEvents = [...getDismissedEvents()].filter(
      (event) => event.eventId !== eventId,
    );

    dismissedEvents.push({
      eventId,
      expiresAt: expiresAt
        ? dayjs(expiresAt).toISOString()
        : dayjs().add(10, 'year').toISOString(),
    });

    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(dismissedEvents));

    setDismissedEvents(dismissedEvents);

    //Clean up dismissed events
    setUpdates((prevUpdates) =>
      prevUpdates.filter(
        (update) =>
          !dismissedEvents.some((event) => event.eventId === update.type),
      ),
    );
  };

  return { updates, handleDismiss };
};

export default useUpdates;
